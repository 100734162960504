import {roles} from '../../constants'

export default function guest({next, store, nextMiddleware}) {

    /** This middleware will take care of logged in users, who are trying go to login page.
     * If user already logged in, we redirect him directly on design dashboard.
     * If he logged out - we allow him to go to /login page.
     * */

    // console.log('Middleware Guest. IsAuthentificated status', store.getters['auth/isAuthenticated']());

    if (store.getters['auth/isAuthenticated']()) {

        const user = store.getters['auth/getCurrentUser'];
        const userRole = user ? user.users_role : '0';

        let path = '';

        switch (userRole) {
            case roles.ADMIN:
            case roles.MANAGER:
            case roles.ACC_EXECUTIVE:
            case roles.PRODUCT_MANAGER:    
                path = '/design';
                break;
            case roles.ADVERTISER:
                path = '/404';
                break;
            case roles.WF_PRINTER:
                path = '/design';
                break;
            case roles.PUB_PRINTER:
                path = '/404';
                break;
            case roles.FACILITATOR:
                path = '/material';
                break;
            case roles.DESIGNER:
                path = '/design';
                break;
            case roles.INSTALLER:
                path = '/installer';
                break;

            case '0':
                store.dispatch('auth/signOut');
                break;
        }

        return next({path: path});
    }

    return nextMiddleware();
}