<template>
    <div class="component-wrap">
        <AppContentHeader headline="Installation Walkthrough"/>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <div class="md-layout-item md-large-size-33 md-small-size-50 md-xsmall-size-100" v-if="!loadingSpinner">
            <p class="group-description" v-if="installationGroups.length > 0">Select an installation group to begin a walkthrough.</p>

            <div class="md-alignment-top-left" v-for="(installationGroup, index) in installationGroups" :key="index + '_install-group'">
                <md-button @click="beginWalkthrough(installationGroup)" class="md-primary md-raised md-large-size-100 install-group-btns">{{installationGroup.name}}</md-button>
            </div>
        </div>


        <AppEmptyState message="Looks like you haven't made any groups yet." v-if="!installationGroups.length && !loadingSpinner"/>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

    </div>
</template>

<script>
    export default {
        name: 'InstallationTickets',
        data: function () {
            return {
                loadingSpinner: true,
                installationGroups: [],
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                }
            }
        },
        methods: {
            getGroups() {
                this.$axios.get('/installation-groups')
                    .then((res) => {
                        this.installationGroups = res.data;
                        this.loadingSpinner = false;
                    })
            },
            beginWalkthrough(locationGroup){
                this.$router.push('/installer-walkthrough/' + locationGroup.id);
            }
        },

        created(){
            this.getGroups();
        }
    }
</script>

<style lang="scss" scoped>

    .install-group-btns{
        width: 70%;
        margin-top: 20px;

        @include respond(medium) {
            width: 100%;
        }
    }
</style>
