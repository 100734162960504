export const DeepFilterMixin = {
    methods: {
        filterFunction(data, filter) {
            const filters = filter.split(' ');
            let keywordsFound = 0;

            let isFound;
      
            for (const fil of filters) {
              isFound = false;
              
              for (const prop in data ) {
                if (data[prop] && data[prop].toString().toLowerCase().indexOf(fil.toLowerCase()) !== -1) {
                  isFound = true;
                }
              }

              if (isFound) {
                keywordsFound++;
              }
              
              if (keywordsFound === 0) {
                return false;
              }
            }
            // check each keyword
      
            return keywordsFound === filters.length;
            // if we found less keywords then filters array contain - return false
          }
    }
}