<template>
    <div class="component-wrap md-layout collaboration-comments">

        <h3 class="md-headline md-layout-item md-xlarge-size-100 md-large-size-100">Collaboration comments</h3>

        
        <div class="canvas-wrap md-layout-item md-xlarge-size-100 md-large-size-100 md-elevation-10" v-if="markers.length">

            <div class="fake-canvas"
                ref="fakeCanvas"
                :style="{'backgroundImage': 'url(' + order.artworkThumbnail + ')'}">

                <div
                    v-for="(box, index) in drawedBoxes"
                    :key="index + '_box'"
                    @mouseover="comments[index].active = true"
                    @mouseleave="comments[index].active = false"
                    :class="{
                        'active': box.active
                    }"
                    class="marker"
                    :style="structureStyles(box)"
                ></div>
            </div>

            <div class="comment-list">

                <div v-for="(comment, index) in comments"
                    :key="index + '_comment'"
                    @mouseover="drawedBoxes[index].active = true"
                    @mouseleave="drawedBoxes[index].active = false"
                    :class="{
                            'active': comment.active,
                            'approved': comment.status == '1',
                            'rejected': comment.status == '2'
                        }"
                    
                    class="comment">
                    <p class="md-body-2 comment__author">{{comment.author}}</p>
                    <p class="comment__text">{{comment.text}}</p>

                    <p v-if="!comments.length" class="mat-body-2 comment__text empty-comment">There are no comments yet.</p>
                </div>
                <!-- end of comment -->
            
            </div>
            <!-- end of comment list -->

        </div>


        <AppEmptyState
            class="md-layout-item md-xlarge-size-100 md-large-size-100" 
            message="There are no collaboration comments to display."
            v-if="!markers.length"/>

    </div>
</template>

<script>
export default {
    name: 'DesignDashboardCollaborationComments',
    props: {
        markers: {
            type: Array,
            default: () => []
        },
        order: {
            type: Object,
            required: true
        }
    },

    data: function() {
        return {
            comments: [],
            drawedBoxes: []
        } 
    },

    created() {
        this.comments = this.markers.map(x => {
            return {
                text: x.comment,
                author: x.participant_user_first + ' ' + x.participant_user_last,
                time: new Date(x.created_at),
                active: false,
                status: x.comment_approved
            }
        });

        this.drawedBoxes = this.markers.map(x => {
            return { 
                ...x,
                active: false
            }
        });

    },

    methods: {

        structureStyles(el) {

            return {
                top:  el.top + 'px',
                left: el.left + 'px',
                width: el.width + 'px',
                height: el.height + 'px',
                zIndex: el.zIndex
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.collaboration-comments {
    margin: 4.8rem 0 3.2rem;
    justify-content: flex-start;

    .md-headline {
        margin-bottom: 2.4rem;
        font-weight: 600;
    }
}

/** Proof editor styles */
.canvas-wrap {
  width: 1280px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  background-color: #57585B;
  border-top: 1px solid #57585B;
}
.fake-canvas {
  width: 960px;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #57585B;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.marker {
    position: absolute;
    background-color: transparent;
    border: 5px solid $color-primary;
    cursor: pointer;
    transition: all .3s ease-in;

    &.active {
        border: 5px solid #000;
        background-color: $color-blue;
    }
}

.comment-list {
    background-color: #fff;
    border: 1px solid #57585B;
    border-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 600px;
    width: 320px;

    .comment{
        width: 100%;
        padding: 16px;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: 2px solid rgba(0, 0, 0, .6);
        cursor: pointer;
        align-items: center;
        position: relative;
        transition: all .2s ease-in;
    
        &.approved {
            background-color: rgba(0, 255, 0, .4);
        }

        &:not(:last-child) {
            border-bottom: 0;
        }

        &.active {
            background-color: #fff;
            transform: translateX(-60px);
            box-shadow: 0 10px 20px rgba(0,0,0, .4);
        }

        &__text {
            margin: 0;
            width: 100%;
            word-break: break-word;
        }

        &__author {
            width: 100%;
            text-transform: capitalize;
        }
    }
}

</style>