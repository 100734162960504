<template>
    <div class="component-wrap">
        <AppContentHeader headline="Queues"/>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <transition name="fade">  
            <QueuesList v-if="!loadingSpinner" :queues="queues" @printFailed="printRequestFailed"/>
        </transition>
        
        <AppEmptyState
            v-if="!queues.length && !loadingSpinner"
            :message="'There is no queue to display'"/>

         <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->
    </div>
</template>

<script>
import QueuesList from '@/components/MaterialDashboard/QueuesList'
import Cookie from 'js-cookie'

export default {
    name: 'MaterialDashboardQueuesList',
    data: function(){
        return {
            queues: [],
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loadingSpinner: false
        }
    },
    components: {
        QueuesList
    },
    created() {
        this.getQueues();
    },
    methods: {
        printRequestFailed(message) {
            this.snackbar.message = `${message}. Please, try again.`;
            this.snackbar.show = true;
        },
        queuesObjectToArray(queues) {

            const queuesArray = Object.keys(queues)
                .map(key => {
                    return {
                        'name': key,
                        'items': queues[key]
                    };
                });

            return queuesArray;
        },

        parseQueues(queuesData) {
            const sponsorships = queuesData['sponsorships'];
            const signageOrders = queuesData['signage_orders'];
            const queues = {};

            const queuesParser = order => {
                const materialName = order.material_name ? order.material_name : '';
                if (materialName && !queues[materialName]) {
                    queues[materialName] = [];
                }
                
                if (materialName) {
                    queues[materialName].push(order);
                }
            };

            sponsorships.map(queuesParser);
            signageOrders.map(queuesParser);
            
            return this.queuesObjectToArray(queues);
        },

        getQueues() {
            this.loadingSpinner = true;
            this.$axios.get(`/queues?ready_to_print=true?season_id=${Cookie.get('season_id')}`)
                .then(res => {
                    this.queues = this.parseQueues(res.data);
                    this.loadingSpinner = false;
                })
                .catch(err => {
                    this.snackbar.message = `Get queues request failed. ${err.message}`;
                    this.loadingSpinner = false;
                    this.snackbar.show = true;
                })
        }
    }
}
</script>