<template>
    <div class="component-wrap">
        <AppContentHeader :headline="'Item ' + $route.params.itemId" />

        <div class="md-layout md-alignment-top-space-between item">
                
            <ShipmentItemStatusesSection class="md-layout-item md-large-size-30 md-large-size-30 md-small-size-100 md-elevation-5"/>
            
            <div class="box__content md-layout-item md-large-size-66 md-large-size-66 md-small-size-100">
                
                <OrderDataBlock class="order-minimized md-elevation-5"/>

                <ShipmentItemCommentsSection :item="item"/>

            </div>
            <!-- end of box content column -->
        
        </div>
    </div>
</template>

<script>
import OrderDataBlock from "@/components/Common/OrderDataBlock"
import ShipmentItemCommentsSection from "@/components/ShipmentDashboard/ShipmentItemCommentsSection"
import ShipmentItemStatusesSection from "@/components/ShipmentDashboard/ShipmentItemStatusesSection"

export default {
    name: 'ShipmentDashboardItemPage',
    data: () => ({
        item: {id:123}
    }),
    components: {
        OrderDataBlock,
        ShipmentItemCommentsSection,
        ShipmentItemStatusesSection
    }
}
</script>

<style lang="scss" scoped>
.item {
    
    &__status-flow {
        @include white-card-with-subheading;
        @include manage-status-card;
    }

}
</style>