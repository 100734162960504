import { render, staticRenderFns } from "./OrderCardStatusRibbon.vue?vue&type=template&id=5878de67&scoped=true&"
import script from "./OrderCardStatusRibbon.vue?vue&type=script&lang=js&"
export * from "./OrderCardStatusRibbon.vue?vue&type=script&lang=js&"
import style0 from "./OrderCardStatusRibbon.vue?vue&type=style&index=0&id=5878de67&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5878de67",
  null
  
)

export default component.exports