<template>
    
    <div class="control-field">
    
        <label v-if="hasLabelSlot()"
            :style="{ width: returnWidth() }"
            class="control-field__label">
            <slot name="label"/>
        </label>
        
        <input
            v-if="controlType === 'input'" 
            v-bind="$attrs"
            :value="value"
            :type="type"
            class="control-field__input"
            :class="{'control-field__input--invalid': error}"
            @input="$emit('input', $event.target.value)">

         <input
            v-if="controlType === 'date'" 
            v-bind="$attrs"
            :value="value"
            :type="type"
            ref="dateInput"
            @focus="$refs.dateInput.type = 'date'"
            class="control-field__input"
            :class="{'control-field__input--invalid': error}"
            @input="$emit('input', $event.target.value)">

         <textarea
            v-if="controlType === 'textarea'"
            v-bind="$attrs"
            :value="value"
            class="control-field__textarea"
            :class="{'control-field__textarea--invalid': error}"
            @input="$emit('input', $event.target.value)"></textarea>
        
        <select
            v-if="controlType === 'select'"
            v-bind="$attrs"
            :value="value"
            @input="$emit('input', $event.target.value)"
            class="control-field__select"
            :class="{'control-field__select--invalid': error}">
                <slot/>
        </select>

        <transition name="slide">
            <p v-if="error" class="control-field__error">{{errorText ? errorText : 'Field is required'}}</p>
        </transition> 

    </div>

</template>

<script>
export default {
    name: 'AppInput',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        controlType: {
            type: String,
            default: 'input'
        },
        value: {
            type: [String, Number],
            default: ''
        },
        error: {
            type: [String, Boolean],
            default: ''
        },
        errorText: {
            type: String,
            default: ''
        },
        labelWidth: {
            type: String,
            default: 'unset'
        }
    },
    methods: {
        returnWidth() {
            if (window.innderWidth > 768 && this.labelWidth !== 'unset') {
                return this.labelWidth;
            } 
            return '100%';
        },
        hasLabelSlot() {
            return !!this.$slots.label;
        }
    }
}
</script>

<style scoped>
.control-field {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.control-field__select,
.control-field__textarea,
.control-field__input {
    background-color: #d8d8d8;
    border: solid 2px #f0f1f2;
    color: #8996a6;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.85;
    padding-left: 24px;
    transition: all .4s ease-out;
    width: 100%;
}
.control-field__select:active,
.control-field__textarea:active,
.control-field__input:active,
.control-field__select:focus,
.control-field__textarea:focus,
.control-field__input:focus {
    background-color: #fff;
    color: #44566c;
    border-radius: 5px;
    outline: none;
}
.control-field__textarea:valid,
.control-field__select:valid,
.control-field__input:valid {
    background-color: #fff;
    border:2px solid #57A773;
    border-radius: 5px;
    color: #44566c;
}
.control-field__textarea.control-field__textarea--invalid,
.control-field__select.control-field__select--invalid,
.control-field__input.control-field__input--invalid {
    background-color: #fff;
    border:2px solid #E15554;
}
.control-field__select:focus,
.control-field__textarea:focus,
.control-field__input:focus {
    border:2px solid #4ea5d9;
}
.control-field__select,
.control-field__input {
    height: 48px;
}
.control-field__textarea {
    padding-top: 8px;
}
.control-field__select{
    appearance: none;
    border-radius: 0;
}
.control-field__input::placeholder,
.control-field__textarea::placeholder {
    color: #8996a6;
}
.control-field__label {
    display: block;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 16px;
    color: #4d5762;
}
.control-field__error {
    position: absolute;
    bottom: -16px;
    margin:0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #E15554;
}
</style>