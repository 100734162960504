<template>
    <div class="component-wrap">
        
        <AppContentHeader :headline="'Order #' +  id"></AppContentHeader>

        <AppSpinner v-if="loading && !order" :loading="loading"/>

        <OrderDataBlock
            v-if="order"
            @uploadFile="showDialog = true"
            @materialSet="showSnackbar"
            :order="order"
            :locationCode="locationCode"
        />

        <!-- make separate component from that VVVVVVVVV -->

    <div v-if="!loading">

        <AppContentHeader :headline="'Design notes'"></AppContentHeader>

        <p v-if="order.mediaType === 'signage'" class="md-body-1">{{order.notes.notes}}</p>
        <p v-if="order.mediaType === 'signage' && !order.notes.notes" class="md-body-1">There is no notes to display.</p>

        <p v-if="order.mediaType !== 'signage'" class="md-body-1">{{order.notes}}</p>
        <p v-if="order.mediaType !== 'signage' && !order.notes" class="md-body-1">There is no notes to display.</p>

        <AppContentHeader v-if="order.mediaType === 'signage'" :headline="'Order info'"></AppContentHeader>

        <div class="order-extra-data" v-if="order.mediaType === 'signage'"> 
            <p class="md-body-1"><strong>Description:</strong> {{order.description ? order.description : 'No description yet'}}</p>
            <p class="md-body-1"><strong>Front copy:</strong> {{order.frontCopy ? order.frontCopy : 'No front copy yet'}}</p>
            <p class="md-body-1"><strong>Back copy:</strong> {{order.backCopy ? order.backCopy : 'No back copy yet'}}</p>
            <p class="md-body-1"><strong>Reference field:</strong> {{order.reference ? order.reference : 'No refence field yet'}}</p>
            <p class="md-body-1"><strong>Support method:</strong> {{order.supportMethod ? order.supportMethod : 'Not specified'}}</p>
            <p class="md-body-1"><strong>Quantity:</strong> {{order.quantity ? order.quantity : 'Not specified'}}</p>
        </div>

        <AppContentHeader :headline="'Order uploads'"></AppContentHeader>

        <AppEmptyState message="There is no order uploads to display." v-if="!uploads.length"/>


        <div class="md-layout order-uploads">
            
            <div class="upload-card
                        md-layout-item
                        md-elevation-5
                        md-xlarge-size-30
                        md-large-size-30
                        md-medium-size-30
                        md-small-size-50
                        md-xsmall-size-100"
                        v-for="(upload, index) in uploads"
                        :key="index + '_upload'">

                <div class="upload-card__image-wrap">
                    <img :src="upload.thumbnail_url ||  order.fallbackImage"
                        alt="Signage upload thumbnail"
                        class="upload-card__image">
                    <!-- <md-tooltip md-direction="bottom">Click to see more info</md-tooltip> -->
                </div>

                <div class="upload-card__info">
                    <p class="md-body-1"><strong>Description: </strong>{{upload.description}}</p>
                    <p class="md-body-1"><strong>Original name: </strong>{{upload.original_name}}</p>
                </div>

                <div class="upload-card__actions">
                     <md-button
                        class="md-raised md-primary"
                        @click="downloadUpload(upload.download_url)"
                        >Download</md-button>
                </div>

            </div>
        </div>

    </div>

        <!-- TO DO:
            make separate component from that ^^^^^ -->


        <DesignDashboardCollaborationComments
            v-if="!loading"
            :order="order"
            :markers="markers"/>


        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

         <DesignDashboardUploadDialog
            :showDialog.sync="showDialog"
            :order="order"
            @fileUploaded="originalUploadSuccess()"
            @fileUploadingFailed="originalUploadFail()"/>


    </div>

</template>

<script>
import OrderDataBlock from "@/components/Common/OrderDataBlock";
import DesignDashboardUploadDialog from "@/components/DesignDashboard/DesignDashboardUploadDialog";
import DesignDashboardCollaborationComments from "@/components/DesignDashboard/DesignDashboardCollaborationComments";
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin';
import Cookie from 'js-cookie'

export default {
    name: 'DesignDashboardOrder',
    mixins: [InitializeOrderMixin],
    data: () => {
        return {
            id: null,
            mode: '',
            showDialog: false,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            order: null,
            loading: true,
            markers: [],
            uploads: [],
            locationCode: ''
        }
    },

    components: {
        OrderDataBlock,
        DesignDashboardUploadDialog,
        DesignDashboardCollaborationComments
    },

    created() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;

        this.getOrder();
    },

    methods: {
        downloadUpload(link) {
            window.open(link, '_blank');
        },

        showSnackbar(errorMessage = null) {
            this.snackbar.message = errorMessage ? errorMessage : 'Material successfully set';
            this.snackbar.show = true;
        },

        originalUploadSuccess() {
            this.getOrder();
            this.snackbar.duration = 3000;
            this.snackbar.message = "Original was successfully uploaded!";
            this.snackbar.show = true;
        },

        originalUploadFail() {
            this.snackbar.duration = 6000;
            this.snackbar.message = "Oooops! Something went wrong. Please, check if your file isn't too big.";
            this.snackbar.show = true;
        },

        getOrderUploads(signageOrderId) {
            this.$axios.get(`signage-orders/${signageOrderId}/signage-uploads`)
                .then(res => {
                    this.uploads = res.data;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        getCollaborationData(collaborationID) {
            this.$axios.get(`signage-proof-collaborations/${collaborationID}/markers`)
                .then(res => {
                    this.markers = res.data.filter(x => x.comment_approved == 1);
                    this.loading = false;
                })
                .catch(() => {
                    // this.showSnackbar(err.message);
                    this.loading = false;
                });
        },

        getOrder() {

            this.loading = true;
            let requestUrl = '';

            if (this.mode === 'sponsorship') {
                requestUrl = `sponsorships/${this.id}?season_id=${Cookie.get('season_id')}`;
            } else if (this.mode === 'signage') {
                requestUrl = `signage-orders/${this.id}?season_id=${Cookie.get('season_id')}`;
            } else if (this.mode === 'free-standing') {
                requestUrl = `freeStanding/${this.id}?season_id=${Cookie.get('season_id')}`;
            }

            this.$axios.get(requestUrl)
                .then(res => {

                    this.locationCode = res.data.location_code;

                    this.initializeOrder(res.data);
                    
                    if (this.mode === 'signage') {
                        this.getCollaborationData(res.data.signage_proof_collaboration_id);
                        this.getOrderUploads(res.data.id);
                    } else {
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.showSnackbar(err.message);
                    this.loading = false;
                })
        }

    },
}
</script>

<style lang="scss" scoped>
.order-extra-data {
    .md-body-1 {
        margin-bottom: 1.6rem;
    }
}


.upload-card {
    padding: 0 2.4rem 2.4rem;
    background: #fff;
    margin: 0 2.4rem 2.4rem 0;

    &__image-wrap {
        width: calc(100% + 4.8rem);
        transform: translateX(-2.4rem);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ccc;

    }
    &__image {
        max-height: 300px;
        object-fit: contain;
    }

    &__info,
    &__actions {
        margin-top: 2.4rem;
    }


}
</style>