<template>
	<div class="component-wrap">

		<AppContentHeader headline="Reports">

		</AppContentHeader>

		<div class="md-layout">

			<div class="report-box md-elevation-15 md-layout-item md-size-30" @click="$router.push('/reports/billing')">
				<div class="report-box__info">
					<h3 class="md-subheading"><strong>Billing Reports </strong></h3>
					<h6 class="md-subheading"> Reports for generating invoices and billing.</h6>
					<md-button class="md-raised md-primary">VIEW REPORTS</md-button>
				</div>

				<md-tooltip md-direction="top">View reports</md-tooltip>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: 'ReportsDashboard',
		data: function () {
			return {}
		},

		components: {},

	}
</script>

<style lang="scss" scoped>
	.boxes {
		width: 100%;
	}

	.report-box {
		display: flex;
		padding: 1.6rem 2.4rem;
		margin: 0 0 $margin-medium;
		cursor: pointer;
		justify-content: center;
		align-content: center;

		@include respond(small) {
			flex-direction: column;
			justify-content: center;
		}

		&__info {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.md-button {
				margin-top: 3rem;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
				border-radius: 4px;
			}
		}

		&__items {
			width: 60%;

			@include respond(small) {
				width: 100%;
			}
		}

		&__list {
			list-style: none;
			padding: 0;

			@include respond(small) {
				margin: 16px 0;
			}
		}

		&__item {
			text-align: right;

			@include respond(small) {
				text-align: left;
			}
		}

		h3 {
			text-align: center;
		}

		h6.md-subheading {
			font-size: 14px;
		}
	}
</style>