<template>
    <div class="component-wrap">
        <md-list class="queue-list md-elevation-10">
            <md-list-item class="queue"
                v-for="(queue, index) in queues" :key="index + 'queue'"
                @click="$router.push('/material/queues/' + queue.items[0].material_id)">

                <span class="queue__name">{{queue.name}}</span>
                <span class="queue__items">{{queue.items.length}} items</span>
                <md-button class="md-raised md-primary"
                            @click.stop="printSet(queue.items[0].material_id)">Print set</md-button>

            </md-list-item>
        </md-list>
    </div>
</template>

<script>
export default {
    name: 'UIQueuesList', 
    props: {
        queues: {
            type: Array,
            required: true
        }
    },
    methods: {
        printSet(id) {
            this.$axios.get(`reports/queues/export?material_id=${id}`)
                .then(res => {
                    window.open(res.data, '_blank');
                })
                .catch(err => {
                    this.$emit('printFailed', err.message);
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.queue-list{
    width: 100%;
    padding: 0;
}
.queue {
    border: 1px solid rgba($color-black, .5);

    &__name {
        width: 40%;
    }

    &__items {
        width: 40%;
    }

    &:not(:last-child) {
        border-bottom: 0;
    }
}
</style>