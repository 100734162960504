<template>
    <div class="component-wrap">

        <AppContentHeader headline="Materials">
            <md-button class="md-primary md-raised" @click="openMaterialDialog()">New material</md-button>
        </AppContentHeader>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner" class="floating-spinner"/>

        <MaterialsList
            v-if="!loadingSpinner"
            :materials="materials"
            @updateMaterial="openMaterialDialog"
            @deleteMaterial="openConfirmDialog"/>
        <!-- end of page content -->

        <AppEmptyState message="There is no materials to display." v-if="!materials.length && !loadingSpinner"/>

        <AppConfirmDialog
            :showDialog.sync="confirmationDialog.show"
            :question="confirmationDialog.question"
            @actionConfirmed="deleteMaterial"/>   
        <!-- dialog to confirm material delete -->

        <MaterialDialog
            :showDialog.sync="materialDialog.show"
            :key="materialDialog.key"
            :material="materialToEdit"
            @success="updateMaterialList"
            @fail="showSnackbar"/>
        <!-- dialog to create / update material -->

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->

    </div>
</template>

<script>
import MaterialsList from "@/components/MaterialDashboard/MaterialsList";
import MaterialDialog from "@/components/MaterialDashboard/MaterialDialog";

export default {
    name: 'MaterialDashboardManageMaterials',
    data: function(){
        return {
            materials: [],
            materialToEdit: null,
            materialDialog: {
                show: false,
                key: 0
            },
            
            materialToDelete: null,
            confirmationDialog: {
                show: false,
                question: ''
            },

            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loadingSpinner: false,

        }
    },
    components: {
        MaterialsList,
        MaterialDialog
    },
    created() {
        this.getMaterials();
    },
    methods: {
        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },
        openConfirmDialog(id) {
            this.materialToDelete = id;
            this.confirmationDialog.question = `Are you sure you want to delete material with id ${id}?
            All orders that have this material will lose it.`;
            this.confirmationDialog.show = true;
        },
        openMaterialDialog(material = null) {
            this.materialToEdit = material;
            this.materialDialog.key++;
            this.materialDialog.show = true;
        },
        updateMaterialList(message) {
            this.getMaterials();
            this.showSnackbar(message);
        },
        deleteMaterial() {
            this.$axios.delete(`/materials/${this.materialToDelete}`)
                .then(()=> {
                    this.showSnackbar('Material deleted!');
                    this.getMaterials();
                })
                .catch(err => {
                    this.showSnackbar(`${err.response.data}.`);
                });
        },
        getMaterials() {
            this.loadingSpinner = true;
            this.$axios.get('/materials')
                .then(res => {
                    this.materials = res.data;
                    this.loadingSpinner = false;
                })
                .catch(err => {
                    this.showSnackbar(`${err.message}. Please, try again.`);
                    this.loadingSpinner = false;
                });
        }
        
    }
}
</script>

<style lang="scss" scoped>

</style>