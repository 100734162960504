<template>
    <md-dialog :md-active.sync="showDialog"
                class="qr-code-dialog"
                :md-click-outside-to-close="false">
        
        <md-dialog-title class="qr-code-dialog__header">
            <span> Print QR codes</span>
        </md-dialog-title>
        <!-- end of dialog header -->

        <div class="qr-code-dialog__content">
            <AppInput
                class="qr-code-dialog__name"
                required
				type="number"
				min="1"
                placeholder="Number of QR codes to generate"
                v-model=$v.qrCodesNumber.$model
                :error="$v.qrCodesNumber.$invalid && $v.qrCodesNumber.$dirty"
                errorText="Number of QR codes is required"
            />
        </div>        

        <md-dialog-actions>
            <md-button class="md-primary md-raised" @click="closeDialog()">Cancel</md-button>
            <md-button  :disabled="!qrCodesNumber"
                        class="md-primary md-raised"
                        @click="generateQrCodes()">Generate package QR codes</md-button>
        </md-dialog-actions>
        <!-- end of dialog actions -->

    </md-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'QrCodeDialog',

    props: {

        showDialog: {
            type: Boolean,
            default: false
        }
    },
    
    data: () => ({

       qrCodesNumber: 10
	}),

    validations: {

        qrCodesNumber: { required },
    },

    methods: {

        closeDialog() {

            this.qrCodesNumber = 10;
            this.$emit('update:showDialog', false);
        },

        generateQrCodes() {

			this.$axios.get(`/box-qr-codes?codes_to_generate=${this.qrCodesNumber}`)
				.then((res) => {

					this.forceFileDownload(res);
					this.$emit('success', 'QR codes successfully created!');
					this.closeDialog();
				})
				.catch(err => {

					this.$emit('fail', `${err.message}. Please, try again.`);
					this.closeDialog();
				})
        },

		forceFileDownload(response){

			const link = document.createElement('a');
			link.href = response.data.toString();
			link.setAttribute('download', 'QR-codes.pdf');
			document.body.appendChild(link);
			link.click();
		}
    }

}
</script>

<style lang="scss" scoped>
   .md-dialog {
        justify-content: center;

       /deep/ .md-dialog-actions{

           @include respond(large) {
               padding: 3rem 2.4rem !important;
           }
       }

       /deep/ .md-dialog-container {

           @include respond(xsmall) {
               .md-button-content {
                   white-space: normal;
                   font-size: 10px;
               }
           }
       }

        .qr-code-dialog {

            &__content {
               padding: 0 2.4rem;
            }
       }
    }
</style>