<template>
    <div class="component-wrap" v-if="order">
        <AppContentHeader :headline="'Order #' + order.id">
        </AppContentHeader>

        <div class="md-layout box">
            <div class="box__controls md-layout md-layout-item md-xlarge-size-40 md-large-size-40 md-small-size-100">

                <div class="box__status-flow md-layout-item md-large-size-100 md-small-size-50 md-xsmall-size-100 md-elevation-5">

                    <h6 class="md-subheading">Manage statuses</h6>

                    <OrderStatusesCard :order="order" @statusUpdated="onStatusUpdated"></OrderStatusesCard>

                </div>
            </div>

            <div class="box__content md-layout-item md-xlarge-size-60 md-large-size-60 md-small-size-100">
                <div class="md-layout">
                    <div class="md-layout-item md-layout md-xlarge-size-100 md-large-size-100 md-small-size-100">
                        <OrderDataBlock
                            :order="order"
                            class="order-minimized single-item md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100"/>
                    </div>
                </div>
            </div>


        </div>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{ snackbar.message }}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
    </div>
</template>

<script>
import OrderDataBlock from "@/components/Common/OrderDataBlock"
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin'
import OrderStatusesCard from "@/components/Common/OrderStatusesCard"

export default {
    name: 'Order',
    mixins: [InitializeOrderMixin],
    data: function () {
        return {
            order: null,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
        }
    },

    components: {
        OrderDataBlock,
        OrderStatusesCard
    },

    methods: {

        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },
        onStatusUpdated(payload){

            this.showSnackbar(payload);
        },
        getCurrentOrder() {

            let requestStr = '';

            let route = this.$route.path.split('/');
            let mediaType = route[1];

            if (mediaType === 'sponsorships') {

                requestStr = `sponsorships/${this.$route.params.id}`;
            } else if (mediaType === 'signage-orders') {

                requestStr = `signage-orders/${this.$route.params.id}`;
            }

            this.$axios.get(requestStr)
                .then((res) => {
                    this.order = this.initializeOrder(res.data);
                })
        },

        // updateOrderStatus(statusId) {
        //     let payload = {};
        //     const fieldName = this.order.mediaType === 'signage' ? 'status_id' : 'sponsorship_status';
        //
        //     payload[fieldName] = statusId;
        //     this.order.updateOrder(payload)
        //         .then(() => {
        //
        //             this.showSnackbar('The status was updated successfully');
        //             this.getCurrentOrder();
        //
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //             this.showSnackbar('Opps something went wrong, please try again!');
        //         });
        //
        //     this.$forceUpdate();
        // }
    },

    created() {
        this.getCurrentOrder();
    },
}
</script>

<style lang="scss" scoped>
.box {

    &__status-flow,
    &__shipment-info,
    &__content {
        //margin-left: 40px;
        @include white-card-with-subheading;
    }

    &__content {
        padding: 0rem !important;
    }


    &__controls {
        padding: 0;
    }

    /deep/ &__status-flow {
        @include manage-status-card;

        .md-button {
            white-space: normal;
        }
    }

    &__shipment-info {
        padding-right: 15px;
    }

    .details {
        list-style: none;
        width: 100%;

        &__item {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-top: 5px;
        }

        &__header {
            font-weight: 600;
        }
    }
}

.single-item {
    margin-left: 20px;
    /*width: 40%;*/
}

.no-boxes-msg {
    margin-top: 100px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 1.5em;
    color: white;
}

.box-background {
    background-color: #57585B;
}
</style>