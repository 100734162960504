<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100"
            v-for="(status, index) in statuses"
            :key="index + '_status'">
            <md-button

                class="md-raised btn--narrow"
                :class="{'md-primary':(order.status === status.id)}"
                @click="changeOrderStatus(status)">
                {{ status.name }}
            </md-button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OrderStatusesCard',
    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    data: function() {
        return {
            statuses: [],
        };
    },

    methods: {

        changeOrderStatus(status) {

            let payload = {};
            const fieldName = this.order.mediaType === 'signage' ? 'status_id' : 'sponsorship_status';
            payload[fieldName] = status.id;

            this.order.updateOrder(payload).then(() => {

                this.$emit('statusUpdated', 'The status was updated successfully')
                this.order.status = status.id;
            }).catch(function(error) {
                console.log(error);
                this.$emit('statusUpdated', 'Opps something went wrong, please try again!')
            });
        },

        getSponsorshipStatuses() {

            this.$axios.get('sponsorship-statuses').then((response) => {

                let statuses = response.data;

                for (let i = 0; i < statuses.length; i++) {

                    this.statuses.push({
                        id: statuses[i].sponsorship_status_id,
                        name: statuses[i].sponsorship_status_name,
                        stage: statuses[i].sponsorship_status_stage,
                    });
                }
            });
        },

        getSignageStatuses() {

            this.$axios.get('signage-statuses').then((response) => {

                this.statuses = response.data;
            });
        },
    },
    created() {

        if (this.order.mediaType === 'sponsorship') {

            this.getSponsorshipStatuses();
        } else {

            this.getSignageStatuses();
        }
    },
};
</script>


<style lang="scss" scoped>
.md-menu {
    margin-left: 5px;

    @include respond(xsmall) {
        margin: 0;
    }
}

/deep/ .btn--narrow {
    padding: 0;
    white-space: normal;
    width: 100%;

    @include respond(xsmall) {
        margin: 8px 8px 0 0;
    }

    .md-ripple {
        padding: 0;
    }
}
</style>