<template>
	<div class="search__form md-layout md-gutter">

		<div class="md-layout-item md-size-100 previous-season-toggle">
			<span class="toggle-label"><strong>Show previous season orders: </strong></span>

			<toggle-button
					v-model="previous_season"
					color="#CD0A2F"
					:width=80
					:font-size=17
					:height=35
					:labels="{checked: 'On', unchecked: 'Off'}"/>
		</div>

		<div class="search__form__custom-layout md-layout-item md-size-40 md-medium-size-100 md-small-size-100">
			<AppInput class="search__form__custom md-layout-item" v-model="searchStr" placeholder="Search" id="search-field-main"/>
		</div>

		<div class="dropdown-select-search md-layout-item md-size-15 md-medium-size-100 md-small-size-100 md-small-size-100">
			<div class="md-field ">
				<label>Order Type</label>

				<AppInputSelectDropdown
						class="create-form__field"
						v-model="filters.media_type"
						@change="search()">

					<option value="sponsorship"> Sponsorships</option>
					<option value="signage"> Operational Signages</option>

				</AppInputSelectDropdown>
			</div>
		</div>

		<div class="dropdown-select-search md-layout-item md-size-15 md-medium-size-100 md-small-size-100 md-small-size-100">
			<md-field class=" ">
				<div class="size-field">
					<label>Size</label>
					<md-input v-model="filters.size"></md-input>
				</div>
			</md-field>
		</div>

		<div class="dropdown-select-search md-layout-item md-size-15 md-medium-size-100 md-small-size-100">
			<md-field class="">
				<label>Status</label>

				<AppInputSelectDropdown
						v-if="isSponsorshipSelected == false"
						class="create-form__field"
						v-model="filters.status_id"
						@change="search()">
					<option value="">All</option>
					<option v-for="(status, index) in signageStatusesOptions" :key="index" :value="status.id">
						{{ status.name }}
					</option>

				</AppInputSelectDropdown>

				<AppInputSelectDropdown
						v-if="isSponsorshipSelected == true"
						class="create-form__field"
						v-model="filters.status_id"
						@change="search()">
					<option value="">All</option>
					<option v-for="(status, index) in sponsorshipStatusesOptions" :key="index" :value="status.id">
						{{ status.name }}
					</option>

				</AppInputSelectDropdown>

			</md-field>
		</div>

		<div class="dropdown-select-search md-layout-item md-size-15 md-medium-size-100 md-small-size-100">
			<md-field class="">
				<label>Material</label>

				<AppInputSelectDropdown
						class="create-form__field"
						v-model="filters.material_id"
						@change="search()">

					<option v-for="(material, index) in materials" :key="index" :value="material.id">
						{{ material.name }}
					</option>

				</AppInputSelectDropdown>
			</md-field>
		</div>
	</div>
</template>

<script>

    import AppInputSelectDropdown from '@/components/Base/AppInputSelectDropdown';
	import Cookie from 'js-cookie'

    export default {
        name: 'MaterialDashboardSearch',
        components: {
            AppInputSelectDropdown,
        },

        data: function () {
            return {
                orders: [],
                loadingSpinner: false,
                sponsorshipStatusesOptions: [],
                signageStatusesOptions: [],
                materials: [],
                searchStr: '',
                showSidePanel: false,
                showTabletTopPanel: false,
                previous_season: false,
                filters: {
                    search: null,
                    media_type: 'sponsorship',
                    size: null,
                    status_id: null,
                    material_id: null,
                },

                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000,
                },
            };
        },

        methods: {

            getMaterials(){

                this.$axios.get('/materials').then((response) => {

                    this.materials = response.data;
                });
            },

            getSponsorshipStatuses() {

                this.$axios.get('sponsorship-statuses').then((response) => {

                    let statuses = response.data;

                    for (let i = 0; i < statuses.length; i++) {

                        this.sponsorshipStatusesOptions.push({
                            id: statuses[i].sponsorship_status_id,
                            name: statuses[i].sponsorship_status_name,
                            stage: statuses[i].sponsorship_status_stage,
                        });
                    }
                });
            },


            getSignageStatuses() {

                this.$axios.get('signage-statuses').then((response) => {

                    this.signageStatusesOptions = response.data;
                });
            },

            search(callRoute = true) {

                this.$emit('loadingSearchResults', true);

                let query = {
                    media_type: this.filters.media_type,
                    size: this.filters.size,
                    material_id: this.filters.material_id,
                    status_id: this.filters.status_id,
                };

                if (this.filters.media_type === 'signage') {

                    if (this.previous_season) {
                        this.signageOrdersSearch({params: {...this.filters, previous_season: true}});
                        query['previous_season'] = true;
                    } else {
                        this.signageOrdersSearch({params: {...this.filters, current_season: true}});
                        query['current_season'] = true;
                    }

                } else if (this.filters.media_type === 'sponsorship') {

                    if (this.previous_season) {
                        this.sponsorshipsSearch({params: {...this.filters, previous_season: true}});
                        query['previous_season'] = true;
                    } else {
                        this.sponsorshipsSearch({params: this.filters});
                    }
                } else {

                    this.$emit('loadingSearchResults', false);
                }

                if (callRoute) {
                    this.$router.push({
                        path: 'material',
                        query: query,
                    }).catch(err => {
                        console.log(err.message)
                    });
                }
            },

            signageOrdersSearch(data) {

                this.$axios.get(`signage-orders?season_id=${Cookie.get('season_id')}`, data).then((response) => {

                    this.$emit('setResultsOrders', response.data);
                    this.$emit('loadingSearchResults', false);
                }).catch(() => {
                    this.$emit('loadingSearchResults', false);
                });
            },

            sponsorshipsSearch(data) {

                this.$axios.get(`sponsorships?season_id=${Cookie.get('season_id')}`, data).then(response => {
                    this.$emit('setResultsOrders', response.data);
                    this.$emit('loadingSearchResults', false);
                }).catch(() => {
                    this.$emit('loadingSearchResults', false);
                });
            },

            async loadData() {

                try {
                    this.getSponsorshipStatuses();
                    this.getSignageStatuses();
                    this.getMaterials();
                } catch (err) {

                    this.showSnackbar(err.message);
                }
            },
        },

        created() {

            this.loadData();
            // this.filters = this.$store.getters['getVendorFilters'];
            this.orders = this.$store.getters['getVendorOrders'];

            let previousSeasonLocalStorageString = localStorage.getItem('previous_season');

            if (previousSeasonLocalStorageString === 'true') {
                this.previous_season = true;
            } else {
                this.previous_season = false;
            }

            if (this.$route.query.media_type === 'signage') {

                this.signageOrdersSearch({params: {...this.$route.query, current_season: true}});
            } else if (this.$route.query.media_type === 'sponsorship') {

                this.sponsorshipsSearch({params: this.$route.query});
            }
        },

        computed: {
            isSponsorshipSelected() {

                return this.filters.media_type !== 'signage';
            },
        },

        watch: {

            filters: {
                deep: true,
                handler (value) {
                    let filtersObject = {
                        filters: value,
                        season: this.previous_season
                    };

                    this.$emit('updateFilterResults', filtersObject);
                }
            },

            searchStr(value){
                console.log('Search String Input');
                console.log(value)

                this.filters.search = value;

                let filtersObject = {
                    filters: this.filters,
                    season: this.previous_season
                };

//                this.$emit('searchInput', value);
                this.filters.search = value;
                this.$emit('updateFilterResults', filtersObject);
                this.search();
            },

            previous_season(value) {

                let filtersObject = {
                    filters: this.filters,
                    season: value
                };

                this.$emit('updateFilterResults', filtersObject);

                this.search();
                localStorage.setItem('previous_season', value);
            },

            'filters.size': function () {
                this.search();
            }
        },
    };
</script>

<style lang="scss" scoped>

	.search {

		&__header {
			font-size: 55px !important;
			line-height: 65px;
		}

		&__form {

			select#spaces-signage {
				width: 100%;
			}

			.md-field {
				border: 1px solid black;
				box-sizing: border-box;
				height: 66px;
				padding-top: 0px !important;

				@include respond(large) {
					min-width: 140px;

				}

				@media only screen and (max-width: 1619px) and (min-width: 1287px) {
					max-width: 188px;
				}

				@include respond(small) {
					height: 50px;
					padding-top: 1px;
				}

				@media only screen and (min-width: 950px) {
					.md-menu.md-select {
						margin-top: 15%;
						margin-left: 2px;
					}
				}

				@media only screen and (max-width: 950px) {
					.md-menu.md-select {
						margin-top: 15px;
						margin-left: 2px;
					}
				}

				label {
					font-family: Roboto;
					font-style: normal;
					font-weight: bold;
					padding-left: 12px;
					color: black;
					font-size: 20px;
					line-height: 0px;
					top: 15px;

					@include respond(small) {
						font-size: 14px;
						top: 10px;
					}
				}

				select {
					border: 0;
				}
			}

			.desktop-search-button {
				height: 110px;
				background: rgba(231, 82, 82, 0.88) !important;
				width: 100%;

				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 30px;
				line-height: 35px;
				color: black !important;

				@include respond(small) {
					height: 50px;
					padding-top: 1px;
					font-size: 16px;
				}
			}

			&__custom {
				@include respond(large) {
					min-width: 180px;
				}
			}
		}

	}

	::v-deep .control-field {
		margin: 4px 0 24px;
	}

	::v-deep .control-field__input {
		border: 1px solid black !important;
		box-sizing: border-box;
		margin-right: 20px;
		height: 110px;
		border-radius: 0px !important;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 28px;
		padding-left: 5px !important;

		@include respond(small) {
			height: 50px;
			padding-top: 1px;
			font-size: 16px;
		}
	}

	.md-layout.md-gutter > .md-layout-item {
		padding-right: 8px !important;
		padding-left: 8px !important;
	}

	.previous-season-toggle {
		text-align: right;
		margin-bottom: 20px;

		.toggle-label {
			font-size: 1.2em;
		}
	}

	.create-form-size__field {
		input {
			background-color: white !important;
		}
	}

	.size-field {

		input {
			height: 66px;
			padding-left: 10px;
		}

		label {
			font-size: 2rem;
		}
	}

	.size-container {
		height: 66px;
	}

	.search__form__custom-layout {
		margin-top: auto;
	}

	.search__form__custom {
		/deep/ input {
			border-radius: 20px !important;
			padding-left: 15px !important;
			height: 66px;
			margin-top: auto;
			margin-bottom: auto;
		}
	}

</style>
