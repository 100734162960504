<template>
    <div class="component-wrap">

        <AppContentHeader headline="Installer Dashboard">
           <AppInput
            class="filter-orders__field"
            required
            placeholder="Filter by any option"
            v-model="filter"
        />
        </AppContentHeader>

        <div class="md-layout md-alignment-top-space-between boxes">

            <!--<virtual-list :size="160" :remain="8" class="scroller">-->

                <!--<OrderCard-->
                        <!--v-for="order in filteredOrders"-->
                        <!--:key="order.index + '_orders'"-->
                        <!--:orderData="order"-->
                        <!--cardMode="installer"-->
                        <!--class="md-layout-item">-->
                    <!--</OrderCard>-->

                <!--<AppEmptyState v-if="!orders.length" class="md-layout-item md-large-size-100" message="No orders to display"/>-->

            <!--</virtual-list>-->
            <installation-dashboard-table
                :installations="orders"
            ></installation-dashboard-table>

        </div>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->


    </div>
</template>

<script>

//import OrderCard from "@/components/Common/OrderCard.vue"
import InstallationDashboardTable from "@/components/InstallerDashboard/InstallationDashboardTable.vue"
import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
import Cookie from 'js-cookie'

export default {
    name: 'InstallerDashboard',
    mixins: [DeepFilterMixin],
    data: function() {
        return {
            orders: [],
            ordersToDisplay: [],
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loadingSpinner: false,
            itemsToShow: 9,
            filter: '',
            scrollPosition: 0
        }
    },
    
    components: {
		InstallationDashboardTable
    },
    
    created() {
        this.getOrders();
    },

    computed: {
        filteredOrders() {
            return this.orders.filter(x => this.filterFunction(x, this.filter));
        }
    },

    methods: {

        onFilterInput() {
            const previousFilter = this.filter;

            setTimeout(() => {

                if (this.filter === previousFilter) {
                    this.filterCards();
                }
            }, 400);
        },

        async getOrders() {
            this.loadingSpinner = true;
            try {
                const sponsorships = (await this.$axios.get(`/sponsorships?season_id=${Cookie.get('season_id')}`)).data.map(x => {
                    return {
                        ...x,
                        index: x.sponsorships_id };
                });
                const signage = (await this.$axios.get(`/signage-orders?season_id=${Cookie.get('season_id')}`)).data.map(x => {
                    return {
                        ...x,
                        index: x.id };
                });
                this.orders = [...sponsorships, ...signage];

                for(let i = 0; i < this.order.length; i++){
                  this.order['ís_expanded'] = false;
                }

                this.filteredOrders = [...sponsorships, ...signage];
                this.loadingSpinner = false;
            } catch (err) {
                this.loadingSpinner = false;
                this.snackbar.message = err.message;
                this.snackbar.show = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@include scroller-styles;

.boxes {
    width: 100%;
}
.filter-orders__field {
    width: 100%;
    max-width: 320px;
}
</style>