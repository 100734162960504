const state = () => ({
    selectedLocations: []
});

const getters = {
    getSelectedLocations(state) {
        return state.selectedLocations;
    }
};

const mutations = {
    setSelectedLocations(state, locations) {
        state.selectedLocations = locations;
    }
}

const actions = {
    setSelectedLocations(vueContext, locations) {
        vueContext.commit('setSelectedLocations', locations);
    },
    getSelectedLocations(vueContext, locations) {
        vueContext.commit('setSelectedLocations', locations);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
