<template>
    <md-dialog :md-active.sync="showDialog"
                class="confirm-dialog"
                :md-click-outside-to-close="false">
        
        <md-dialog-title class="confirm-dialog__header">
            Confirm your action
        </md-dialog-title>
        <!-- end of dialog header -->

        <div class="confirm-dialog__content">
            <p class="md-body-2">{{question}}</p>
        </div>

        <md-dialog-actions>
            <md-button class="md-primary md-raised" @click="closeDialog()">Cancel</md-button>
            <md-button  class="md-primary md-raised"
                        @click="confirmAction()">Yes, I'm sure</md-button>
        </md-dialog-actions>
        <!-- end of dialog actions -->

    </md-dialog>
</template>

<script>
export default {
    name: 'AppConfirmlDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        question: {
            type: String,
            default: 'Are you sure?'
        },
    },
    data: () => ({}),
    methods: {
        closeDialog() {
            this.$emit('update:showDialog', false);

        },
        confirmAction() {
            this.$emit('actionConfirmed');
            this.closeDialog();
        }
    }

}
</script>

<style lang="scss" scoped>
    .confirm-dialog {
        max-width: 768px;
        width: 500px;

        &__content {
            padding: 0 2.4rem;
        }
    }
</style>