<template>
	<div class="component-wrap main-shipment-card-container md-layout md-raised md-elevation-4" @click="goToDetails()">
		<div class="md-layout-item md-small-size-100  shipment-single__info">
			<h5 class="md-subheading">Shipment #{{ shipment.id }}</h5>
			<md-button class="md-raised md-primary"  v-if="!isMobile()" @click.stop="editShipment()">Edit</md-button>
		</div>

		<div class="md-layout-item md-small-size-100 shipment-single__items">
			<ul class="shipment-single__list">
				<li class="shipment-single__item md-body-2">License plate: {{ shipment.license_plate }}</li>
				<li class="shipment-single__item md-body-2">Carrier: {{ shipment.carrier }}</li>
				<li class="shipment-single__item md-body-2">Tracking Number: {{ shipment.tracking_number }}</li>
				<li class="shipment-single__item md-body-2">Shipped On: {{ shipment.shipped_on }}</li>
				<li class="shipment-single__item md-body-2">Arrived On: {{ shipment.arrived_on }}</li>
				<li class="shipment-single__item md-body-2">Created At: {{ shipment.created_at }}</li>
				<li class="shipment-single__item md-body-2">Created By: {{ shipment.created_by_name }}</li>
				<li class="shipment-single__item md-body-2">Driver Name: {{ shipment.driver_name }}</li>
				<li class="shipment-single__item md-body-2">Driver Phone: {{ shipment.driver_phone }}</li>
			</ul>
		</div>

        <md-button class="md-raised md-primary"  v-if="isMobile()" @click.stop="editShipment()">Edit</md-button>

        <md-tooltip md-direction="top">Click to see more info</md-tooltip>
	</div>
</template>

<script>
export default {
	name: 'ShipmentCard',
	props: {
		shipment: {
			type: Object,
		},
	},
	data: function () {
		return {}
	},
	methods: {
		editShipment() {
			this.$router.push('/shipment-edit/' + this.shipment.id);
		},

		goToDetails() {
			this.$router.push('/shipments/' + this.shipment.id);
		},

        isMobile() {

            if (screen.width <= 760) {
                return true;
            } else {
                return false;
            }
        }
	}
}
</script>

<style lang="scss" scoped>
.shipment-single {
	display: flex;
	padding: 1.6rem 2.4rem;
	margin: 0 0 $margin-medium;
	cursor: pointer;

	&__info {
		display: flex;
		margin-right: 40px;
		flex-direction: column;
		justify-content: space-between;

		h5 {
			font-weight: bold;
		}
	}

	&__items {
		width: 60%;
		margin-left: auto;
	}

	&__list {
		list-style: none;
		padding: 0;

        @include respond(small) {
            margin: 16px 0;
        }
	}
}

.shipment-single__item {
	font-weight: 400;
}

.main-shipment-card-container {
	padding: 20px;
	cursor: pointer;
}
</style>