<template>
  <div>
    <div style="text-align: center" v-if="loading">Loading...</div>
    <canvas class="text-center mt-2" id="the-canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "MandatePDF",
  props: {
    pdf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      canvasSize: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      pdfDoc: null,
      loading: false,
      pageRendering: false,
    };
  },
  watch: {
    pdf() {
      console.log("New pdf received");
      this.renderNewPdf();
    },
  },
  created() {
    this.renderNewPdf();
  },
  methods: {
    renderNewPdf() {
      this.loading = true;
      let pdfjsLib = window["pdfjs-dist/build/pdf"];
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;
      setTimeout(() => {
        this.canvasEl = document.getElementById("the-canvas");
        this.ctx = this.canvasEl.getContext("2d");
        pdfjsLib.getDocument(this.pdf).promise.then((pdfDoc_) => {
          this.pdfDoc = pdfDoc_;
          // Initial/first page rendering
          this.renderPage(1, 1, this.canvasEl, this.ctx);
          this.loading = false;
        });

      }, 1000);
    },
    renderPage(num, scale, canvasEl, ctx) {
      this.pageRendering = true;
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then(function (page) {
        let viewport = page.getViewport({ scale: scale });
        canvasEl.height = viewport.height;
        canvasEl.width = viewport.width;

        // Render PDF page into canvas context
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
      });
      // Update page counters
    },
  },
};
</script>

<style scoped></style>
