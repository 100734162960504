<template>
<div class="signage-setup-teardown-data component-wrap">

    <h6 class="md-body-1">Setup / Tear down</h6>
    
    <p class="time-text">
        <span class="time-text__title">Install by: </span>
        <span class="time-text__date" v-if="orderData">{{new Date(orderData.install_by) | dateFormat('MM/DD/YYYY hh:mm a')}}</span>
        <span class="time-text__date" v-if="!orderData">---</span>
    </p>
    
    <p class="time-text">
        <span class="time-text__title">Leave until:</span>
        <span class="time-text__date" v-if="orderData">{{new Date(orderData.leave_until) | dateFormat('MM/DD/YYYY hh:mm a')}}</span>
        <span class="time-text__date" v-if="!orderData">---</span>
    </p>
    
    <p class="time-text">
        <span class="time-text__title">Remove by:</span>
       <span class="time-text__date" v-if="orderData"> {{new Date(orderData.remove_by) | dateFormat('MM/DD/YYYY hh:mm a')}}</span>
       <span class="time-text__date" v-if="!orderData">---</span>
    </p>
</div>
</template>

<script>
export default {
    name: 'InstallerDashboardSetUpTearDownData',
    props: {
        orderData: {
            type: Object,
            required: true
        }
    },
    data: function() {
        return {}
    },

    created(){
    }

}
</script>

<style lang="scss" scoped>
.signage-setup-teardown-data {
    padding: 1.6rem 2.4rem;

    h6 {
        margin-bottom: 2.4rem;
    }
}
.time-text {
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
        font-size: 1.6rem;
    }

    &__date {
        font-size: 3.2rem;
        width: 100%;
        text-align: center;
        line-height: 4.8rem;
        border: 2px solid rgba($color-primary, .4);
        border-radius: 5px;
        margin: 1.6rem 0 2.4rem;
        color: rgba($color-black, .6);
    }
}


.time-text__date{
    @include respond(medium) {
        font-size: 2.3rem!important;
    }
}
</style>