<template>
	<div class="component-wrap">

		<AppContentHeader headline="Create Shipment" v-if="!$route.params.id">
		</AppContentHeader>
		<AppContentHeader headline="Edit Shipment" v-if="$route.params.id">
		</AppContentHeader>

		<div class="md-layout md-alignment-top-space-between boxes">
			<form class="md-layout md-layout-item shipment-form">
				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="Name"
							v-model="$v.shipment.name.$model"
							:error="$v.shipment.name.$invalid && $v.shipment.name.$dirty"
							errorText="Name is required"
					/>

				</div>
				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="License Plate"
							v-model="$v.shipment.license_plate.$model"
							:error="$v.shipment.license_plate.$invalid && $v.shipment.license_plate.$dirty"
							errorText="Valid license is required"
					/>

				</div>

				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="Carrier"
							v-model="$v.shipment.carrier.$model"
							:error="$v.shipment.carrier.$invalid && $v.shipment.carrier.$dirty"
							errorText="Valid carrier is required"
					/>

				</div>

				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="Tracking Number"
							v-model="$v.shipment.tracking_number.$model"
							:error="$v.shipment.tracking_number.$invalid && $v.shipment.tracking_number.$dirty"
							errorText="Valid tracking number is required"
					/>

				</div>

				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="Driver Name"
							v-model="$v.shipment.driver_name.$model"
							:error="$v.shipment.driver_name.$invalid && $v.shipment.driver_name.$dirty"
							errorText="Driver Name is required"
					/>

				</div>

				<div class="md-layout-item md-raised shipment-input-containers md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
					<AppInput
							class="shipment-form__field"
							required
							placeholder="Driver Phone"
							v-model="$v.shipment.driver_phone.$model"
							:error="$v.shipment.driver_phone.$invalid && $v.shipment.driver_phone.$dirty"
							errorText="Driver Phone is required"
					/>
				</div>
				<md-button class="md-primary md-raised shipment-form__submit-btn" :disabled="$v.$invalid" @click="submitForm()">Save</md-button>

			</form>
		</div>

		<md-snackbar
				md-position="center"
				:md-active.sync="snackbar.show"
				:md-duration="snackbar.duration"
				md-persistent>

			<span>{{snackbar.message}}</span>
			<md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

		</md-snackbar>

	</div>
</template>

<script>

	import {required} from 'vuelidate/lib/validators';

	export default {
		name: 'ShipmentForm',
		data: function() {
			return {
				shipment: {
					license_plate: '',
					carrier: '',
					tracking_number: '',
					driver_name: '',
					driver_phone: '',
					name: ''
				},
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },

            }
		},
		validations() {

			let shipment = {
				license_plate: {required},
				carrier: {required},
				tracking_number: {required},
				driver_name: {required},
				driver_phone: {required},
				name: {required}
			};

			return {
				shipment
			}
		},

		methods: {

            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },

			submitForm(){

				let _this = this;
				let shipmentObj = this.shipment;

				let url = '/shipments';
				let method = 'post';
				let successMsg = 'Successfully created!';

				if (this.$route.params.id) {
					method = 'put';
					url = '/shipments/' + this.$route.params.id;
					successMsg = 'Successfully updated!';
				}

				this.$axios({ method: method, url: url, data: shipmentObj})
					.then( () => {

                        _this.$router.push('/shipments');
						_this.showSnackbar(successMsg);
					})
					.catch(function (error) {
						console.log(error);

						_this.showSnackbar('Opps something went wrong, please try again!');
					});
			},

			getCurrentShipment(id){

				this.$axios.get(`/shipments/${id}`)
					.then((res) => {
						this.shipment = res.data;
						this.$forceUpdate();
					})
			}
		},

		created(){
			if(this.$route.params.id){
				this.getCurrentShipment(this.$route.params.id);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.boxes {
		width: 100%;
	}

	.shipment-input-containers {
		margin-bottom: 20px;
		margin-right: 20px;
	}

	.shipment-form__submit-btn{
		width: 120px;
		height: 45px;
		font-size: 2rem;
	}
</style>