<template>
    <div class="component-wrap md-elevation-5">
        
        <div class="logo__wrap">
            <img src="@/assets/img/tcs_logo_2x.png" alt="Logo image" class="logo__image">
        </div>

       
        <div class="content">
            <h1 class="content__headline">Please, choose campus you would like to work with</h1>

            <div class="card" @click="redirectToCampus('atl')">
                <p class="card__title">Atlanta</p>
                <md-icon class="card__arrow">forward</md-icon>
            </div>
            
            <div class="card" @click="redirectToCampus('vegas')">
                <p class="card__title">Las Vegas</p>
                <md-icon class="card__arrow">forward</md-icon>

            </div>
            
            <div class="card" @click="redirectToCampus('hp')">
                <p class="card__title">High Point</p>
                <md-icon class="card__arrow">forward</md-icon>
            </div>
        
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseCampusPage',
    methods: {
        redirectToCampus(prefix) {
            let campusLink = prefix + '.' + window.location.hostname;
            window.location = `https://${campusLink}`;
        }
    }
}
</script>

<style lang="scss" scoped>

.component-wrap {
    width: 100%;
    max-width: 1190px;
    margin: 4.8rem auto;
    display: grid;
    grid-template-columns: 200px minmax(360px, 1fr);
    grid-gap: 4.8rem;
    border: 2px solid $color-grey;

    @include respond(small) {
        grid-template-columns: minmax(100%, 1fr);
        margin: 3.2rem;
        width: calc(100% - 6.4rem);
    }
}
.logo {
    &__wrap {
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: $color-grey;
    }

    &__image {
        object-fit: contain;

        @include respond(small) {
            margin: 3.2rem;
        }
    }

}
.content {
    padding: 4.8rem 3.2rem 3.2rem;

    &__headline {
        font-size: 3.2rem;
        line-height: 3.6rem;
        font-weight: 300;
        margin: 0 0 4.8rem 0;
        
        @include respond(small) {
           width: 60%;
           min-width: 300px;
           margin: 0 auto 3.2rem;
        }
    }
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 2.4rem 2.4rem 2.4rem 3.2rem;
    margin: 0 0 3.2rem;
    cursor: pointer;

    background: linear-gradient(30deg, $color-primary 0%, $color-primary 50%, #fff 50%), #fff;
    background-size: 250%;
    background-position: right top;
    transform: scaleX(1) translateY(0);

    transition: all .4s ease-in-out;
    
    &:hover {
        color: #fff;
        transform: scaleX(1.05) translateY(-2px);
        background-position: left bottom;
    }

    &__title {
        text-transform: uppercase;
        font-family: 'Montserrat', 'Open Sans', 'Avenir','PT Sans', sans-serif;
        font-size: 1.8rem;
        letter-spacing: 1.2px;
        font-weight: 600;
    }

    &__arrow {
        margin: 0;
        color: #fff;
        animation: pulse .8s infinite;
    }

    @include respond(small) {
        margin: 0 auto 3.2rem;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

/** Used important to overwrite other !important staement from 
    VueMaterial framework. I had to do that because default 
    VueMaterial sizes (2x,3x,5x) were not appropriate */

/deep/ .md-icon.md-theme-default.md-icon-font {
    color: #fff;
    font-size: 32px !important;
}

</style>