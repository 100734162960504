var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item__status-flow"},[_c('h6',{staticClass:"md-subheading"},[_vm._v("Manage statuses")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '8')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '35')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '8' : '35')}}},[_vm._v("Printed")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '13')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '28')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '13' : '28')}}},[_vm._v("Packing")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '14')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '29')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '14' : '29')}}},[_vm._v("Loaded on track")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '15')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '30')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '15' : '30')}}},[_vm._v("Shipped")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '16')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '31')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '16' : '31')}}},[_vm._v("In staging")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '17')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '32')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '17' : '32')}}},[_vm._v("Unpacked")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '9')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '8')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '9' : '8')}}},[_vm._v("Installed")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '18')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '33')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '18' : '33')}}},[_vm._v("Problem")]),_c('md-button',{staticClass:"md-raised",class:{
            'md-primary': 
            (_vm.order.mediaType === 'signage' && _vm.order.status === '19')
            || (_vm.order.mediaType === 'sponsorship' && _vm.order.status === '34')
        },on:{"click":function($event){return _vm.updateStatus(_vm.order.mediaType === 'signage' ? '19' : '34')}}},[_vm._v("Solved")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }