<template>
    <div class="component-wrap">
        <AppContentHeader :headline="'QR Code Scanner'">
            <md-button class="md-primary md-raised"
                       @click="goBack()">
                Close
            </md-button>
        </AppContentHeader>

        <div class="md-layout md-alignment-top-space-between box">
            <div class="md-layout-item md-size-100">
                <div class="scanner">
                    <qrcode-stream @decode="onDecode" v-if="isCameraOn" @init="onInit" camera="auto">
                    </qrcode-stream>
                </div>
            </div>
        </div>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{ snackbar.message }}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
    </div>
</template>

<script>


export default {
    name: "ShipmentScanner",
    data:  function () {
        return {
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            isCameraOn: true
        }
    },

    methods: {
        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },

        goBack(){

			this.$router.go(-1);
        },

        turnScannerOn() {

        },

        turnScannerOff() {

        },

        onDecode(decodedString) {

            if (this.$route.params.type == 'shipment') {

				return this.decodeForBoxInShipment(decodedString);
			}

			if (this.$route.params.type == 'box') {

				return this.decodeForItemInBox(decodedString);
			}

			return this.$router.push(decodedString);
        },

        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        },

		decodeForBoxInShipment(decodedString) {
            let _this = this;

			const uuid = decodedString.split('/').pop();
			this.$axios.post('/shipments/' + this.$route.params.id + '/boxes' , { box_uuid: uuid })
				.then(() => {
                    this.showSnackbar('Its added successfully');
                    this.isCameraOn = false;
                    this.isCameraOn = true;
                })
				.catch(function (error) {

                    _this.showSnackbar('Oops, something went wrong, and its not added.');
                    _this.isCameraOn = false;
                    _this.isCameraOn = true;
                    console.log(error);
				});
		},

		decodeForItemInBox(decodedString) {

			let decodeData = decodedString.split('/');
			const order_id = decodeData.pop();
			const type = decodeData.pop();
			const uuid = this.$route.params.id;

			let _this = this;

			this.$axios.put('/boxes/' + uuid + '/orders' , { order_id, type })
				.then(() => {

                    this.showSnackbar('Its added successfully');
                    this.isCameraOn = false;
                    this.isCameraOn = true;

                })
				.catch(function (error) {
					console.log(error);
                    _this.isCameraOn = false;
                    _this.isCameraOn = true;
                    _this.showSnackbar('Oops, something went wrong, and its not added.');
                });

		}
    },
}
</script>

<style lang="scss" scoped>
    .qr-code-container{
        height: 50%;
        background-color: #E2E2E2;
        color: #666666;
        font-size: 3.4rem;
        text-align: center;
        padding-top: 12.5%;
    }
</style>