<template>

    <div class="content-header">
        <h1 class="md-headline">{{headline}}</h1>
        <slot/>    
    </div>

</template>

<script>
    export default {
        name: 'AppContentHeader',
        props: {
            headline: {
                type: String,
                default: 'Headline'
            }
        }
    }
</script>

<style lang="scss" scoped>
    
    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: $margin-big 0 $margin-medium;

        @include respond(small) {
            flex-direction: column;
            align-items: center;
        }

        .md-headline {
            margin: 0;
            font-weight: 600;
            
            @include respond(small) {
                margin-bottom: $margin-small;
            }
        }

        .control-field, 
        .md-button {
            @include respond(small) {
                width: 100%;
            } 
        }
    }

    /*
    * In case you need more than one button in page header,
    * you'll need to add actions wrapper for buttons */ 
    .content-header__actions {
        display: flex;
        align-items: flex-end;

        @include respond(small) {
            width: 100%;
            flex-direction: column;

            & > * {
                width: 100%;
            }
        }

        :not(:last-child) {
            margin-right: 2.4rem;

            @include respond(small) {
                margin-bottom: 1.6rem;
                margin-right: 0;
            } 
        }
    }
    
</style>