<template>
    <div class="component-wrap">
        <AppContentHeader headline="User management"/>
        <UserInvitation @getUsers="getUsers()"/>
        <UsersList ref="userList"/>

    </div>

</template>

<script>
import UserInvitation from '@/components/UserManagement/UserInvitation';
import UsersList from '@/components/UserManagement/UsersList';

export default {
    name: 'UserManagement',
    components: {
        UserInvitation,
        UsersList
    },
    methods: {
        getUsers() {
            console.log('asdasd');
            this.$refs.userList.getUsers();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>