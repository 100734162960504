import Cookie from 'js-cookie'
import {$axios} from '../../plugins/axios'

const setUpAuth = (token, user) => {
    // console.log('Here is token and user:', token, user);
    // Set up data to be consistent on reload
    // Cookie.set('current_user', token, { samesite: 'none' });
    Cookie.set('user_data', user);
    Cookie.set('current_user', token);
    
    // Set up request authorization
    $axios.defaults.headers.common['Authorization'] =  `Bearer ${token}`;
};

const state = () => ({
    user: null,
    sudoMode: false
});

const getters = {

    isAuthenticated() {
        return () => {
            return Cookie.get('current_user') != null;
        };
    },

    isSudoMode(state) {
        return state.sudoMode;
    },

    getCurrentUser(state) {
        return state.user;
    },
};

const mutations = {

    setUser(state, user) {
        state.user = user;
    },

    signOut(state) {
        state.user = null;
        Cookie.remove('current_user');
        Cookie.remove('user_data');
    },

    toggleSudoMode(state, sudoModeState) {
        state.sudoMode = sudoModeState;
    }
};

const actions = {

    checkUserToken(vuexContext) {
        const token = Cookie.get('current_user');
        const sudoToken = Cookie.get('real_user');

        if (sudoToken) {
            vuexContext.commit('toggleSudoMode', true);
        }

        if (token) {
            return vuexContext.dispatch('getUserFromToken', token);
        }

        return Promise.resolve();
    },
    
    signUpClient(vuexContext, user) {
        return this.$axios.post('auth/register', user)
            .then(() => {
                vuexContext.dispatch('initAuth');
            });
    },

    signOut(vuexContext) {
        const oldToken = Cookie.get('real_user');

        if (oldToken) {            
            Cookie.remove('real_user');
            vuexContext.commit('toggleSudoMode', false);
            return vuexContext.dispatch('getUserFromToken', oldToken);
        } else {
            vuexContext.commit('signOut');
            return Promise.resolve(null);
        }
    },

    getUserFromToken(vuexContext, token) {

        return $axios.get('/auth/identities/' + token)
            .then(res => {
                setUpAuth(token, res.data);
                vuexContext.commit('setUser', res.data);
                return res.data;
            });
    },

    impersonateUser(vuexContext, userID) {
        const token = Cookie.get('current_user');        
        Cookie.set('real_user', token);
        

        return $axios.post(`users/${userID}/impersonate`)
            .then(res => {
                setUpAuth(res.data.users_bearer_token, res.data);
                vuexContext.commit('setUser', res.data);
                vuexContext.commit('toggleSudoMode', true);
                return res.data;
            });
    },

    signUp(vuexContext, user) {
        return $axios.post('users', user)
            .then(res => {
                vuexContext.commit('setToken', res.token);
            });
    },

    signIn(vuexContext, data) {

        const payload = {
            "email": data.email,
            "password": data.password
        };

        return $axios.post('auth/login', payload)
            .then(res => {
                let token = res.data.users_bearer_token;
                let user = res.data;

                setUpAuth(token, user);
                vuexContext.commit('setUser', user);

                return res;
            })
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  