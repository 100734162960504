<template>
    <div class="component-wrap md-layout">

        <div class="md-layout md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout md-layout-item md-xlarge-size-70 md-large-size-70 md-small-size-50 md-xsmall-size-100">
                <div class="md-layout-item md-large-size-30 md-small-size-50 md-xsmall-size-100">
                    <AppContentHeader headline="Installation tickets" class="hdr-page"/>
                    <AppContentHeader v-if="highPriorityTickets.length > 0" headline="High Priority Tickets" class="hdr-page priority-hdr"/>
                </div>
                <div class="md-layout-item md-large-size-55 md-small-size-50 md-xsmall-size-100 order-switcher">
                    <span class="show_status_label">Show tickets by status:</span>
                    <select
                        class="show_status__field"
                        v-model="showStatus">
                        <option value="1">Open</option>
                        <option value="2">Closed</option>
                        <option value="3">Resolved</option>
                        <option value="4">Canceled</option>
                        <option value="5">Deferred</option>
                    </select>
                </div>
            </div>
        </div>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <div v-if="highPriorityTickets.length > 0" class="md-layout md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100 md-xsmall-size-100 md-alignment-top-center order_cards_container">
                <OrderCard
                    v-for="(ticket, index) in highPriorityTickets"
                    :key="index +'_order'"
                    cardMode="installationTickets"
                    :orderData="ticket"
                    class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
                </OrderCard>
        </div>

        <AppContentHeader v-if="highPriorityTickets.length > 0" headline="Non-High Priority Tickets" class="hdr-page non-priority-hdr"/>

        <div class="md-layout md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100 md-xsmall-size-100 md-alignment-top-center order_cards_container">
            <OrderCard
                    v-for="(ticket, index) in tickets"
                    :key="index +'_order'"
                    cardMode="installationTickets"
                    :orderData="ticket"
                    class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
            </OrderCard>
        </div>

        <AppEmptyState message="There is no tickets to display." v-if="!tickets.length && !loadingSpinner"/>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

    </div>
</template>

<script>
    import OrderCard from "@/components/Common/TicketCard.vue"

    export default {
        name: 'InstallationTicketsList',
        data: function () {
            return {
                showStatus: 1,
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
                highPriorityTickets:[],
                tickets:[],
                notFilteredTickets:[],
                itemsToShow: 9,
                scrollPosition: 0,
                ordersToDisplay: [],
                loadingSpinner: false

            }
        },
        components: {
            OrderCard
        },
        methods: {

            getAllTickets(){
                this.$axios.get('/tickets')
                    .then((res) => {

                        let signageTickets = res.data.signageOrderTickets;
                        let sponsorshipsTickets = res.data.sponsorshipTickets;

                        this.notFilteredTickets = [...sponsorshipsTickets, ...signageTickets];

                        for(let i = 0; i < this.notFilteredTickets.length; i++){
                            if(this.notFilteredTickets[i].status_id == 1){
                                this.tickets.push(this.notFilteredTickets[i]);
                            }
                        }

                    })
            },
        },

        watch:{
            showStatus(value){

                this.tickets = this.notFilteredTickets.filter(function( obj ) {
                    return obj.status_id == value;
                });
            }
        },

        created(){
             this.getAllTickets();
        }
    }
</script>

<style lang="scss" scoped>

    /deep/ .card-header__horizontal{
        justify-content: flex-start;

        .md-title{
            margin: 0 auto;
        }
    }

    /deep/ .walkthrough{
        text-align:center;
    }

    /deep/ .order_cards_container{
        justify-content: flex-start;
    }

    /deep/ .order-card:first-child{
        margin-left: 0px;
    }

    .show_status__field {
        margin-left: 0.8em;
        margin-top: 2.3em;
        margin-bottom: 1em;
        width: 40%;
        height: 35px;
        border-radius: 15px;
        padding-left: 10px;
        border: 2px solid #CD0A2F;
    }

    .show_status__field:focus{
        border: none;
    }

    .show_status_label{
        font-weight: bold;
        font-size: 1.4rem;
    }

    .non-priority-hdr{
        color: #F9BA2A;
        text-decoration: underline;
    }

    .priority-hdr{
        color: #CD0A2F;
        text-decoration: underline;
    }

    /deep/ .md-card-header:last-child{
        margin-bottom: 0px;
    }

</style>
