<template>
    
    <form class="user-invitation md-elevation-5">

        <h4 class="md-subheading">Invite new user</h4>

        <AppInput
            class="user-invitation__field"
            required
            placeholder="First name"
            v-model="$v.firstName.$model"
            :error="$v.firstName.$invalid && $v.firstName.$dirty"
            errorText="First name is required"
        />
        
        <AppInput
            class="user-invitation__field"
            required
            placeholder="Last name"
            v-model="$v.lastName.$model"
            :error="$v.lastName.$invalid && $v.lastName.$dirty"
            errorText="Last name is required"
        />

        <AppInput
            class="user-invitation__field"
            required
            placeholder="Email"
            v-model="$v.email.$model"
            :error="$v.email.$invalid && $v.email.$dirty"
            errorText="Valid email is required"
        />

        <AppInput
            controlType="select"
            class="user-invitation__field"
            required
            placeholder="Role"
            v-model="$v.role.$model"
            :error="$v.role.$invalid && $v.role.$dirty"
            errorText="Role is required">
            
            <option value="" selected>Choose Role</option>
            <option value="1">Admin</option>
            <option value="2">Manager</option>
            <option value="4">Account executive</option>
            <option value="5">Wide format printer</option>
            <option value="7">Facilitator</option>
            <option value="8">Designer</option>
            <option value="9">Installer</option>
            <option value="10">Production manager</option>
            
        </AppInput>

        <md-button
                class="md-primary md-raised user-invitation__btn"
                :disabled="$v.$invalid"
                @click.prevent="sendInvitation()">Send invitation</md-button>

          <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>
            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Got it</md-button>
        </md-snackbar>

    </form>

</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: 'UserInvitation',
    data: () => ({
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        snackbar: {
            show: false,
            message: '',
            duration: 4000
        },
    }),
    validations: {
        email: { required, email },
        role: { required },
        firstName: { required },
        lastName: { required }
    },
    methods: {
        showSnackbar(message = "Done") {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },

        sendInvitation() {

            const payload = {
                users_email: this.email,
                users_first: this.firstName,
                users_last: this.lastName,
                accounts_id: 1,
                users_role: this.role,
                users_phone: '',
                users_is_primary: 'N'
            };

            this.$axios.post('accounts/1/users', payload)
                .then(() => {
                    this.email = this.role = this.firstName = this.lastName = '';
                    this.$v.$reset();
                    this.showSnackbar("Invitation sent!");
                    this.$emit('getUsers');
                })
                .catch(err => {
                    this.showSnackbar("Ooops! " + err.response.data);
                })

        }
    }
}
</script>

<style lang="scss" scoped>
    .user-invitation {
        width: 100%;

        display: grid;
        grid-template-columns:  repeat(auto-fit, minmax(24rem, 1fr));;
        grid-gap: 1.6rem;
        @include white-card-with-subheading;

        .md-subheading {
            width: 100%;
            font-weight: 600;
            margin: 0;
            grid-column: 1 / -1;
        }

        &__btn {
            grid-column: -2 / -1;
        }

    }
</style>