<template>
	<div class="component-wrap">
		<AppContentHeader :headline='shipmentHeader'>
			<md-button class="md-primary md-raised" @click="goToScan('box')">
				Add item
			</md-button>
		</AppContentHeader>


		<div class="md-layout box">

			<div class="box__controls md-layout md-layout-item md-xlarge-size-30 md-large-size-30 md-small-size-100">

				<div
					class="box__status-flow md-layout-item md-large-size-100  md-small-size-50 md-xsmall-size-100 md-elevation-5">

					<h6 class="md-subheading">Manage statuses</h6>

					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 1)}" @click="updateBoxStatus(1)">
						New
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 2)}" @click="updateBoxStatus(2)">
						Being packed
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 3)}" @click="updateBoxStatus(3)">
						Packed. Ready to load.
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 4)}" @click="updateBoxStatus(4)">
						Loaded on truck
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 5)}" @click="updateBoxStatus(5)">
						Shipped
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 6)}" @click="updateBoxStatus(6)">
						Arrived. In staging
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(box.status_id == 7)}" @click="updateBoxStatus(7)">
						Unpacked
					</md-button>
				</div>
				<!-- end of statuses card -->

				<div
					class="box__shipment-info md-layout-item md-large-size-100  md-small-size-50 md-xsmall-size-100 md-elevation-5">
					<h6 class="md-subheading"><strong>Box Details</strong></h6>

					<ul class="details">
						<li class="details__item">
							<span class="details__header"><strong>uuid:</strong> </span>
							<span class="details__value">{{ box.uuid }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Shippment</strong></span>
							<span class="details__value">{{ box.shipment_id }}</span>
						</li>

					</ul>
				</div>
				<!-- end of box details card -->
			</div>

			<div :class="{'box-background': (items.length <= 0)}" class="box__content md-layout-item md-xlarge-size-65 md-large-size-65 md-small-size-100 md-elevation-5">
				<div class="md-layout">
					<div class="md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100">
						<h6 class="md-subheading" v-if="items.length > 0">Packed items</h6>
					</div>
					<div v-if="items.length > 0" class="md-layout-item md-layout md-xlarge-size-100 md-large-size-100 md-small-size-100">
						<OrderDataBlock v-for="(item, index) in items"
										:key="index +'_item'"
										:order="item"
                                        @click.native="goToOrderPage(item)"
										class="order-minimized single-item md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100"/>
					</div>


					<div class="md-accent md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100" v-if="items.length === 0">
						<div class="no-boxes-msg">Items will appear here <br>
							Click Add Item to add
						</div>
					</div>
				</div>
			</div>
		</div>

		<md-snackbar
			md-position="center"
			:md-active.sync="snackbar.show"
			:md-duration="snackbar.duration"
			md-persistent>

			<span>{{ snackbar.message }}</span>
			<md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

		</md-snackbar>
	</div>
</template>

<script>
import OrderDataBlock from "@/components/Common/OrderDataBlock"
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin'

export default {
	name: 'SingleShipmentBox',
    mixins: [InitializeOrderMixin],
	data: function () {
		return {
			items: [],
			box: {},
			snackbar: {
				show: false,
				message: '',
				duration: 4000
			},
		}
	},

	components: {
        OrderDataBlock
	},

	methods: {

		goToScan(type) {
			this.$router.push('/shipments-scanner/' + type + '/' + this.$route.params.uuid)
		},

		showSnackbar(message) {
			this.snackbar.message = message;
			this.snackbar.show = true;
		},

		getCurrentBox() {
			this.$axios.get('/boxes/' + this.$route.params.uuid)
//			this.$axios.get('/boxes/03f876bc-075e-40cb-98c3-b7cb58917738')
				.then((res) => {
					this.box = res.data;

                    for(let i = 0; i < res.data.orders.length; i++){
                        this.items.push(this.initializeOrder(res.data.orders[i]));
                    }
                })
		},

		updateBoxStatus(statusId) {

			this.box.status_id = statusId;

			this.$axios.put('/boxes/' + this.$route.params.uuid, { status_id: this.box.status_id, shipment_id: this.box.shipment_id })
				.then(() => {

					this.showSnackbar('The status was updated successfully');
				})
				.catch(function (error) {
					console.log(error);
					this.showSnackbar('Opps something went wrong, please try again!');
				});
		},
        goToOrderPage(item) {

            if(item.mediaType === 'sponsorship'){

                this.$router.push('/sponsorships/' + item.id);
            } else {

                this.$router.push('/signage-orders/' + item.id);
            }
        },
	},

	created() {
		this.getCurrentBox();
	},

	computed: {

		shipmentHeader() {
			return 'Box #' + this.box.id;
		}
	}
}
</script>

<style lang="scss" scoped>
.box {

	&__status-flow,
	&__shipment-info,
	&__content {
		margin-left: 40px;
		@include white-card-with-subheading;
	}



	&__controls {
		padding: 0;
	}

	&__status-flow {
		@include manage-status-card;

		.md-button {
			margin-left: 4rem;
			margin-right: 4rem;

			@include respond(small) {
				margin-left: 0;
				margin-right: 0;
			}

		}
	}

	&__shipment-info {
		padding-right: 15px;
	}

	.details {
		list-style: none;
		width: 100%;

		&__item {
			display: flex;
			justify-content: space-between;
			font-size: 1.6rem;
			line-height: 2.4rem;
			margin-top: 5px;
		}

		&__header {
			font-weight: 600;
		}
	}
}

.single-item {
	margin-left: 20px;
	/*width: 40%;*/
}

.no-boxes-msg {
	margin-top: 100px;
	text-align: center;
	font-size: 2.4rem;
	line-height: 1.5em;
	color: white;
}

.box-background {
	background-color: #57585B;
}
</style>