<template>
	<div class="component-wrap">

		<AppContentHeader headline="Shipments">
			<md-button class="md-primary md-raised print-shipment" @click="openPrintQrCodeModal">Print shipment QR codes</md-button>

			<md-button class="md-primary md-raised" @click="$router.push('/shipment-create')">New Shipment</md-button>
		</AppContentHeader>

		<div class="md-layout boxes">
			<ShipmentCard
					v-for="(shipment, index) in shipments"
					:shipment="shipment"
					:key="index + '_shipment'"
					class="md-layout-item shipment-card md-xlarge-size-45 md-large-size-45 md-medium-size-30 md-small-size-50 md-xsmall-size-100"/>
			<AppEmptyState v-if="!shipments.length" class="md-layout-item md-large-size-100" message="No shipments to display"/>
		</div>

		<QrCodeDialog
			:showDialog.sync="QrCodeDialog.show"
			:key="QrCodeDialog.key"
			@success="showSnackbar"
			@fail="showSnackbar"/>

		<md-snackbar
			md-position="center"
			:md-active.sync="snackbar.show"
			:md-duration="snackbar.duration"
			md-persistent>

			<span>{{snackbar.message}}</span>
			<md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

		</md-snackbar>

	</div>
</template>

<script>

	import ShipmentCard from "@/components/ShipmentDashboard/ShipmentCard"
	import QrCodeDialog from "@/components/QrCode/QrCodeDialog";

	export default {
		name: 'Shipments',
		data: function() {
			return {
				shipments: [],
				QrCodeDialog: {
					show: false,
					key: 0
				},
				snackbar: {
					show: false,
					message: '',
					duration: 4000
				},
			}
		},
		components: {
            ShipmentCard,
			QrCodeDialog
		},
		methods: {
			getAllShipments(){

				this.$axios.get('/shipments')
					.then((res) => {
						this.shipments = res.data;
					})
			},

			openPrintQrCodeModal() {

				this.QrCodeDialog.key++;
				this.QrCodeDialog.show = true;
			},

			showSnackbar(message) {

				this.snackbar.message = message;
				this.snackbar.show = true;
			},

			downloadPdf() {

			}
		},

		created(){
			this.getAllShipments();
		}
	}
</script>

<style lang="scss" scoped>
	.boxes {
		width: 100%;
	}

	.shipment-card{
		margin-left: 30px;
		margin-top: 30px;
	}

    .print-shipment {

        @include respond(small) {
            margin-bottom: 20px;
        }
     }

</style>