<template>
    <div class="component-wrap">

        <AppContentHeader headline="Ready to install">
           <AppInput
            class="filter-orders__field"
            required
            placeholder="Search by any option"
            v-model="filter"
        />
        </AppContentHeader>

        <div class="md-layout md-alignment-top-space-between boxes">
            
            <virtual-list v-if="filteredOrders.length" :size="160" :remain="8" class="scroller" style="width: 100%">

                <OrderCard 
                    v-for="order in filteredOrders"
                    :key="order.index + '_orders'"
                    :orderData="order"
                    cardMode="installer--vendor"
                    :nextStatuses="{
                        'sponsorship': statuses.sponsorship.INSTALLED,
                        'signage': statuses.signage.INSTALLED,
                        'text': 'Mark as installed'}"
                    @statusUpdated="getOrders()"
                    class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-40 md-xsmall-size-100">
                </OrderCard>

            </virtual-list>


            <AppEmptyState v-if="!filteredOrders.length" class="md-layout-item md-large-size-100" message="No orders to display"/>
        
        </div>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->


    </div>
</template>

<script>

import OrderCard from "@/components/Common/OrderCard.vue"
import {sponsorshipStatuses, signageStatuses} from "../../constants"
import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
import Cookie from 'js-cookie'


export default {
    name: 'InstallerDashboard',
    mixins: [DeepFilterMixin],
    data: function() {
        return {
            orders: [],
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loadingSpinner: false,
            itemsToShow: 9,
            filter: '',
            scrollPosition: 0,
            statuses: {
                sponsorship: sponsorshipStatuses,
                signage: signageStatuses
            }
            
        }
    },
    
    components: {
        OrderCard
    },
    
    created() {
        this.getOrders();
    },

    computed: {
        filteredOrders() {
            return this.orders.filter(x => this.filterFunction(x, this.filter));
        }
    },

    methods: {

        // onFilterInput() {
        //     const previousFilter = this.filter;

        //     setTimeout(() => {
        //         console.log('Should we start filtering?', this.filter === previousFilter);
        //         if (this.filter === previousFilter) {
        //             this.filterCards();
        //         }
        //     }, 400);
        // },

        async getOrders() {
            this.loadingSpinner = true;
            try {
                const sponsorships = (await this.$axios.get(`/sponsorships?status_id=7?season_id=${Cookie.get('season_id')}`)).data.map(x => {
                    return {
                        ...x,
                        index: x.sponsorships_id };
                });

                const signage = (await this.$axios.get(`/signage-orders?status_id=8?season_id=${Cookie.get('season_id')}`)).data.map(x => {
                    return {
                        ...x,
                        index: x.id };
                });

                this.orders = [...sponsorships, ...signage];
                this.loadingSpinner = false;
            } catch (err) {
                this.loadingSpinner = false;
                this.snackbar.message = err.message;
                this.snackbar.show = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.boxes {
    width: 100%;
}
.filter-orders__field {
    width: 100%;
    max-width: 320px;
}

.scroller {
    display: flex!important;
}
</style>