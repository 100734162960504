<template>
    <div class="shipment-box md-elevation-10" @click="$router.push('/shipments/box/' + box.id)">

        <div class="shipment-box__info">
            <h6 class="md-subheading"><strong>Box #{{box.id}}</strong></h6>
<!--            <md-button class="md-raised md-primary" @click.stop="unsealBox()">Unseal</md-button>-->
        </div>

        <div class="shipment-box__items">
            <ul class="shipment-box__list">
                <li class="shipment-box__item md-body-2" v-for="(order, index) in orders"
                    :key="index +'_order'">#{{order.id ? order.id: order.sponsorships_id}}</li>
            </ul>
        </div>

        <md-tooltip md-direction="top">Click to see more info</md-tooltip>
    </div>
</template>

<script>
export default {
    name: 'ShipmentBox',
    props: {
        box: {
            type: Object,
        },
    },
    data: function() {
        return {
            orders: []
        }
    },
    methods: {
        // unsealBox() {
        //     console.log('unsealing');
        // }
    },
    created(){

        this.orders = this.box.orders;
        // console.log(this.box)
    }
}
</script>

<style lang="scss" scoped>
.shipment-box {
    display: flex;
    padding: 1.6rem 2.4rem;
    margin: 0 0 $margin-medium;
    cursor: pointer;

    @include respond(small) {
        flex-direction: column;
        justify-content: left;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__items {
        width: 60%;
        margin-left: auto;

        @include respond(small) {
            width: 100%;
        }
    }

    &__list {
        list-style: none;
        padding: 0;

        @include respond(small) {
            margin: 16px 0;
        }
    }

    &__item {
        text-align: right;

        @include respond(small) {
            text-align: left;
        }
    }
}
</style>