export default function domain ({ next, nextMiddleware }) {

    const currentHost = window.location.hostname;

    if (currentHost === 'dev.imc.tcs.ink' || currentHost === 'test.imc.tcs.ink' || currentHost === 'imc.tcs.ink' || currentHost === 'beta.imc.tcs.ink') {
        // console.log('Trying to access old domain');
        
        return next({
            path: '/choose-campus'
        });
    }

    return nextMiddleware();


}