<template>
	<div class="component-wrap">
		<AppContentHeader :headline='shipmentHeader'>
			<md-button class="md-primary md-raised" @click="goToScan('shipment')">
				Scan Box QR code
			</md-button>
		</AppContentHeader>


		<div class="md-layout box">

			<div class="box__controls md-layout md-layout-item md-xlarge-size-30 md-large-size-30 md-small-size-100">

				<div
					class="box__status-flow md-layout-item md-large-size-100  md-small-size-50 md-xsmall-size-100 md-elevation-5">

					<h6 class="md-subheading">Manage statuses</h6>

					<md-button class="md-raised" :class="{'md-primary': (shipment.status_id == 1)}" @click="updateShipmentStatus(1)">
						Loading
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(shipment.status_id == 2)}" @click="updateShipmentStatus(2)">
						Loaded
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(shipment.status_id == 3)}" @click="updateShipmentStatus(3)">
						Shipped
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(shipment.status_id == 4)}" @click="updateShipmentStatus(4)">
						Arrived in stagging
					</md-button>
					<md-button class="md-raised" :class="{'md-primary':(shipment.status_id == 5)}" @click="updateShipmentStatus(5)">
						Problem
					</md-button>Materials dashboard

                </div>
				<!-- end of statuses card -->

				<div
					class="box__shipment-info md-layout-item md-large-size-100  md-small-size-50 md-xsmall-size-100 md-elevation-5">
					<h6 class="md-subheading">Shipment details</h6>

					<ul class="details">
						<li class="details__item">
							<span class="details__header"><strong>Licensed Plate:</strong></span>
							<span class="details__value">{{ shipment.license_plate }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Carrier:</strong></span>
							<span class="details__value">{{ shipment.carrier }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Tracking Number:</strong></span>
							<span class="details__value">{{ shipment.tracking_number }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Shipped On:</strong></span>
							<span class="details__value">{{ shipment.shipped_on }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Arrived On:</strong></span>
							<span class="details__value">{{ shipment.arrived_on }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Created At:</strong></span>
							<span class="details__value">{{ shipment.created_at }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Created By:</strong></span>
							<span class="details__value">{{ shipment.created_by }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Driver Name:</strong></span>
							<span class="details__value">{{ shipment.driver_name }}</span>
						</li>

						<li class="details__item">
							<span class="details__header"><strong>Driver Phone:</strong></span>
							<span class="details__value">{{ shipment.driver_phone }}</span>
						</li>
					</ul>
				</div>
				<!-- end of shipment details card -->
			</div>

			<div :class="{'box-background': (boxes.length <= 0)}" class="box__content md-layout-item md-xlarge-size-65 md-large-size-65 md-small-size-100 md-elevation-5">
				<div class="md-layout">
					<div class="md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100">
						<h6 class="md-subheading" v-if="boxes.length > 0">Packed items</h6>
					</div>
					<div v-if="boxes.length > 0" class="md-layout-item md-layout md-xlarge-size-100 md-large-size-100 md-small-size-100">
						<ShipmentBox
							v-for="(box, index) in boxes"
							@click.native="$router.push('boxes/' + box.uuid)"
							:key="index +'_box'"
							:box="box"
							class="order-minimized single-box md-layout-item md-xlarge-size-45 md-large-size-45 md-small-size-100"/>
					</div>


					<div class="md-accent md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100" v-if="boxes.length === 0">
						<div class="no-boxes-msg">Boxes will appear here <br>
							Click Scan Box QR code to add
						</div>
					</div>
				</div>
			</div>
		</div>

		<md-snackbar
			md-position="center"
			:md-active.sync="snackbar.show"
			:md-duration="snackbar.duration"
			md-persistent>

			<span>{{ snackbar.message }}</span>
			<md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

		</md-snackbar>
	</div>
</template>

<script>
import ShipmentBox from "@/components/ShipmentDashboard/ShipmentBox"

export default {
	name: 'SingleShipment',
	data: function () {
		return {
			boxes: [],
			shipment: {},
			snackbar: {
				show: false,
				message: '',
				duration: 4000
			},
		}
	},

	components: {
		ShipmentBox
	},

	methods: {
		goToScan(type) {
			this.$router.push('/shipments-scanner/' + type + '/' + this.$route.params.id)
		},
		showSnackbar(message) {
			this.snackbar.message = message;
			this.snackbar.show = true;
		},

		getCurrentShipment() {
			this.$axios.get('/shipments/' + this.$route.params.id)
				.then((res) => {
					this.shipment = res.data;
				})
		},

		getCurrentShipmentBoxes() {
			this.$axios.get('/shipments/' + this.$route.params.id + '/boxes')
				.then((res) => {
					this.boxes = res.data;
				})
		},

		updateShipmentStatus(statusId) {

			this.shipment.status_id = statusId;

			this.$axios.put('/shipments/' + this.$route.params.id, this.shipment)
				.then(() => {
					this.showSnackbar('The status was updated successfully');
				})
				.catch(function (error) {
					console.log(error);
					this.showSnackbar('Opps something went wrong, please try again!');
				});
		}
	},

	created() {
		this.getCurrentShipment();
		this.getCurrentShipmentBoxes();
	},

	computed: {

		shipmentHeader() {
			return 'Shipments #' + this.$route.params.id;
		}
	}
}
</script>

<style lang="scss" scoped>
.box {

	&__status-flow,
	&__shipment-info,
	&__content {
		margin-left: 40px;
		@include white-card-with-subheading;
	}

	&__controls {
		padding: 0;
	}

	&__status-flow {
		@include manage-status-card;
	}

	.details {
		list-style: none;
		width: 100%;

		&__item {
			display: flex;
			justify-content: space-between;
			font-size: 1.6rem;
			line-height: 2.4rem;
			margin-top: 5px;
		}

		&__header {
			font-weight: 600;
		}
	}
}

.single-box {
	margin-left: 20px;
	/*width: 40%;*/
}

.no-boxes-msg {
	margin-top: 100px;
	text-align: center;
	font-size: 2.4rem;
	line-height: 1.5em;
	color: white;
}

.box-background {
	background-color: #57585B;
}

</style>