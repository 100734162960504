<template>
	<div class="component-wrap">
		<form class="reset-form md-elevation-5 reset-form">
        
        <img srcset="@/assets/img/tcs_logo_1x.png 1x, @/assets/img/tcs_logo_2x.png 2x"
            alt="Logo Image"
            class="reset-form__logo">

        <h1 class="md-title reset-form__title">Enter new password</h1>

       <AppInput 
            required
            class="reset-form__field"
            type="password"
            placeholder="Password"
            v-model="$v.password.$model"
            :error="$v.password.$invalid && $v.password.$dirty"
            :errorText="chooseErrorText($v.password)"
        />

        <AppInput 
            required
            class="reset-form__field"
            type="password"
            placeholder="Confirm password"
            v-model="$v.confirmPassword.$model"
            :error="$v.confirmPassword.$invalid && $v.confirmPassword.$dirty"
            :errorText="chooseErrorText($v.password)"
        />

        <md-button class="md-primary md-raised reset-form__submit-btn"
                    :disabled="$v.$invalid"
                    @click="resetPassword()">Reset</md-button>
    </form>
	</div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'ResetPasswordPage',
    data: () => ({
        password: '',
        confirmPassword: '',
    }),
    validations: {
        password: { 
            required,
            minLength: minLength(8)
        },
        confirmPassword: {
            required,
            sameAsPassword: sameAs('password')
        }
    },
    methods: {
        resetPassword() {
            this.$router.push('/login');
        },
        chooseErrorText(field) {
            if (!field.required) {
                return 'Field is required';
            }

            if (!field.minLength) {
                return 'Password is too short';
            }

            if (!field.sameAsPassword) {
                return 'Passwords must be identical.';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.reset-form{
    @include default-auth-form;
}
</style>