<template>
    <div class="component-wrap">
        <AppContentHeader :headline="'Installation ticket #' +  ticket.id" class="hdr-page"/>
        <!--<AppContentHeader :headline="'Installation Ticket #123456'" class="hdr-page"/>-->

        <div class="order md-layout">
            <!-- end of order's graphic -->

            <div class="order__info md-layout-item md-small-size-100">

                <ul class="info-list">
                    <li class="info-list__row">
                        <span class="info-list__head remove-for-mobile">Order</span>
                        <span class="info-list__col fix-mobile">#{{orderId}}</span>
                    </li>

                    <li class="info-list__row">
                        <span class="info-list__head sections-header">Install By</span>
                        <span class="info-list__col mobile-col">{{ticketUpdated}}</span>
                        <span class="info-list__col2 remove-for-mobile"><span class="small-text">In </span>{{countDownObject.days}} days {{countDownObject.hours}}hours {{countDownObject.minutes}}mins {{countDownObject.seconds}}s</span>
                    </li>
                    <li class="info-list__row1 mobile-version mobile-col2">
                        <span class="info-list__col2"><span class="small-text">In </span>{{countDownObject.days}} days {{countDownObject.hours}}hours {{countDownObject.minutes}}mins {{countDownObject.seconds}}s</span>
                    </li>

                    <li class="info-list__row">
                        <span class="info-list__head sections-header">Created At:</span>
                        <span class="info-list__col mobile-col">{{ticketCreated}}</span>
                        <span class="info-list__col2 remove-for-mobile">{{countUpObject.days}} days {{countUpObject.hours}}hours {{countUpObject.minutes}}mins {{countUpObject.seconds}}s <span class="small-text">ago </span></span>
                    </li>
                    <li class="info-list__row1 mobile-version mobile-col2">
                        <span class="info-list__col2">{{countUpObject.days}} days {{countUpObject.hours}}hours {{countUpObject.minutes}}mins {{countUpObject.seconds}}s <span class="small-text">ago </span></span>
                    </li>
                    <li class="info-list__row add-mobile-bottom-border">
                        <span class="info-list__head sections-header">Create By:</span>
                        <span class="info-list__col mobile-col">{{ticket.created_by}}</span>
                    </li>

                    <li class="info-list__row last-row">
                        <span class="info-list__head sections-header">Ticket Status</span>
                        <span class="info-list__col mobile-col"><select
                                class="show_status__field"
                                v-model="ticketStatus">
                            <option value="1">Open</option>
                            <option value="2">Closed</option>
                            <option value="3">Resolved</option>
                            <option value="4">Cancelled</option>
                            <option value="5">Deferred</option>
                        </select></span>
                        <span class="info-list__col mobile-col">
                            <md-button class="md-primary md-raised save-status-btn" :disabled="ticket.status_id == ticketStatus" @click="changeTicketStatus()">Save Status</md-button>
                        </span>
                    </li>
                    <li class="info-list__row">
                        <span class="info-list__head">Description</span>
                    </li>
                    <li class="info-list__row last-row-for-real">
                        <span class="info-list__head">{{ticket.content}}</span>
                    </li>
                </ul>

            </div>
        </div>

        <AppContentHeader headline="Comments" class="hdr-page"/>

        <div class="order comments md-layout">
            <div class="order__info md-layout md-layout-item md-small-size-100">
                <ul class="info-list" v-for="(comment, index) in ticketComments" :key="index"
                    :class="comment.isCurrentUser ? 'current-user' : 'other-user'">
                    <li class="info-list__row user-label-row">
                        <span class="info-list__head user-label">{{comment.user_name}}</span>
                        <span class="info-list__col">{{comment.created_at}}</span>
                    </li>
                    <li class="info-list__row last-row-for-real">
                        <span class="info-list__head">{{comment.content}}</span>
                    </li>
                </ul>
                <ul class="info-list comment-field-main">
                    <li class="info-list__row">
                        <AppInput
                                controlType="textarea"
                                placeholder="Leave your comment here..."
                                class="comments-field"
                                rows="2"
                                v-model="newComment"/>
                    </li>
                    <li class="info-list__row user-label-row">
                        <md-button class="md-primary md-raised" @click="addComment()">Submit</md-button>
                    </li>
                </ul>
            </div>
        </div>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
    </div>
</template>

<script>
    export default {
        name: 'TicketBlock',
        data: function() {
            return {
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
                ticket: {},
                ticketCreated: '',
                ticketUpdated: '',
                countDownObject:{
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                },
                countUpObject: {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                },
                ticketStatus: '1',
                orderId: 0,
                ticketComments: [],
                newComment: ''
            }
        },
        computed: {
            currentUser() {
                return this.$store.getters['auth/getCurrentUser'];
            }
        },
        created() {

            this.getComments();
            this.getCurrentTicket();
        },
        methods: {
            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },
            getCurrentTicket(){
                let ticketId = this.$route.params.id;
                let type = this.$route.params.type;

                this.$axios.get(`/${type}-tickets/${ticketId}`)
                    .then((res) => {
                        this.ticket = res.data;
                        this.ticketStatus = this.ticket.status_id;
                        this.ticketUpdated = this.ticket.updated_at.split(' ')[0]
                        this.ticketCreated = this.ticket.created_at.split(' ')[0]

                        if(this.ticket.signage_order_id){
                            this.orderId = this.ticket.signage_order_id;
                        }
                        else{
                            this.orderId = this.ticket.sponsorship_id;
                        }
                        this.timer(true);
                        this.timer(false);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            makeAMPMHours(timeString){
                let hours = timeString.split(':')[0];
                let minutes = timeString.split(':')[1];
                let ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? minutes : minutes;
                let strTime = hours + ':' + minutes + ' ' + ampm;

                return strTime;
            },
            getComments(){
                let ticketId = this.$route.params.id;
                let type = this.$route.params.type;

                let currentUserId = this.currentUser.users_id;

                this.$axios.get(`/${type}-tickets/${ticketId}/comments`)
                    .then((res) => {
                        this.ticketComments = res.data;

                        for(let i = 0; i < this.ticketComments.length; i++){
                            this.ticketComments[i].created_at = this.ticketComments[i].created_at.replace(/-/g,'/');

                            let currentHours = this.ticketComments[i].created_at.split(' ')[1];
                            let currentDate = this.ticketComments[i].created_at.split(' ')[0];

                            let correctTimeFormat = this.makeAMPMHours(currentHours);
                            this.ticketComments[i].created_at = currentDate + ' ' + correctTimeFormat;

                            if(currentUserId === this.ticketComments[i].user_id) {
                                this.ticketComments[i]['isCurrentUser'] = true;
                            }
                            else {
                                this.ticketComments[i]['isCurrentUser'] = false;
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            changeTicketStatus(){
                let ticketId = this.$route.params.id;
                let type = this.$route.params.type;

                let ticketObj = this.ticket;
                ticketObj.status_id = this.ticketStatus;

                this.$axios.put(`/${type}-tickets/${ticketId}`, ticketObj)
                    .then((res) => {
                        console.log(res);
                        this.ticket.status_id = this.ticketStatus;

                        this.showSnackbar("The ticket status was successfully changed!");
                    })
                    .catch(err => {
                        this.showSnackbar(err);
                        console.log(err);
                    })

            },
            addComment(){
                let ticketId = this.$route.params.id;
                let type = this.$route.params.type;

                let commentContent = {
                    content: this.newComment
                }

                this.$axios.post(`/${type}-tickets/${ticketId}/comments`, commentContent)
                    .then((res) => {

                        this.newComment = '';

                        let newPostedComment = res.data;

                        let dateNow = new Date();
                        let timeNow = dateNow.toString().split(' ')[4];

                        let correctTimeFormat = this.makeAMPMHours(timeNow);

                        let currentYear = dateNow.getFullYear();
                        let currentMonth = dateNow.getMonth();

                        if (currentMonth.toString().length === 1) {
                            currentMonth = '0' + currentMonth;
                        }

                        let currentDate = dateNow.getDate();

                        if (currentDate.toString().length === 1) {
                            currentDate = '0' + currentDate;
                        }

                        let currentDateInCorrectFormat = currentYear + '/' + currentMonth + '/' + currentDate + ' ' + correctTimeFormat;

                        newPostedComment['user_name'] = this.currentUser.users_first + ' ' + this.currentUser.users_last;
                        newPostedComment['created_at'] = currentDateInCorrectFormat;
                        newPostedComment['isCurrentUser'] = true;

                        this.ticketComments.push(newPostedComment);

                        this.showSnackbar('You have added a new comment !');
                    })
                    .catch(err => {
                        this.showSnackbar(err);
                        console.log(err);
                    })
            },
            timer(isPlus){

              let createdAtTime = '';
              let createdAtDate = '';

              if(isPlus){
                  createdAtTime = this.ticket.updated_at.split(' ')[1];
                  createdAtDate = this.ticket.updated_at.split(' ')[0];
              }
              else{
                  createdAtTime = this.ticket.created_at.split(' ')[1];
                  createdAtDate = this.ticket.created_at.split(' ')[0];
              }

              let createdYear = createdAtDate.split('-')[0];
              let createdMonth = createdAtDate.split('-')[1];
              let createdDay = createdAtDate.split('-')[2];

             let createdHour = createdAtTime.split(':')[0];
             let createdMinute = createdAtTime.split(':')[1];
             let createdSecond = createdAtTime.split(':')[2];

              let countDownDate = new Date(createdYear, createdMonth, createdDay, createdHour, createdMinute, createdSecond, 0).getTime();

              let _this = this;

              let now = new Date().getTime();
              let distance = countDownDate - now;

                setInterval(function() {

                    if(!isPlus){
                        distance -= 1000;
                    }
                    else{
                        distance += 1000;
                    }

                    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    if(isPlus){
                        _this.countUpObject.days = days;
                        _this.countUpObject.hours = hours;
                        _this.countUpObject.minutes = minutes;
                        _this.countUpObject.seconds = seconds;
                    }
                    else{
                        _this.countDownObject.days = days;
                        _this.countDownObject.hours = hours;
                        _this.countDownObject.minutes = minutes;
                        _this.countDownObject.seconds = seconds;
                    }
                }, 1000);
            }
        },
        watch:{
            ticketStatus(){
            }
        }
    }
</script>

<style lang="scss" scoped>
    .order {
        padding: 4rem;
        background-color: $color-grey;

        @include respond(xsmall) {
            padding: 2rem;
        }

        &__image {
            background-color:$color-grey-light;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__info {
            padding-left: 3.2rem;

            .md-button {
                margin: $margin-big $margin-small 0 0;
            }
            .material-field {
                color: $color-white;
                margin: $margin-small 0 0;

                label,
                .md-select-value.md-input {
                    -webkit-text-fill-color: $color-white;
                    color: $color-white;
                }

                .md-icon-image svg{
                    fill: $color-white;
                }

                &::after {
                    background-color: $color-white;
                }
            }

            @include respond(small) {
                padding: 2rem;
                margin: $margin-medium 0;
                border: 1px solid $color-white;
            }

            @include respond(xsmall) {
                padding: 1rem;
                margin: $margin-small 0;
                border: 1px solid $color-white;
            }
        }

        .info-list {
            width: 100%;
            padding: 0;
            list-style: none;
            color: $color-white;
            font-size: 1.6rem;

            @include respond(xsmall) {
                font-size: 1.4rem;
            }

            &__row {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0 0;
            }

            &__row:first-child {
                font-size: 2.8rem;
                margin-bottom: $margin-small;

                @include respond(xsmall) {
                    font-size: 1.6rem;
                }
            }

            &__head {
                width: 30%;
            }

            &__col {
                max-width: 55%;
                word-break: normal;
                line-height: 1.4;
                font-weight: 600;
            }
        }

        &__actions {
            display: flex;
            justify-content: flex-end;

            @include respond(small) {
                justify-content: flex-start;
            }
        }

        /**  This class used on shipment dashboard to decrease components paddings
        * on large screens */
        &.order-minimized {
            padding: 2rem;
            margin-bottom: $margin-medium;
            cursor: pointer;
        }
    }

    .show_status__field {
        /*width: 100%;*/
        height: 35px;
        cursor: pointer;
        padding-left: 10px;
        border: 2px solid #CD0A2F;
    }

    .show_status__field:focus{
        border: none;
    }

    .ticket-comment{
        width: 20em;

        textarea{
            height: 8em;
        }

        @include respond(medium) {
            width: 9em;

            textarea{
                height: 5em;
            }
        }
    }

    .comments{

        .comment-field-main{
            box-shadow: none!important;
            width: 100%!important;

            .md-button {
                margin-top: 10px;
            }
        }

        .user-label{
            width: 75%;

            @include respond('small') {
                width: 55%;
            }
        }

        .user-label-row{
            .info-list__col{
                text-align: right;

                @include respond('small') {
                    width: 45%;
                }
            }
        }

        .other-user{
            background-color: white;
            color: black;
        }

        .current-user{
            margin-left: auto;
            background-color:#30C4FD;
        }

        .info-list{
            width: 70%;
            box-shadow: 8px 6px 18px 0px rgba(0,0,0,0.9);
            border-radius: 4px;
            margin-top: 30px;
            padding: 20px;

            @include respond('small') {
                width: 100%;
            }

            .info-list__row{
                font-size: 1em;
                font-weight:400;
                margin-bottom: 5px;
            }

            .user-label-row{
                padding-top: 0px;
            }
        }
    }

    .order {
        /*margin-top: 5em;*/

        .info-list__row {
            justify-content: start;
            font-weight: 200;
        }

        .info-list__col {
            width: 35%;
            font-weight: 400;
        }

        .info-list__col2 {
            font-weight: 400;
            font-size: 0.85em;
        }

        .small-text{
            color: #CD0A2F;
            font-weight: bold;
            font-size: 1.3em;
        }

        .last-row{
            border-bottom: 2px solid white;
            padding-bottom: 4em;

            @include respond('small') {
                padding-bottom: 0.5em;
            }
        }

        .last-row-for-real .info-list__head{
            width: 100%;
        }


        .remove-for-mobile {
            @include respond('small') {
                display: none;
            }
        }

        .fix-mobile{
            @include respond('small') {
                max-width: none;
                width: 100%;
                font-size: 2.3rem;
                font-weight: 200;
            }
        }

        .order__info{
            @include respond('small') {
                border: none;
                padding: 0px;
            }
        }

        .sections-header{
            @include respond('small') {
                width: 100%;
            }
        }

        .mobile-col{
            @include respond('small') {
                text-align: right;
            }
        }

        .mobile-col2{
            @include respond('small') {
                justify-content: flex-end;
                padding-top: 5px;
                border-bottom: 1px solid white;
                margin-bottom: 7px;
            }
        }
    }

    .mobile-version{
        display: none;
        @include respond('small') {
            display: flex;
        }
    }

    .page-container .app-content {

        @include respond('small') {
            padding: 0;
        }
    }

    .add-mobile-bottom-border {

        @include respond('small') {
            border-bottom: 1px solid white;
            margin-bottom: 7px;
        }
    }

    .save-status-btn{
        margin-top: 0px!important;
    }

    .comments-field{
        width: 100%;

        /deep/ textarea{
            height: 20vh;
        }
    }

    .comment-field-main{

    }

</style>