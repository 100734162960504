export default function auth ({ next, store, nextMiddleware }) {

    if (!store.getters['auth/isAuthenticated']()){
        // console.log('Auth authentification failed: redirecting to login');
        return next({
            path: '/login'
        });    
    } 
    // console.log('Auth authentification success: next middleware');
    return nextMiddleware();


}