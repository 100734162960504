import { render, staticRenderFns } from "./ShipmentItemStatusesSection.vue?vue&type=template&id=4c5b6b66&scoped=true&"
import script from "./ShipmentItemStatusesSection.vue?vue&type=script&lang=js&"
export * from "./ShipmentItemStatusesSection.vue?vue&type=script&lang=js&"
import style0 from "./ShipmentItemStatusesSection.vue?vue&type=style&index=0&id=4c5b6b66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5b6b66",
  null
  
)

export default component.exports