<template>
    <div class="component-wrap md-layout">
        <div class="md-layout-item md-layout md-large-size-100 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-100 md-alignment-top-left location-grid">
                <AppContentHeader headline="Installation Group" class="hdr-page"/>

                <div class="location-search-container">
                    <AppInput
                            class="filter-search"
                            required
                            placeholder="Search for available location"
                            v-model="searchString"
                    />
                    <md-button class="md-primary md-raised" @click="getLocations()" :disabled="searchString.length < 3">Search</md-button>
                    <p v-if="!locations.length > 0" class="group-description">To choose a locations add some criteria in the search field. At least 3 characters needed !</p>
                </div>
                <p class="locations-label"><strong>Available Locations</strong></p>
                <p class="group-description">Click to Add.</p>
                <div class="location-grid__buttons-container">
                    <md-button class="md-raised location-grid__locations-btns" v-for="(location, index) in locations" :key="index + '_location'" @click="addLocationToCurrentLocations(index)">{{location.locations_name}}</md-button>
                </div>

            </div>
            <div class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-100 md-alignment-top-left all-locations-grid">
                <p class="locations-label"><strong>Locations in this group</strong></p>
                <p class="group-description">Click to Remove.</p>
                <div class="all-locations-grid__buttons-container">
                    <md-button class="md-raised location-grid__locations-btns" v-for="(currentLocation, index) in currentLocations" :key="index + '_location'" @click="removeLocationFromCurrentLocations(index)">{{currentLocation.name}}</md-button>
                </div>
            </div>

            <md-button class="md-primary md-raised edit-group-next" @click="goToNextRound()">Next</md-button>
        </div>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

    </div>
</template>

<script>

    export default {
        name: 'InstallationGroupLocations',
        data: function() {
            return {
                searchString: '',
                currentGroupID: this.$route.params.id,
                currentLocations: [],
                locations: [],
                currentGroupObject: {},
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
            }
        },
        methods: {

            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },

            getLocations() {

                let _this = this;

                let searchStringForRequest = this.searchString;

                _this.loadingSpinner = true;

                this.$axios.get('/installation-groups-available-locations',{
                    params: {name: searchStringForRequest},
                }).then((res) => {

                    _this.locations = res.data;

                    if(_this.currentLocations.length > 0) {

                            for(let j = 0; j < _this.locations.length; j++){
                                for(let i = 0; i < _this.currentLocations.length; i++){
                                    if(_this.locations[j].locations_id === _this.currentLocations[i].id){
                                        _this.locations.splice(j,1);
                                    }
                                }
                            }

                        this.$forceUpdate();
                    }

                    _this.loadingSpinner = false;

                }).catch((res) => {

                    console.log(res);
                });
            },

            getCurrentLocations(){

                let _this = this;
                let groupId = this.currentGroupID;

                _this.loadingSpinner = true;

                this.$axios.get(`/installation-groups/${groupId}`)
                    .then((res) => {

                    _this.currentLocations = res.data.locations;

                    _this.currentGroupObject = res.data;

                    if(_this.locations.length > 0){

                        for(let j = 0; j < _this.locations.length; j++){
                            for(let i = 0; i < _this.currentLocations.length; i++){
                                if(_this.locations[j].locations_id === _this.currentLocations[i].id){
                                    _this.locations.splice(j,1);
                                }
                            }
                        }

                        this.$forceUpdate();
                    }

                    _this.loadingSpinner = false;

                }).catch((res) => {
                    console.log(res);
                });
            },

            addLocationToCurrentLocations(index){
                let location = this.locations[index];
                let currentLocation = {
                    id: location.locations_id,
                    name: location.locations_name,
                    order: this.currentLocations.length + 1
                };

                this.locations.splice(index, 1);
                this.currentLocations.push(currentLocation);
            },

            removeLocationFromCurrentLocations(index){
                let currentLocation = this.currentLocations[index];

                let location = {
                    locations_id: currentLocation.id,
                    locations_name: currentLocation.name,
                    order: currentLocation.order
                };
                this.currentLocations.splice(index, 1);
                this.locations.push(location);
            },

            goToNextRound() {

                let locationsRemake = [];

                for(let i = 0; i < this.currentLocations.length; i++){
                    let locationToPush = {};
                    locationToPush['id'] = this.currentLocations[i].id;
                    locationToPush['name'] = this.currentLocations[i].name;
                    locationToPush['order'] = i + 1;

                    locationsRemake.push(locationToPush);
                }

                this.currentGroupObject.locations = locationsRemake;

                this.$store.dispatch('installations/setSelectedLocations', this.currentGroupObject);

                this.$router.push('/installer-group-edit/'  + this.currentGroupID);
            }
        },

        created() {
            this.getCurrentLocations();
        }
    }
</script>

<style lang="scss" scoped>

    .hdr-page{

        @include respond(small) {
            margin: 0px;
        }
    }

    .all-locations-grid{
        margin-top: 88px;
        margin-left: 10%;

        @include respond(medium) {
            margin-top: 40px;
        }
    }

    .location-grid, .all-locations-grid{

        &__locations-btns{
            width: 100%;
            margin-right: 4%;
            margin-top: 6%;

            @include respond(small) {
                width: 100%;
            }
        }

        &__buttons-container{
            padding: 15px;
            border: 2px solid #e3e3e3;
            margin-top: 10px;
            border-radius: 4px;
        }

    }


    .location-search-container{
        display: grid;
        margin-bottom: 30px;

        .filter-search{

            /deep/ input {
                /*padding-left: 10px;*/
                margin-bottom: 10px;
            }
        }

        /deep/ .control-field__input {
            background-color: white;
            width: 100%;
            margin-bottom: 20px;
            padding: 0px;
            height: 35px;
            font-size: 1.5rem;
            border: none;
            border-bottom: 4px solid #CD0A2F;
        }

        /deep/ .control-field__input:focus {
            border: none;
            border-bottom: 4px solid #CD0A2F;
        }

        /deep/ .control-field__error {
            bottom: 3px;
        }

        /deep/ .control-field__input:valid {
            border: none;
            color: black;
            border-bottom: 4px solid #CD0A2F;
        }

    }

    .pagination {

        display: flex;
        font-weight: 500;
        justify-content: space-around;
        list-style: none;
        margin: 2rem auto;
        width: 200px;

        .page-item {

            a {
                color: #CD0A2F;
            }
        }
    }


    .group-description{
        color: #7B7C7E;
        margin-top: 10px;

        @include respond(small) {
            text-align:center;
            margin-bottom: 10px;
        }
    }

    .locations-label{
        @include respond(medium) {
            text-align:center;
        }
    }

    .edit-group-next{
        position: absolute;
        right: 55px;
        top: 160px;

        @include respond(small) {
            position:relative;
            right: 0px;
            top: 0px;
            margin: 0 auto;
            width: 100%;
            margin-top: 65px;
        }
    }
</style>