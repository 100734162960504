<template>
    <p class="status-ribbon" :class="statusColor">{{order.statusName}}</p>    
</template> 

<script>
export default {
    name: 'OrderCardStatusRibbon',
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            statusColor: {}
        }
    },
    created() {
        this.statusColor = {
                'status-ribbon__green': 
                    (this.order.mediaType === 'signage' && this.order.statusStage < 400) ||
                    (this.order.mediaType === 'sponsorship' && this.order.statusStage < 45),
                'status-ribbon__yellow':
                    (this.order.mediaType === 'signage' && this.order.statusStage > 300 && this.order.statusStage < 1000) ||
                    (this.order.mediaType === 'sponsorship' && this.order.statusStage < 80 && this.order.statusStage > 44),
                'status-ribbon__red':
                    (this.order.mediaType === 'signage' && this.order.statusStage > 1000) ||
                    (this.order.mediaType === 'sponsorship' && this.order.statusStage > 79)
            };
    }
}
</script>

<style lang="scss" scoped>
.status-ribbon {
    text-align: center;
    width: calc(100% + 32px);
    background: #222;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: #fff;
    line-height: 1;
    padding: 5px 10px 3px;
    margin: -18px 0 10px;

    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;

    transform: translateX(-16px);

    &__green {
        background-color: #3F826D;
    }

    &__yellow {
        background-color: #FFBA08;
    }

    &__red {
        background-color: $color-primary;
    }
}
</style>