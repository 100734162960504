<template>
    <div class="componet-wrap">
        
        <AppContentHeader headline="Packing App">
            
            <md-button
                v-if="boxScanner.destroyed && !boxUUID"
                class="md-primary md-raised"
                @click="turnBoxScannerOn()">Scan box</md-button>

        </AppContentHeader>


        <AppEmptyState v-if="boxScanner.destroyed && !boxUUID" message="Scan some box's QR code to start packing."/>

        <div v-if="!boxScanner.destroyed || boxUUID" class="content-wrap md-elevation-5">

            <div v-if="boxUUID" class="box-details">
                <p class="box-details__uuid">Box UUID: <span>{{ boxUUID }}</span></p>
                
                <ul class="box-details__items">
                    <li class="box-details__item">Item 1</li>
                    <li class="box-details__item">Item 2</li>
                    <li class="box-details__item">Item 3</li>
                    <li class="box-details__item">Item 4</li>
                    <li class="box-details__item">Item 5</li>
                </ul>

                <md-button class="md-primary md-raised box-details__action" @click="turnItemsScannerOn()">Pack items</md-button>
                <md-button class="md-primary md-raised box-details__action" @click="turnBoxScannerOn()">Finish / Choose other box</md-button>
            </div>
            <!-- end of scanned box block -->


            <div class="scanner" v-if="!boxScanner.destroyed">
                
                <p class="md-body-1">Place box's QR code inside this square:</p>
                
                <qrcode-stream  v-if="!boxScanner.destroyed"
                                @init="initScanner(boxScanner)"
                                @decode="onDecode">

                    <div class="loading-indicator" v-if="boxScanner.loading">
                        Loading...
                    </div>

                </qrcode-stream>

            </div>
            <!-- QR code scanner for boxes -->


            <div class="scanner" v-if="!itemScanner.destroyed || itemUUID">
                
                <p class="md-body-1">Place item's QR code inside this square:</p>
                
                <AppSpinner v-if="itemUUID != ''" :loading="itemUUID != ''"/>
                <p v-if="itemUUID != ''" class="md-body-1">
                    Adding your item to the box.
                    You will be able to continue in a sec.</p>

                <qrcode-stream  v-if="!itemScanner.destroyed"
                                @init="initScanner(itemScanner)"
                                @decode="onDecodeItem">

                    <div class="loading-indicator" v-if="itemScanner.loading">
                        Loading...
                    </div>

                </qrcode-stream>

            </div>
            <!-- QR code scanner for items -->


        </div>


    </div>
</template>

<script>

/** TODO: 
 * 1. Finish setting up workflow for scanning items and putting them in box.
 * 2. Split into components.
 */

export default {
    name: 'ShipmentDashboardBoxPacking',

    data () {
        return {
            boxScanner: {
                loading: false,
                destroyed: true,
            },

            itemScanner: {
                loading: false,
                destroyed: true,
            },
            boxUUID: '',
            itemUUID: ''
        }
    },

    methods: {
        async initScanner (scanner, promise) {
            scanner.loading = true;

            try {
                await promise;
            } catch (error) {
                console.error(error);
            } finally {
                scanner.loading = false;
            }
        },

        async reloadScanner (scanner) {
            scanner.destroyed = true;
            await this.$nextTick();
            scanner.destroyed = false;
        },

        onDecode(result) {
            this.boxUUID = result;
            this.boxScanner.destroyed = true;
        },

        onDecodeItem(result) {
            this.itemUUID = result;
            this.itemScanner.destroyed = true;
            
            setTimeout(()=> {
                this.itemUUID = '';
                this.itemScanner.destroyed = false;
            }, 1600);

        },

        turnBoxScannerOn() {
            this.itemScanner.destroyed = true;
            this.boxScanner.destroyed = false;
            this.boxUUID = '';
        },

        turnItemsScannerOn() {
            this.itemScanner.destroyed = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.content-wrap {
    width: calc(100% + 4.8rem);
    transform: translateX(-2.4rem);
    padding: 1.6rem;
}

.scanner {
    width: 100%;
    min-height: 320px;
    margin: 0 auto;
    background-color: rgba($color-grey, .2);
    border: 1px solid $color-primary;

    .md-body-1 {
        margin: 8px auto;
        padding: 0 1.6rem;
        text-align: center;
    }
    
    .loading-indicator {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.box-details {
    display: flex;
    flex-direction: column;

    &__uuid {
        font-size: 1.8rem;
        margin-bottom: $margin-small;

        span {
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &__items {
        list-style: none;
        padding: 0 0 0 1.6rem;
        margin-bottom: $margin-small;
        padding: 1rem;
        border: 1px solid $color-primary;
    }

    &__item {

    }

    &__action {
        margin-bottom: $margin-small;
    }
}
</style>