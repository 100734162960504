<template>
    <div class="component-wrap">
        
        <h6 class="md-subheading">Comments</h6>

        <div v-for="(com, index) in comments"
            :key="index + '_comment'"
            class="comment"
            :class="com.author === 'Current User' ? 'my-comment' : ''">

            <div class="comment__header">
                <span class="comment__author">{{ com.author }}</span>
                <span class="comment__date">{{ new Date(com.date) | dateFormat('YYYY.MM.DD hh:mm:a') }}</span>
            </div>

            <p class="comment__text">{{ com.text }}</p>
        
        </div>
        <!-- end of comments -->

        <AppInput
            class="comment__input"
            controlType="textarea"
            rows="5"
            required
            placeholder="Leave your comment here…"
            v-model="newComment"
        />

        <md-button class="md-primary md-raised"
                :disable="!newComment"
                @click.native="addComment()">Submit</md-button>

    </div>    
</template>

<script>
export default {
    name: 'ShipmentItemCommentsSection',
    data: () => ({
        comments: [
            {
                author: 'Lorem User 1',
                text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est, cumque perferendis. Ut modi sint consequuntur praesentium, nihil iste laborum.",
                date: new Date() - 400000
            },
            {
                author: 'Lorem User 2',
                text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est, cumque perferendis. Ut modi sint consequuntur praesentium, nihil iste laborum.",
                date: new Date() - 300000
            },
            {
                author: 'Current User',
                text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est, cumque perferendis. Ut modi sint consequuntur praesentium, nihil iste laborum.",
                date: new Date() - 20000
            },
            {
                author: 'Lorem User 2',
                text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est, cumque perferendis. Ut modi sint consequuntur praesentium, nihil iste laborum.",
                date: new Date() - 10000
            }
        ],
        newComment: ''
    }),

    methods: {
        addComment() {
            this.comments.push({
                author: 'Current User',
                text: this.newComment,
                date: new Date()
            });
            this.newComment = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.component-wrap {
    padding: 2.4rem;
    background-color: $color-grey;

    .md-subheading {
        color: $color-white;
        margin-bottom: $margin-small;
    }

    .comment {
        background-color: $color-white;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.6rem;
        margin-bottom: $margin-small;
        padding: 1.6rem 2.4rem;
        width: 85%;

        @include respond(xsmall) {
            width: 100%;
        }

        &.my-comment {
            margin-left: 15%;
            background-color: $color-blue;
            text-align: right;

            @include respond(xsmall) {
                margin-left: 0;
            }
        }

        &.my-comment .comment__text {
            color: $color-white;
            margin-left: auto;
        }

        &.my-comment .comment__date {
            color: rgba($color-white, .8);
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__author {
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 3.2rem;
        }

        &__date {
            color: rgba($color-black, .6);
        }

        &_text {
            min-width: 100%;
        }

        &__input {
            border-radius: 5px !important;
            margin-bottom: $margin-small;

            & ~ .md-button {
                display: flex;
                margin-left: auto;
            }
        }
    }
}
</style>