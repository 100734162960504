<template>
		<select
			v-bind="$attrs"
			:value="value"
			@input="$emit('input', $event.target.value)"
			@change="$emit('change', $event.target.value)"
			class="control-field__select">

			<option value="" disabled selected>{{placeholder}}</option>
			<slot/>
		</select>
</template>

<script>
export default {
	name: "AppInputSelectDropdown",

	props: {
		value: {
			type: [String, Number],
			default: null
		},
		placeholder: {
			type: [String, Number],
			default: null
		}
	}
}
</script>

<style lang="scss" scoped>

	select {
		width: 100%;
		background: #FFFFFF;
        font-size: 16px;
        text-align-last: center;
        -webkit-appearance: none;

		@include respond(small){
			border: 0px;
			margin-top: 25px;
		}
	}

</style>