<template>
    <md-dialog :md-active.sync="showDialog"
                class="upload-dialog"
                :md-click-outside-to-close="false">
        
        <md-dialog-title class="upload-dialog__header">
            <span>Upload original</span>
            <input type="file" @change="showPreview($event)" ref="hiddenInput">
            <md-button class="md-raised md-primary"
                @click.native="$refs.hiddenInput.click()">Choose file</md-button>
        </md-dialog-title>
        <!-- end of dialog header -->

        <div class="upload-dialog__preview">
            <img v-if="previewUrl"
                :src="previewUrl"
                class="preview-image"
                alt="Preview Uploaded Image">

            <p class="md-body-1" v-if="!previewUrl">Preview will appear here</p>
        </div>
        <!-- end of dialog preview part -->

        <div class="upload-dialog__note" v-if="previewUrl">
            <AppInput
                controlType="textarea"
                placeholder="Note"
                rows="2"
                v-model="fileNote"/> 
        </div>
        <!-- end of dialog note -->

        <AppSpinner v-if="loading" :loading="loading" class="floating-spinner"/>

        <md-dialog-actions>
            <md-button class="md-primary" @click="closeDialog()">Cancel</md-button>
            <md-button  :disabled="!previewUrl || !fileNote"
                        class="md-primary upload-file-btn"
                        @click="uploadFile()">Finish</md-button>
        </md-dialog-actions>
        <!-- end of dialog actions -->

    </md-dialog>
</template>

<script>
import OrderModel from '@/models/OrderModel';

  export default {
    name: 'DesignDashboardUploadDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        order: {
            type: OrderModel,
            default: null
        }
    },
    data: () => ({
        previewUrl: '',
        fileNote: '',
        file: null,
        loading: false
    }),
    methods: {

        closeDialog() {
            this.previewUrl = '';
            this.fileNote = '';
            this.$emit('update:showDialog', false);
        },

        showPreview(event) {
            const input = event.target;

            if (input.files && input.files[0]) {
                this.file = input.files[0];
                const reader = new FileReader();
                
                reader.onload = e => {
                    this.previewUrl = e.target.result;
                    input.value = '';
                };

                reader.readAsDataURL(input.files[0]);
            }
        },

        uploadFile() {
            this.loading = true;

            this.order.uploadOriginalFile(this.file, this.fileNote)
                .then(() => {
                    this.$emit('fileUploaded');
                    setTimeout(this.finishUpload, 1000);
                })
                .catch(() => {
                    this.$emit('fileUploadingFailed');
                    setTimeout(this.finishUpload, 2500);
                });
        },

        finishUpload() {
            this.loading = false;
            this.closeDialog();
        }
    }
  }
</script>

<style lang="scss" scoped>
    .upload-dialog {
        max-width: 768px;
        min-width: 500px;
    
        &__header {
            display: flex;
            justify-content: space-between;

            input[type="file"] {
                display: none;
            }
        }
        &__preview {
            width: 100%;
            min-height: 200px;
            background-color: $color-grey;

            .preview-image {
                display: flex;
                max-width: 300px;
                margin: 1rem auto;
                object-fit: contain;
            }
            .md-body-1 {
                color: $color-white;
                text-align: center;
                line-height: 200px;
                font-size: 1.6rem;
            }
        }
        &__note {
            padding: 1.6rem 2.4rem .8rem;
        }
    }
</style>