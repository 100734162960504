<template>
    <div class="component-wrap">
        <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
    </div>
</template>

<script>
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'

export default {
    name: 'AppSpinner',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#CD0A2F'
        },
        size: {
            type: String,
            default: '10px'
        }
    },
    components: {
        PulseLoader
    }
}
</script>
<style lang="scss" scoped>
.component-wrap {
    width: 100%;
    padding: 6rem 0;
    text-align: center;
    background-color: rgba($color-white, .6);
}
.floating-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    z-index: 1000;
}
</style>