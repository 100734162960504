<template>
    <md-dialog :md-active.sync="showDialog"
                class="material-dialog"
                :md-click-outside-to-close="false">
        
        <md-dialog-title class="material-dialog__header">
            <span>{{material ? 'Update material #' + material.id : 'Create new material'}}</span>
        </md-dialog-title>
        <!-- end of dialog header -->

        <div class="material-dialog__content">
            <AppInput
                class="material-dialog__name"
                required
                placeholder="Material name"
                v-model="$v.materialName.$model"
                :error="$v.materialName.$invalid && $v.materialName.$dirty"
                errorText="Material name is required"
            />
        </div>        

        <md-dialog-actions>
            <md-button class="md-primary" @click="closeDialog()">Cancel</md-button>
            <md-button  :disabled="!materialName"
                        class="md-primary"
                        @click="submitMaterial()">Submit</md-button>
        </md-dialog-actions>
        <!-- end of dialog actions -->

    </md-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'MaterialDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        material: {
            type: Object,
            default: null
        }
    },
    
    data: () => ({
       materialName: ''
    }),

    validations: {
        materialName: { required },
    },
    created() {
        this.material ? this.materialName = this.material.name : this.materialName = '';
    },
    
    methods: {
        closeDialog() {
            this.materialName = '';
            this.$emit('update:showDialog', false);
        },
        submitMaterial() {
            const payload = {
                'name': this.materialName
            };
            this.material ? this.updateMaterial(this.material.id, payload) : this.createMaterial(payload);
        },
        updateMaterial(id, data) {
            this.$axios.put(`/materials/${id}`, data)
                .then(() => {
                    this.$emit('success', 'Material successfully updated!');
                    this.closeDialog();
                })
                .catch(err => {
                    this.$emit('fail', `${err.message}. Please, try again.`);
                    this.closeDialog();
                })
        },
        createMaterial(data) {
            this.$axios.post(`/materials`, data)
                .then(() => {
                    this.$emit('success', 'Material successfully created!');
                    this.closeDialog();
                })
                .catch(err => {
                    this.$emit('fail', `${err.message}. Please, try again.`);
                    this.closeDialog();
                })
        }
    }

}
</script>

<style lang="scss" scoped>
    .material-dialog {
        max-width: 768px;
        width: 500px;

        &__content {
            padding: 0 2.4rem;
        }
    }
</style>