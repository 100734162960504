const state = () => ({
	shipments: []
});

const getters = {
	getShipments(state) {
		return state.shipments;
	}
};

const mutations = {
	setShipments(state, shipments) {
		state.shipments = shipments;
	}
}

const actions = { }

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
