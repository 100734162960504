import {roles} from '../../constants'

export default function rolesCheck ({ to, next, store, nextMiddleware }) {

    const user = store.getters['auth/getCurrentUser'];
    const allowedRoles = to.meta.roles;
    const userRole = user ? user.users_role : '0';

    // console.log('Roles authentification', user);
       
    if (!allowedRoles.includes(userRole)) {
        let path = '';

        switch (userRole) {
            case roles.ADMIN:
            case roles.MANAGER:
            case roles.ACC_EXECUTIVE:
            case roles.PRODUCT_MANAGER:    
                path = '/design';
                break;
            case roles.ADVERTISER:
                path = '/404';
                break;
            case roles.WF_PRINTER:
                path = '/design';
                break;
            case roles.PUB_PRINTER:
                path = '/404';
                break;
            case roles.FACILITATOR:
                path = '/material';
                break;
            case roles.DESIGNER:
                path = '/design';
                break;
            case roles.INSTALLER:
                path = '/installation-dashboard';
                break;
        }

        if (path) {
            return next({
                path: path
            });    
        }

     }
    return nextMiddleware();
}