<template>
    <md-card v-bind="$attrs" class="md-elevation-15 order-card">

        <md-card-header class="card-header__horizontal">

            <div v-if="cardMode === 'installationTickets'" class="md-subhead subhead-orderID subhead-third-number">Ticket ID: {{orderData.id}}</div>

            <!--<div class="md-subhead" v-if="cardMode === 'installationWalkthrough'">{{orderData.adspaces_description}}</div>-->

            <div :class="{
                'md-title':cardMode === 'installationWalkthrough' || cardMode === 'installationTickets',
                'md-subhead':cardMode !== 'installationWalkthrough' && cardMode !== 'installationTickets'}">
                {{orderData.adspaces_description}}</div>


            <div class="md-subhead subhead-orderID" v-if="cardMode !== 'installationTickets'">{{orderData.order_id}}</div>

            <div class="md-subhead subhead-orderID" v-if="cardMode === 'installationTickets' && orderData.signage_order_id">Order ID: {{orderData.signage_order_id}}</div>


            <div class="md-subhead subhead-orderID" v-if="cardMode === 'installationTickets' && orderData.sponsorships_id">Order ID: {{orderData.sponsorships_id}}</div>


            <!--<div v-if="cardMode === 'installationTickets'" class="md-subhead">{{order.spaceDescription}}</div>-->
        </md-card-header>
        <!-- order info -->

        <router-link :to="`${orderLink}${orderData.id}`" v-if="cardMode !== 'installationWalkthrough'">

        <md-card-media>

            <img v-if="orderData.artwork_url_thumbnail" v-lazy="orderData.artwork_url_thumbnail"
                 alt="Proof thumbnail"
                 class="card-image">

            <div class="report-description" v-if="cardMode === 'installationTickets'">{{orderData.content.substring(0,50)+"..."}}</div>
            <md-tooltip md-direction="bottom" v-if="cardMode === 'installationTickets'">Click on text to see more info</md-tooltip>

            <img v-if="orderData.upload_thumbnail_url" v-lazy="orderData.upload_thumbnail_url"
                 alt="Proof thumbnail"
                 class="card-image">

        </md-card-media>
        </router-link>

        <!-- proof thumbnail -->

        <md-card-media v-if="cardMode === 'installationWalkthrough'">

            <img v-if="orderData.artwork_url_thumbnail" v-lazy="orderData.artwork_url_thumbnail"
                 alt="Proof thumbnail"
                 class="card-image">

            <img v-if="orderData.upload_thumbnail_url" v-lazy="orderData.upload_thumbnail_url"
                 alt="Proof thumbnail"
                 class="card-image">

        </md-card-media>

        <md-card-header>

            <div class="md-subhead artWorkName_sub">{{orderData.name}}</div>

        </md-card-header>
        <!-- card content -->

        <div v-if="cardMode === 'installationTickets'" class="status-desc">Status: {{orderData.status_name}}</div>

        <!--installationWalkthrough report problem card expand-->

        <md-card-expand v-if="cardMode === 'installationWalkthrough'">

            <md-card-actions md-alignment="space-between" class="md-layout">

                <div class="md-layout-item md-large-size-50 md-large-size-50 md-small-size-100 md-xsmall-size-100">
                    <md-card-expand-trigger>
                        <md-button class="md-raised report-btn action-walkthrough-btns">
                            Report Problem
                        </md-button>
                    </md-card-expand-trigger>
                </div>

                <div class="md-layout-item md-large-size-50 md-large-size-50 md-small-size-100 md-xsmall-size-100">
                    <md-button @click="installedCorrect()" class="md-primary md-raised correctly-btn action-walkthrough-btns">
                        Installed Correctly
                    </md-button>
                </div>

            </md-card-actions>

            <md-card-expand-content>

                <md-card-content class="order-card__notes">
                    <AppInput
                            controlType="textarea"
                            placeholder="Please describe the problem"
                            rows="2"
                            v-model="walkThroughReportMsg"/>
                    <AppInput
                            controlType="select"
                            class="severity__field"
                            v-model="severityValue">

                        <option value="" selected>Choose Severity</option>
                        <option value="1">Green</option>
                        <option value="2">Yellow</option>
                        <option value="3">Red</option>
                        <option value="4">DANGER</option>

                    </AppInput>

                    <md-button
                            @click="ticketReport(orderData.media_id)"
                            :disabled="walkThroughReportMsg.length === 0 || severityValue.length === 0"
                            class="md-primary md-raised create-report-btn">

                        Create problem ticket
                    </md-button>
                </md-card-content>

            </md-card-expand-content>

        </md-card-expand>

    </md-card>
</template>

<script>

    export default {
        name: 'TicketCard',

        props: {
            orderData: {
                type: Object,
                default: null
            },
            cardMode: {
                type: String,
                default: 'installationWalkthrough'
            },
        },

        data: function() {
            return {
                walkThroughReportMsg: '',
                severityValue: '',
                orderLink: '',
            }
        },

        components: {},

        computed: {

        },

        created(){

            let ticketOrderType = '';

            if(this.orderData.signage_order_id){
                ticketOrderType = 'signage-order';
            }
            else{
                ticketOrderType = 'sponsorship'
            }

            this.orderLink = `/installer-ticket/${ticketOrderType}/`;
        },

        methods: {
            installedCorrect(){
                this.$emit('installedCorrectly');
            },

            ticketReport(ticketId) {

                let requestString = '';

                let ticketType = 'signage';

                switch(ticketId){
                    case 9:
                        ticketType = 'free-standing';
                        break;
                    case 6:
                        ticketType = 'sponsorship';
                        break;
                }

                if (ticketType === 'signage'){
                    requestString = 'signage-orders';
                } else if (ticketType === 'sponsorship'){
                    requestString = 'sponsorships';
                }

                let ticketObject = {
                    content: this.walkThroughReportMsg,
                    severity: this.severityValue
                };

                let current_id = this.orderData.order_id;

                this.$axios.post(`/${requestString}/${current_id}/tickets`, ticketObject)
                    .then(() => {
                        this.walkThroughReportMsg = '';
                        this.severityValue = '';
                        this.$emit('reportSnackBar', 'The report to was send !');
                    })
                    .catch(err => {
                        this.$emit('reportSnackBar', err.message);
                    })
            }
        }
    }

</script>

<style lang="scss" scoped>

    .order-card {
        margin-bottom: $margin-big;

        .card-header__horizontal {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 5px;
        }
        .card-image {
            cursor: pointer;
        }
        .material-field {
            margin: 0;
        }
        &:last-child {
            margin-right: auto;
        }
        .md-card-media {
            height: 370px;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            display: flex;
            background: $color-grey-light;
        }
        &__notes {
            .md-body-1 {
                margin-bottom: 1.6rem;
            }
        }

        .md-card-actions.md-alignment-space-between {
            @include respond(small) {
                align-items: flex-start;
            }
        }

        .installation-walkthrough-btns{
            margin-left: 20px;
            margin-right: 20px;

            .md-button {
                margin-bottom: 10px;
                border: 1px solid #D3D3D3;
            }
        }

        .severity__field, .create-report-btn{
            margin-top: 20px;
            width: 100%;
        }

        .correctly-btn{
            padding: 0px;
        }

        .action-walkthrough-btns{
            @include respond(small) {
                width:100%;
                margin-bottom: 10px;
            }
        }

        .artWorkName_sub {
            text-align: center;
        }


        .subhead-orderID{
            margin-top: auto;
            margin-bottom: auto;

            @include respond(small) {
                text-align:center;
                width:100%;
            }
        }

        .subhead-third-number{
            color: #CD0A2F;
            font-weight: bold;
            margin-right: 4px;
        }

        .report-description{
            color: red;
            cursor: pointer;
        }

        .status-desc{
            font-size: 1.7em;
            text-align: center;
            margin-bottom: 20px;
        }

        /deep/ .severity__field select{
            cursor: pointer;
        }
    }


    /deep/ .md-card-header:first-child>.md-card-header-text>.md-title:first-child, .md-card-header:first-child>.md-title:first-child {
        margin-top: 0px;
    }
</style>
