<template>
    <div class="component-wrap md-elevation-1">
        <p class="md-body-1">{{message}}</p>
        <!-- Slot to pass an action btn or Empty state image -->
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AppEmptyState',
    props: {
        message: {
            type: String,
            default: 'There is no data to display'
        }
    }
}
</script>

<style lang="scss" scoped>
    .component-wrap {
        padding: 4rem;
        border: 1px solid rgba($color-black, .2);
        text-align: center;
    }
    .md-body-1 {
        font-size: 1.6rem;
    }
</style>