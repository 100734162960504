import {$axios} from '../../plugins/axios'

const state = () => ({
   materials: []
});

const getters = {
    getMaterials(state) {
        return state.materials;
    }
};

const mutations = {
    setMaterials(state, materials) {
        state.materials = materials;
    }
}

const actions = {
    getMaterials(vuexContext) {
        return $axios.get('/materials')
            .then(res => {
                vuexContext.commit('setMaterials', res.data);
                return res.data;
            });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
  