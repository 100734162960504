<template>
    <div class="order md-layout">

        <div class="order__image md-layout-item md-large-size-40 md-small-size-100">
            <img :src="order.artworkThumbnail || order.fallbackImage" alt="Order Image">
        </div>
        <!-- end of order's graphic -->

        <div class="order__info md-layout-item md-size-60 md-small-size-100">

            <ul class="info-list">
                <li class="info-list__row">
                    <span class="info-list__head">Order</span>
                    <span class="info-list__col"> #{{ order.id }}</span>
                </li>

                <li class="info-list__row">
                    <span class="info-list__head">Advertiser</span>
                    <span class="info-list__col">{{ order.companyName }}</span>
                </li>


                <li class="info-list__row">
                    <span class="info-list__head">Adspace ID</span>
                    <span class="info-list__col">#{{ order.spaceId }}</span>
                </li>

                <li class="info-list__row" >
                    <span class="info-list__head">Artwork ID</span>
                    <span class="info-list__col">#{{ order.artworkId }}</span>
                </li>

                <li class="info-list__row" >
                    <span class="info-list__head">Requirements ID</span>
                    <span class="info-list__col">#{{ order.artworkId }}</span>
                </li>

                <li class="info-list__row">
                    <span class="info-list__head">Location ID</span>
                    <span class="info-list__col">#{{ order.adspaceLocation }}</span>
                </li>

                <li class="info-list__row">
                    <span class="info-list__head">Location Code</span>
                    <span class="info-list__col">{{ locationCode }}</span>
                </li>

                <li class="info-list__row" v-if="order.accountExecutiveName">
                    <span class="info-list__head">Account Executive</span>
                    <span
                        class="info-list__col">{{ order.accountExecutiveName + ' (' + order.accountExecutiveEmail + ')' }}</span>
                </li>

                <li class="info-list__row notes-head" >
                    <span
                        class="info-list__head">{{ order.mediaType === 'sponsorship' ? 'Notes' : 'Proof notes' }} </span>
                </li>

                <li class="info-list__row notes" >
                    <span class="info-list__head">{{ order.artworkNotes ? order.artworkNotes : 'Not specified' }}</span>
                </li>

            </ul>

            <template v-if="user.role === 'design'">
                <md-button class="md-raised md-primary" v-if="order.artworkDownloadLink"
                           :href="order.artworkDownloadLink"
                           :download="order.artworkName"
                >
                    Download
                </md-button>

                <md-button class="md-primary md-raised"
                           @click="order.downloadPrintTicket()"
                >
                    Download Print Ticket
                </md-button>

                <md-button class="md-primary md-raised"
                           v-if="order.upload_original_url"
                           :href="order.upload_original_url"
                           :download="order.artworkName"
                >
                    Download original
                </md-button>

                <md-button class="md-primary md-raised"
                           v-if="order.qr_code_image_url"
                           :href="order.qr_code_image_url"
                           :download="order.artworkName"
                >
                    Download QR Code
                </md-button>
            </template>

            <template v-if="user.role === 'facilitator'">

                <AppInputSelectDropdown
                    class="create-form__field"
                    v-model="order.materialId">

                    <option v-for="(material, index) in materials" :key="'material_'+ index" :value="material.id">
                        {{ material.name }}
                    </option>

                </AppInputSelectDropdown>

                <md-button class="md-primary md-raised" @click="sendToQueue()">Send to queue</md-button>

            </template>

            <div v-if="user.role === 'facilitator' || user.role === 'design'">

                <md-button v-if="user.role === 'design'"
                           class="md-primary md-raised"
                           @click="uploadFile()">
                    {{ order.artworkThumbnail ? 'Replace design file' : 'Upload design file' }}
                </md-button>

                <md-button v-if="user.role === 'facilitator'"
                           class="md-primary md-raised"
                           @click="order.downloadPrintTicket()">
                    Download print ticket
                </md-button>
            </div>

        </div>
        <!-- end of order info block -->

        <!-- end of ordr actions -->

<!--        <div class="md-layout md-size-10 md-small-size-100 md-alignment-bottom-right">-->
<!--            <div class="md-layout-item qr-code-image"-->
<!--                 v-if="order.qr_code_image_url"-->
<!--            >-->
<!--                <a :href="order.qr_code_image_url" :download="order.artworkName">-->
<!--                    <img :src=order.qr_code_image_url alt="qr-code">-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
import AppInputSelectDropdown from '@/components/Base/AppInputSelectDropdown';

export default {
    name: 'UIOrderDataBlock',
    props: {
        order: {
            type: Object,
            default: null
        },
        isBoxItem: {
            type: Boolean,
            default: false
        },
        locationCode: {
            type: String
        }
    },
    components: {
        AppInputSelectDropdown
    },
    data: function () {
        return {
            user: {
                role: ''
            },
            orderMaterial: ''
        }
    },
    computed: {
        materials() {
            return this.$store.getters['materials/getMaterials'];
        }
    },
    created() {
        // only for testing purpose
        const route = this.$router.currentRoute;
        if (route.path.includes('material')) {
            this.user.role = 'facilitator';
        } else if (route.path.includes('design')) {
            this.user.role = 'design'
        }

    },
    methods: {

        uploadFile() {
            this.$emit('uploadFile');
        },

        sendToQueue() {
            this.order.setUpOrderMaterial()
                .then(() => {
                    this.$emit('materialSet');
                })
                .catch(err => {
                    this.order.materialId = '';
                    this.$emit('failedToSetMaterial', err.message);
                })
        }
    }
}
</script>

<style lang="scss">
.order {
    padding: 4rem;
    background-color: $color-grey;

    @include respond(xsmall) {
        padding: 2rem;
    }

    &__image {
        background-color: $color-grey-light;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__info {
        padding-left: 3.2rem;

        .md-button {
            margin: $margin-big $margin-small 0 0;
        }

        .material-field {
            color: $color-white;
            margin: $margin-small 0 0;

            label,
            .md-select-value.md-input {
                -webkit-text-fill-color: $color-white;
                color: $color-white;
            }

            .md-icon-image svg {
                fill: $color-white;
            }

            &::after {
                background-color: $color-white;
            }
        }

        @include respond(small) {
            padding: 2rem;
            margin: $margin-medium 0;
            border: 1px solid $color-white;
        }

        @include respond(xsmall) {
            padding: 1rem;
            margin: $margin-small 0;
            border: 1px solid $color-white;
        }

        .print-ticket-button {
            width: 250px;
        }

        .notes-head {
            padding-top: 32px !important;
        }
    }

    .info-list {
        width: 100%;
        padding: 0;
        list-style: none;
        color: $color-white;
        font-size: 1.6rem;

        @include respond(xsmall) {
            font-size: 1.4rem;
        }

        &__row {
            display: flex;
            padding: 1rem 0 0;
        }

        &__row:first-child {
            font-size: 2.8rem;
            margin-bottom: $margin-small;

            @include respond(xsmall) {
                font-size: 1.6rem;
            }
        }

        &__head {
            width: 35%;
            line-height: 1.4;

        }

        &__col {
            width: 65%;
            word-break: normal;
            line-height: 1.4;
            font-weight: 600;
            text-align: right;
        }

        @include respond(xsmall) {
            font-size: 12px;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;

        @include respond(small) {
            justify-content: flex-start;
        }
    }

    /**  This class used on shipment dashboard to decrease components paddings
    * on large screens */
    &.order-minimized {
        padding: 2rem;
        margin-bottom: $margin-medium;
        //cursor: pointer;
    }

    .qr-code-image {
        height: 100px;
        width: 100px;
    }
}

</style>