<template>
    <div class="component-wrap md-layout">
        <div class="md-layout-item md-large-size-50 md-small-size-100 md-xsmall-size-100">
            <AppContentHeader headline="Order your group locations" class="hdr-page"/>

            <p class="group-description">Drag and drop your selected locations so they appear in the correct order.</p>

            <draggable v-model="locations" @start="drag=true" @end="drag=false">
                <div v-for="(location, index) in locations" :key="index + '_location'" :id="location.locations_id + '_loc'"
                     class="md-alignment-top-left md-large-size-100 location-grid">
                    <md-button class="md-raised md-primary location-grid__locations-btns">{{location.locations_name}}</md-button>
                </div>
            </draggable>

            <md-button class="md-primary md-raised save-group" @click="createGroup()" :disabled="isClicked">Save and create group</md-button>


            <md-snackbar
                    md-position="center"
                    :md-active.sync="snackbar.show"
                    :md-duration="snackbar.duration"
                    md-persistent>

                <span>{{snackbar.message}}</span>
                <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

            </md-snackbar>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'

    export default {
        name: 'OrderGroupLocations',
        components: {
            draggable
        },
        data: function() {
            return {
                locations: [],
                groupInformationObject: {},
                groupObjectToSendForCreation: {},
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
                isClicked: false
            }
        },
        methods: {
            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },

            createGroup(){

                this.isClicked = true;

                let allLocationsReordered = document.getElementsByClassName("location-grid");

                let locationIdArray = [];

                for(let i = 0; i < allLocationsReordered.length; i++){
                    let currentLocId = allLocationsReordered[i].id.split('_')[0];
                    let locIdAsNumber = Number(currentLocId);
                    let currentLocationObject = {
                        id: locIdAsNumber,
                        order: i + 1
                    };
                    locationIdArray.push(currentLocationObject);
                }

                this.groupObjectToSendForCreation['name'] = this.groupInformationObject.groupName;
                this.groupObjectToSendForCreation['locations'] = locationIdArray;

                this.$axios.post(`/installation-groups`, this.groupObjectToSendForCreation)
                    .then(() => {
                        this.showSnackbar('Group successfully created!');

                        let _this = this;

                        setTimeout(function(){
                            _this.$router.push('/installer-groups');
                        }, 4000);
                    })
                    .catch(err => {
                        this.isClicked = false;
                        this.showSnackbar(err.message);
                    })
            }
        },
        created(){
           this.groupInformationObject = this.$store.getters['installations/getSelectedLocations'];
           this.locations = this.groupInformationObject.selectedLocations;
        }
    }
</script>

<style lang="scss" scoped>


    .hdr-page{

        @include respond(small) {
            margin: 0px;
        }
    }

    .location-grid{

        &__locations-btns{
            width: 40%;
            margin-right: 4%;
            margin-top: 3%;

            @include respond(small) {
                width: 100%;
            }
        }
    }

    .save-group{
        position: absolute;
        right: 55px;
        top: 160px;

        @include respond(small) {
            position:relative;
            right: 0px;
            top: 0px;
            margin: 0 auto;
            width: 100%;
            margin-top: 65px;
        }
    }

    .group-description{
        color: #7B7C7E;

        @include respond(small) {
            margin-bottom: 30px;
            text-align:center;
        }
    }

    .filter-search {
        /deep/ input {

            @include respond(small) {
                width: 100%;
            }
        }
    }
</style>