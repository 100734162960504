<template>
    <div class="component-wrap">

        <md-list class="materials-list md-elevation-10">
            <md-list-item class="material"
                v-for="(material, index) in materials" :key="index + 'material'">

                <span class="material__id">{{material.id}}</span>
                <span class="material__name">{{material.name}}</span>
                
                <md-button class="md-fab md-mini md-plain md-primary" @click.stop="editMaterial(material)">
                    <md-icon>edit</md-icon>
                </md-button>

                 <md-button
                    v-if="material.id != 1"
                    class="md-fab md-mini md-plain md-primary"
                    @click.stop="deleteMaterial(material.id)">
                    <md-icon>delete</md-icon>
                </md-button>

            </md-list-item>
        </md-list>

    </div>
</template>

<script>
export default {
    name: 'MaterialsList',

    props: {
        materials: {
            type: Array,
            required: true
        }
    },

    methods: {
        editMaterial(material) {
            this.$emit('updateMaterial', material);
        },
        deleteMaterial(id) {
            this.$emit('deleteMaterial', id);
        }
    }
}
</script>

<style lang="scss" scoped>
.materials-list {
    width: 100%;
    padding: 0;
}
.material {
    justify-content: flex-start;
    border: 1px solid rgba($color-black, .5);
    
    &__id {
        flex: 0 0 5%;
    }

    &__name {
        flex: 0 1 80%;
    }
    
    &:not(:last-child) {
        border-bottom: 0;
    }
}
</style>
