<template>
	<div class="component-wrap">
		<AppContentHeader headline="User Settings"></AppContentHeader>
		<transition mode="out-in" name="fade">

			<md-tabs class="design-orders-tabs">

				<md-tab id="tab-sponsorships" md-label="Notifications">
					<div class="md-layout md-alignment-top-center">
						<div v-for="notification in userNotifications" :key="notification.id + '_notification'">
							<p>{{notification.name}}</p>
							<toggle-button
									v-model="notification.active"
									color="#CD0A2F"
									:width=100
									:font-size=17
									:height=35
									@change="responseOnToggle(notification)"
									:labels="{checked: 'On', unchecked: 'Off'}"/>
						</div>
					</div>

					<AppEmptyState message="There is no notifications to display." v-if="!userNotifications.length"/>

				</md-tab>

			</md-tabs>
		</transition>

	</div>
</template>

<script>

	export default {
        name: 'UserSettings',
        data: function() {
            return {
                userNotifications: []
            }
        },

        computed: {
            currentUser() {
                return this.$store.getters['auth/getCurrentUser'];
            }
        },

		methods: {

            getUserNotifications(){

                this.$axios.get('/user-notification-preferences').then((response) => {

                    this.userNotifications = response.data;
                });
            },

            responseOnToggle(notification){

                let objToSend = {
                    notification_type_id: notification.id,
                    active: notification.active
                };

                this.$axios.post('/user-notification-preferences', objToSend).then((response) => {
                    console.log(response)
                });
            }
		},

		created(){

            this.getUserNotifications();

		}
    }

</script>

<style lang="scss" scoped>

</style>