<template>
    <div class="component-wrap md-layout">
        <div class="md-layout-item md-large-size-50 md-small-size-100 md-xsmall-size-100">
            <AppContentHeader headline="Edit Installation Group" class="hdr-page"/>

            <AppInput
                    class="group-name"
                    required
                    placeholder="Give this group a name"
                    v-model="$v.groupName.$model"
                    :error="$v.groupName.$error && !$v.groupName.required"
            />

            <p class="group-description">Drag and drop your selected locations so they appear in the correct order.</p>

                <draggable v-model="locations" @start="drag=true" @end="drag=false">
                    <div class="md-alignment-top-left location-grid" v-for="(location, index) in locations" :key="index + '_location'" :id="location.id + '_loc'">
                    <md-button class="md-raised md-primary md-large-size-100 location-grid__locations-btns">{{location.name}}</md-button>
                    </div>
                </draggable>

            <md-button @click="updateGroup()" :disabled="isClicked"
                       class="md-primary md-raised create-group-next">Update
            </md-button>

            <md-snackbar
                    md-position="center"
                    :md-active.sync="snackbar.show"
                    :md-duration="snackbar.duration"
                    md-persistent>

                <span>{{snackbar.message}}</span>
                <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

            </md-snackbar>

        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'
    import draggable from 'vuedraggable'

    export default {
        name: 'InstallationGroupEdit',
        components: {
            draggable
        },
        data: function() {
            return {
                currentGroupID: this.$route.params.id,
                groupName: '',
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
                locations: [],
                isClicked: false
            }
        },
        validations: {
            groupName: {required}
        },
        methods:{

            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },

            getInstallationGroup(){
                let groupInformationObject = this.$store.getters['installations/getSelectedLocations'];
                this.locations = groupInformationObject.locations;
                this.groupName = groupInformationObject.name;
            },

            updateGroup(){

                let _this = this;

                this.isClicked = true;

                let allLocationsReordered = document.getElementsByClassName("location-grid");

                let locationIdArray = [];

                for(let i = 0; i < allLocationsReordered.length; i++){
                    let currentLocId = allLocationsReordered[i].id.split('_')[0];
                    let locIdAsNumber = Number(currentLocId);
                    let currentLocationObject = {
                        id: locIdAsNumber,
                        order: i + 1
                    };
                    locationIdArray.push(currentLocationObject);
                }

                let groupObjectToSend = {
                    name: this.groupName,
                    locations: locationIdArray
                }

                let groupId = this.currentGroupID;

                this.$axios.put(`/installation-groups/${groupId}`, groupObjectToSend)
                    .then(() => {
                        this.showSnackbar('Group successfully updated!');

                        setTimeout(function(){
                            _this.$router.push('/installer-groups');
                        }, 4000);
                    })
                    .catch(err => {
                        this.isClicked = false;
                        _this.showSnackbar(err.message);
                    })
            }
        },

        created(){
            this.getInstallationGroup();
        }
    }
</script>

<style lang="scss" scoped>


    .hdr-page{

        @include respond(small) {
            margin: 0px;
        }
    }

    .location-grid{

        .md-button {
            &:not(.md-primary) {
                background-color: rgba($color-grey, .6);
                color: $color-white;
                border-radius: 5px;
            }

            &:not(.md-primary):hover {
                background-color: rgba($color-grey, .9);
                color: $color-white;
            }
        }

        &__locations-btns{
            width: 40%;
            margin-right: 4%;
            margin-top: 3%;

            @include respond(small) {
                width: 100%;
            }
        }
    }

    .create-group-next{
        position: absolute;
        right: 55px;
        top: 160px;

        @include respond(small) {
            position:relative;
            right: 0px;
            top: 0px;
            margin: 0 auto;
            width: 100%;
            margin-top: 65px;
        }
    }

    .group-name {
        /deep/ input {

            @include respond(small) {
                width: 100%;
            }
        }
    }

    .group-description{
        color: #7B7C7E;

        @include respond(small) {
            text-align:center;
            margin-bottom: 30px;
        }
    }

    /deep/ .control-field__input{
        background-color: white;
        width: 60%;
        margin-bottom: 20px;
        padding: 0px;
        height: 35px;
        font-size: 1.5rem;
        border: none;
        border-bottom: 4px solid #CD0A2F;
    }

    /deep/ .control-field__input:focus{
        border: none;
        border-bottom: 4px solid #CD0A2F;
    }

    /deep/ .control-field__error{
        bottom: 3px;
    }

    /deep/ .control-field__input:valid{
        border: none;
        color: black;
        border-bottom: 4px solid #CD0A2F;
    }
</style>