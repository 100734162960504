<template>
    <md-dialog :md-active.sync="showDialog"
               class="reset-email-dialog"
               style="margin: auto"
               :md-click-outside-to-close="false">
        
        <md-dialog-title class="dialog__header">
            <h4 class="md-title dialog__title">Forgot password?</h4>
            <p class="md-body-1 dialog__text">
                No worries!<br>Enter your email below and we will send you reset link.
                Be aware, that link will be expired in 24 hours.
            </p>
        </md-dialog-title>
        
        <div class="dialog__content">
            
            <AppInput
                class="login__field"
                required
                placeholder="Email"
                v-model="$v.email.$model"
                :error="$v.email.$invalid && $v.email.$dirty"
                errorText="Valid email is required"
            />

        </div>

        <md-dialog-actions class="dialog__actions">
            <md-button class="md-primary" @click="closeDialog()">Cancel</md-button>
            
            <md-button  :disabled="$v.$invalid"
                        class="md-primary md-raised"
                        @click="requestEmail()">Submit</md-button>
        </md-dialog-actions>

    </md-dialog>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: 'ResetPasswordDialog',
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        emailProp: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            email: this.emailProp ? this.emailProp : ''
        }
    },

    validations: {
        email: { required, email },
    },

    methods: {
        closeDialog() {
            this.email = '';
            this.$emit('update:showDialog', false);
        },
        requestEmail() {
            this.$emit('emailRequested');
            this.closeDialog();
        }
    }
  }
</script>

<style lang="scss" scoped>
    .reset-email-dialog {
        width: 500px;
    }
    .dialog {
        &__header {
            margin-bottom: $margin-small;
        }
        &__title {
            margin-bottom: $margin-small;
        }
        &__text {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
        &__content {
            padding: 0 2.4rem;
        }
        &__actions {
            padding: 0 2.4rem;
        }
    }
</style>
