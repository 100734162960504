<template>
    <md-card v-bind="$attrs" v-if="order" class="md-elevation-15 order-card">

        <md-card-header class="card-header__horizontal">

            <div class="md-subhead">{{ order.spaceDescription }}</div>

            <div class="md-subhead subhead-orderID">{{ order.id }}</div>
        </md-card-header>
        <!-- order info -->

        <router-link :to="`${orderLink}${order.id}`" v-if="cardMode !== 'minimal'">

            <md-card-media>
                <!-- :src="order.artworkThumbnail ||  order.fallbackImage" -->
                <img v-if="cardMode !== 'installationTickets'" v-lazy="order.artworkThumbnail || order.fallbackImage"
                     alt="Proof thumbnail"
                     class="card-image">
                <div class="report-description" v-if="cardMode === 'installationTickets'">
                    {{ orderData.content.substring(0, 50) + '...' }}
                </div>
                <md-tooltip md-direction="bottom">Click on image to see more info</md-tooltip>
            </md-card-media>
        </router-link>

        <!-- proof thumbnail -->

        <md-card-header>

            <OrderCardStatusRibbon :order="order"/>

            <div v-if="cardMode === 'design'" class="md-subhead artWorkName_sub">{{ order.artworkName }}</div>

            <p v-if="cardMode !== 'material'" class="md-body-1"><strong>End of market disposition:</strong> {{
                    order.disposition ? order.disposition :
                        `is Not set`
                }}</p>
            <p v-if="cardMode !== 'material'" class="md-body-1">
                <strong>Installation group:</strong> {{
                    order.installationGroupName ?
                        order.installationGroupName :
                        `is Not set`
                }}</p>

            <AppInputSelectDropdown
                v-if="cardMode === 'material'"
                class="create-form__field"
                v-model="order.materialId">

                <option v-for="(material, index) in materials" :key="'material_'+ index" :value="material.id">
                    {{ material.name }}
                </option>

            </AppInputSelectDropdown>

            <!--            end of first dynamic content section-->
        </md-card-header>
        <!-- card content -->

        <md-card-expand>

            <md-card-actions md-alignment="space-between">

                <div v-if="cardMode === 'design'">

                    <md-button class="md-raised md-primary" v-if="order.artworkDownloadLink"
                               :href="order.artworkDownloadLink"
                               :download="order.artworkName"
                               target="_blank"
                    >Download
                    </md-button>

                    <!-- <router-link tag="md-button"
                                 :to="`${order.mediaType}/${order.id}`"
                                 class="md-raised md-primary" append>Proceed</router-link> -->
                </div>

                <div v-if="cardMode === 'design'">
                    <OrderCardStatusesMenu :order="order" @statusUpdated="onStatusUpdated()"/>
                    <!-- <router-link tag="md-button"
                                 :to="`${order.mediaType}/${order.id}`"
                                 class="md-raised md-primary" append>Proceed</router-link> -->
                </div>

                <div v-if="cardMode === 'material'">
                    <md-button class="md-raised md-primary"
                               :disabled="!order.materialId"
                               @click="sendToQueue()">Send to queue
                    </md-button>
                </div>

                <div>
                    <md-button v-if="needToUnset" class="md-raised md-primary"
                               @click="unSet()">Unset
                    </md-button>
                </div>
                <!-- end of second dynamic content section -->

                <div v-if="cardMode === 'installer--vendor'">
                    <md-button class="md-raised md-primary"
                               @click="changeStatus()">{{ nextStatuses.text }}
                    </md-button>

                    <OrderCardStatusesMenu :order="order" @statusUpdated="onStatusUpdated()"/>
                </div>
                <!-- end of second dynamic content section -->

                <md-card-expand-trigger>
                    <md-button class="md-primary">
                        Notes
                    </md-button>
                </md-card-expand-trigger>

            </md-card-actions>
            <!-- end of expand action panel -->

            <md-card-expand-content>

                <md-card-content v-if="order.mediaType != 'signage'" class="order-card__notes">
                    {{ order.notes ? order.notes : 'There are no notes for this item' }}
                </md-card-content>

                <md-card-content v-if="order.mediaType === 'signage'" class="order-card__notes">

                    <p class="md-body-2">Design notes</p>
                    <p class="md-body-1">{{
                            order.notes.notes ?
                                order.notes.notes :
                                'There are no notes for this item'
                        }}</p>

                    <p class="md-body-2">Reference field</p>
                    <p class="md-body-1">{{
                            order.notes.reference ?
                                order.notes.reference :
                                'No reference info yet..'
                        }}</p>

                    <p class="md-body-2">Upload description</p>
                    <p class="md-body-1">{{
                            order.notes.upload ?
                                order.notes.upload :
                                'No upload desription yet..'
                        }}</p>

                </md-card-content>
            </md-card-expand-content>
            <!-- end of expand content -->

        </md-card-expand>

    </md-card>

</template>

<script>

/**
 * Appearance of this component depends on CardMode property value,
 * that will be passed from parent component.
 * 1. Mode [design] should be used in DesignDashboardComponent
 *                  and will contain Download and proceed btns;
 * 2. Mode [material] should be used in MaterialDashboardComponent
 *                  and will contain select input for material;
 * 3. Mode [small] should be used in *QueueManager components
 *                  and will display minimum info
 * 4. Mode [installationTickets] should be used in Installation tickets page
 * 5. Mode [installationWalkthrough] should be used in Installation tickets page
 */

/**
 * To do: try to redo component to use slots instead modes, see what's better.
 */

import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin';
import OrderCardStatusesMenu from '@/components/Common/OrderCardStatusesMenu';
import OrderCardStatusRibbon from '@/components/Common/OrderCardStatusRibbon';
import AppInputSelectDropdown from '@/components/Base/AppInputSelectDropdown';

export default {
    name: 'UIOrderCard',
    mixins: [InitializeOrderMixin],
    props: {
        cardMode: {
            type: String,
            default: 'design',
        },
        orderData: {
            type: Object,
            default: null,
        },
        nextStatuses: {
            type: Object,
            default: null,
        },
    },

    data: function() {
        return {
            order: null,
            orderMaterial: '',
            orderLink: '',
            imageUrl: document.location.origin,
            needToUnset: false,
        };
    },

    components: {
        OrderCardStatusesMenu,
        OrderCardStatusRibbon,
        AppInputSelectDropdown,
    },

    computed: {
        materials() {
            return this.$store.getters['materials/getMaterials'];
        },
    },

    async created() {

        this.initializeOrder(this.orderData);

        if (this.cardMode === 'design') {
            this.orderLink = `/design/${this.order.mediaType}/`;
        } else if (this.cardMode === 'material') {
            this.orderLink = `/material/${this.order.mediaType}/`;
        } else if (this.cardMode === 'installer' || this.cardMode === 'ready_to_install' || this.cardMode ===
            'installer--vendor') {
            this.orderLink = `/installer/${this.order.mediaType}/`;
        }

        if (this.$router.currentRoute.name === 'MQ1') {
            this.needToUnset = true;
        } else {
            this.needToUnset = false;
        }
    },

    methods: {

        changeStatus() {
            let payload;

            if (this.order.mediaType === 'sponsorship') {
                payload = {
                    sponsorship_status: this.nextStatuses.sponsorship,
                };
            } else {
                payload = {
                    status_id: this.nextStatuses.signage,
                };
            }

            this.order.updateOrder(payload).then(() => {
                this.$emit('statusUpdated');
            }).catch(err => {
                console.log('failedToUpdateStatus', err.message);
            });
        },

        sendToQueue() {
            this.order.setUpOrderMaterial().then(() => {
                this.$emit('materialSet');
            }).catch(err => {
                this.order.materialId = '';
                this.$emit('failedToSetMaterial', err.message);
            });
        },

        unSet() {
            this.order.unSet().then(() => {

                this.$router.push('/material/queues');

            }).catch(err => {
                console.log(err);
            });
        },

        onStatusUpdated() {
            this.$emit('statusUpdated');
            this.initializeOrder(this.orderData);
        },
    },
};
</script>

<style lang="scss" scoped>

.order-card {
    margin-bottom: $margin-big;

    .card-header__horizontal {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 5px;
    }

    .card-image {
        cursor: pointer;
    }

    .material-field {
        margin: 0;
    }

    &:last-child {
        margin-right: auto;
    }

    .md-card-media {
        height: 370px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        background: $color-grey-light;
    }

    &__notes {
        .md-body-1 {
            margin-bottom: 1.6rem;
        }
    }

    .md-card-actions.md-alignment-space-between {
        @include respond(small) {
            align-items: flex-start;
        }
    }

    .installation-walkthrough-btns {
        margin-left: 20px;
        margin-right: 20px;

        .md-button {
            margin-bottom: 10px;
            border: 1px solid #D3D3D3;
        }
    }

    .severity__field, .create-report-btn {
        margin-top: 20px;
        width: 100%;
    }

    .correctly-btn {
        padding: 0px;
    }

    .action-walkthrough-btns {
        @include respond(small) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .artWorkName_sub {
        text-align: center;
    }


    .subhead-orderID {
        margin-top: auto;
        margin-bottom: auto;

        @include respond(small) {
            text-align: center;
            width: 100%;
        }
    }

    .subhead-third-number {
        color: #CD0A2F;
        font-weight: bold;
        margin-right: 4px;
    }

    .report-description {
        color: red;
    }

    /deep/ .severity__field select {
        cursor: pointer;
    }
}

/deep/ .md-card-header:first-child > .md-card-header-text > .md-title:first-child, .md-card-header:first-child > .md-title:first-child {
    margin-top: 0px;
}
</style>