<template>
    <div class="component-wrap">
        
        <AppContentHeader :headline="'Order #' +  id"></AppContentHeader>

        <AppSpinner v-if="loading" :loading="loading"/>

        <div v-if="!loading" class="order-wrap md-layout md-gutter">

            <OrderCard
                :orderData="order"
                cardMode="installer"
                class="md-layout-item main-card md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100 u-clear-margins">
            </OrderCard>

            <InstallerDashboardSetUpTearDownData
                v-if="!order.media_id"
                :orderData="order"
                class="md-layout-item card-cont md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-elevation-15"/>

            <ShipmentItemStatusesSection
                @updateOrder="getOrder()"
                :orderData="order"
                class="md-layout-item card-cont md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100 md-elevation-15 u-clear-margins"/>

        </div>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

    </div>

</template>

<script>
import OrderCard from "@/components/Common/OrderCard.vue"
import ShipmentItemStatusesSection from "@/components/ShipmentDashboard/ShipmentItemStatusesSection"
import InstallerDashboardSetUpTearDownData from "@/components/InstallerDashboard/InstallerDashboardSetUpTearDownData";

export default {
    name: 'InstallerDashboardOrder',
    data: () => {
        return {
            id: null,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            order: null,
            loading: true
        }
    },

    components: {
        OrderCard,
        ShipmentItemStatusesSection,
        InstallerDashboardSetUpTearDownData
    },

    created() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        this.getOrder();
    },

    methods: {

        showSnackbar(errorMessage = null) {
            this.snackbar.message = errorMessage ? errorMessage : 'Material successfully set';
            this.snackbar.show = true;
        },

        getOrder() {

            this.loading = true;
            let requestUrl = '';

            if (this.mode === 'sponsorship') {
                requestUrl = `sponsorships/${this.id}`;
            } else if (this.mode === 'signage') {
                requestUrl = `signage-orders/${this.id}`;
            } else if (this.mode === 'free-standing') {
                requestUrl = `freeStanding/${this.id}`;
            }

            this.$axios.get(requestUrl)
                .then(res => {
                    this.order = res.data;

                    if(this.order.install_by && this.order.install_by.split('-')[0] === '0000'){
                        this.order.install_by = null;
                    }

                    this.loading = false;
                })
                .catch(err => {
                    this.showSnackbar(err.message);
                })

        }

    },
}
</script>

<style lang="scss" scoped>
.order-wrap {
    justify-content: space-around;
}


.main-card{
    @include respond(medium) {
        padding-right: 0px!important;
        padding-left: 0px!important;
        margin-bottom: 20px!important;
    }
}

.md-layout.md-gutter>.md-layout-item {

    @include respond(medium) {
        padding-right: 15px;
        padding-left: 15px;
    }
}


</style>