import Vue from 'vue'
export const EventBus = new Vue();
import App from './App.vue'
import { $axios } from './plugins/axios'
import { store } from './store/store'

// Vue Material Imports
import {
    MdButton,
    MdTable,
    MdContent,
    MdIcon,
    MdTabs,
    MdDialog,
    MdCheckbox,
    MdList,
    MdApp,
    MdDrawer,
    MdToolbar,
    MdCard,
    MdLayout,
    MdField,
    MdMenu,
    MdTooltip,
    MdSnackbar,
    MdSwitch,
    MdAutocomplete,
    MdHighlightText
} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'

// Custom Components
import AppInput from './components/Base/AppInput.vue'
import AppRadioInput from './components/Base/AppRadioInput.vue'
import AppContentHeader from './components/Base/AppContentHeader.vue'
import AppEmptyState from './components/Base/AppEmptyState.vue'
import AppSpinner from './components/Base/AppSpinner.vue'
import AppConfirmDialog from './components/Base/AppConfirmDialog'
import { ToggleButton } from 'vue-js-toggle-button'

// Custom SCSS file
import './assets/scss/main.scss'

// Routes settings
import router from './router/router'

// Form Validation
import Vuelidate from 'vuelidate'

// Date Formating filter
import VueFilterDateFormat from 'vue-filter-date-format';

// QR codes reader
import VueQrcodeReader from "vue-qrcode-reader";

// Pagination
import VuePagination from "vuejs-paginate";

// Infinite scrooling

// lazy load
import VueLazyload from 'vue-lazyload';

/** Vue-touch-events */
import Vue2TouchEvents from 'vue2-touch-events'

/** Hides production mode warnings */
Vue.config.productionTip = false

/** Form Validation */
Vue.use(Vuelidate);

/** Axios plugin settings */
Vue.use({
    install(Vue) {
        Vue.prototype.$axios = $axios
    }
});

/** Vue Material components */
Vue.use(MdButton);
Vue.use(MdTable);
Vue.use(MdContent);
Vue.use(MdIcon);
Vue.use(MdTabs);
Vue.use(MdDialog);
Vue.use(MdCheckbox);
Vue.use(MdList);
Vue.use(MdApp);
Vue.use(MdDrawer);
Vue.use(MdToolbar);
Vue.use(MdCard);
Vue.use(MdLayout);
Vue.use(MdField);
Vue.use(MdMenu);
Vue.use(MdTooltip);
Vue.use(MdSnackbar);
Vue.use(MdSwitch);
Vue.use(MdAutocomplete);
Vue.use(MdHighlightText);

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
    methods: {
        isInvalidValue: function isInvalidValue() {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

/** Custom components use */
Vue.component('paginate', VuePagination);
Vue.component('AppInput', AppInput);
Vue.component('AppRadioInput', AppRadioInput);
Vue.component('AppContentHeader', AppContentHeader);
Vue.component('AppEmptyState', AppEmptyState);
Vue.component('AppSpinner', AppSpinner);
Vue.component('AppConfirmDialog', AppConfirmDialog);
Vue.component('ToggleButton', ToggleButton)

/** Filters */
Vue.use(VueFilterDateFormat);

/** Qr codes reader */
Vue.use(VueQrcodeReader);

/** Infinite scrolling */


/** Lazy load */
Vue.use(VueLazyload);

/** Vue-touch-events */
Vue.use(Vue2TouchEvents)


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');