<template>
	<div class="component-wrap">

		<AppContentHeader headline="Signage billing report">

		</AppContentHeader>

		<div class="md-layout md-gutter">
			<div class="md-layout-item md-size-25">
				<md-field>
					<label>Select season</label>
					<md-select v-model="selectedSeason" name="Select season" id="season">
						<md-option
							v-for="(season) in seasons"
							:key="season.seasons_id"
							:value="season.seasons_id"
						>
							{{ season.seasons_name }}
						</md-option>

					</md-select>
				</md-field>
			</div>

		</div>
		<md-button class="md-primary md-raised" @click="downloadReport">DOWNLOAD</md-button>
	</div>
</template>

<script>

	export default {
		name: 'ReportsDashboardBillingSignage',
		data: () => ({
			seasons: [],
			selectedSeason: null,
		}),

		components: {},

		methods: {

			downloadReport() {

				this.$axios.get(`/signage-orders/billing/${this.selectedSeason}/export`)
					.then(res => {

						window.open(res.data);
					})
					.catch(err => {
						console.log(err);
					});
			},

			getSeasons() {

				return this.$axios.get(`/seasons`)
					.then(res => {
						this.seasons = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},

			async loadData() {

				await this.getSeasons();
			}
		},

		created() {

			this.loadData();
		}
	}
</script>

<style lang="scss" scoped>

	.md-button {
		margin-top: 4rem;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
		border-radius: 4px;
	}
</style>