<template>
    <div class="component-wrap md-layout">
        <div class="md-layout-item md-large-size-50 md-small-size-100 md-xsmall-size-100">
            <AppContentHeader headline="Create Installation Group" class="hdr-page"/>

            <AppInput
                      class="group-name"
                      required
                      placeholder="Give this group a name"
                      v-model="$v.groupName.$model"
                      :error="$v.groupName.$error && !$v.groupName.required"
            />
            <div class="location-search-container">
                <AppInput
                        class="filter-search"
                        required
                        placeholder="Search for available location"
                        v-model="searchString"
                />
                <md-button class="md-primary md-raised search-btn" @click="getLocations()" :disabled="searchString.length < 2">Search</md-button>
                <p v-if="!locations.length > 0" class="group-description">To choose a locations add some criteria in the search field. At least 2 characters needed !</p>
                <p v-if="locations.length > 0" class="group-description">Select which location should be in this group. Don't worry about the order,
                    we'll put them in order in the next step.</p>

            </div>

            <div class="md-alignment-top-left location-grid" v-if="!loadingSpinner">
                <md-button
                        class="md-raised md-large-size-100 location-grid__locations-btns"
                        v-for="(location, index) in locations" :key="location.locations_id + '_location'"
                        ref="all_locations" :id="index + '_loc'"
                        @click="changeBtnSelection(index)"
                        :class="{'md-primary': location.selected}">{{location.locations_name}}
                </md-button>
            </div>

            <md-button @click="goToNextRound()" :disabled="selectedLocations.length === 0 || groupName.length === 0"
                       class="md-primary md-raised create-group-next">Next
            </md-button>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators'

    export default {
        name: 'InstallationGroupCreate',
        data: function () {
            return {
                searchString: '',
                groupName: '',
                locations: [],
                selectedLocations: [],
                loadingSpinner: true
            }
        },
        validations: {
            groupName: {required}
        },
        methods: {

            changeBtnSelection(locationIndex) {

                let location = this.locations[locationIndex];

                location.selected = !location.selected;

                if (location.selected) {

                    this.selectedLocations.push(location);

                } else {

                    let locationId = location.locations_id;

                    this.selectedLocations = this.selectedLocations.filter(function (obj) {
                        return obj.locations_id !== locationId;
                    });
                }

                this.$forceUpdate();
            },

            async getLocations() {

                let _this = this;

                let searchStringForRequest = this.searchString;

                this.$axios.get('/installation-groups-available-locations',{
                    params: {name: searchStringForRequest},
                }).then((res) => {

                    _this.locations = res.data;

                    for (let i = 0; i < _this.locations.length; i++) {
                        _this.locations[i].selected = false;
                    }

                    _this.loadingSpinner = false;

                    this.$store.dispatch('installations/setSelectedLocations', {});

                    this.$forceUpdate();

                }).catch((res) => {

                    console.log(res);
                });
            },

            goToNextRound() {

                let groupInformationObject = {
                    groupName: this.groupName,
                    selectedLocations: this.selectedLocations
                };

                this.$store.dispatch('installations/setSelectedLocations', groupInformationObject);

                this.$router.push('/installer-group-create/order-locations');
            },
        }
    }
</script>

<style lang="scss" scoped>

    .hdr-page {

        @include respond(small) {
            margin: 0px;
        }
    }

    .location-grid {
        display: grid;
        grid-gap: 1rem 2rem;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        .md-button {
            width: 100%;
            &:not(.md-primary) {
                background-color: rgba($color-grey, .6);
                color: $color-white;
                border-radius: 5px;
            }

            &:not(.md-primary):hover {
                background-color: rgba($color-grey, .9);
                color: $color-white;
            }
        }

        &__locations-btns {
            width: 40%;
            margin-right: 4%;
            margin-top: 3%;

            @include respond(small) {
                width: 100%;
            }
        }
    }

    .create-group-next {
        position: absolute;
        right: 55px;
        top: 160px;

        @include respond(small) {
            position: relative;
            right: 0px;
            top: 0px;
            margin: 0 auto;
            width: 100%;
            margin-top: 65px;
        }
    }

    .group-name {
        /deep/ input {

            @include respond(small) {
                width: 100%;
            }
        }
    }

    .location-search-container {
        display: grid;
        margin-bottom: 30px;
        margin-top: 30px;

        .search-btn{
            width: 60%;
        }

        .filter-search {

            /deep/ input {
                /*padding-left: 10px;*/
                margin-bottom: 10px;
            }
        }
    }

        .group-description {
            color: #7B7C7E;
            margin-top: 10px;

        @include respond(small) {
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .pagination {

        display: flex;
        font-weight: 500;
        justify-content: space-around;
        list-style: none;
        margin: 3rem auto;
        width: 300px;

        .page-item {

            a {
                color: #CD0A2F;
            }
        }
    }

        /deep/ .control-field__input {
            background-color: white;
            width: 60%;
            margin-bottom: 20px;
            padding: 0px;
            height: 35px;
            font-size: 1.5rem;
            border: none;
            border-bottom: 4px solid #CD0A2F;
        }

        /deep/ .control-field__input:focus {
            border: none;
            border-bottom: 4px solid #CD0A2F;
        }

        /deep/ .control-field__error {
            bottom: 3px;
        }

        /deep/ .control-field__input:valid {
            border: none;
            color: black;
            border-bottom: 4px solid #CD0A2F;
        }

</style>