<template>
    <div class="item__status-flow">
                    
        <h6 class="md-subheading">Manage statuses</h6>

         <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '8')
                || (order.mediaType === 'sponsorship' && order.status === '35')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '8' : '35')">Printed</md-button> 
        <!-- 
            Printed status id: 
                For signage = 8 
                For sponsorship = ?
        -->

        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '13')
                || (order.mediaType === 'sponsorship' && order.status === '28')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '13' : '28')">Packing</md-button> 
        <!-- 
            Packing status id: 
                For signage = 13 
                For sponsorship = 28
        -->

        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '14')
                || (order.mediaType === 'sponsorship' && order.status === '29')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '14' : '29')">Loaded on track</md-button>
        <!-- 
            Loaded status id: 
                For signage = 14
                For sponsorship = 29
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '15')
                || (order.mediaType === 'sponsorship' && order.status === '30')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '15' : '30')">Shipped</md-button>
        <!-- 
            Shiped status id: 
                For signage = 15 
                For sponsorship = 30
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '16')
                || (order.mediaType === 'sponsorship' && order.status === '31')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '16' : '31')">In staging</md-button>
        <!-- 
            In stagging status id: 
                For signage = 16
                For sponsorship = 31
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '17')
                || (order.mediaType === 'sponsorship' && order.status === '32')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '17' : '32')">Unpacked</md-button>
        <!-- 
            Unpacked status id: 
                For signage = 17
                For sponsorship = 32
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '9')
                || (order.mediaType === 'sponsorship' && order.status === '8')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '9' : '8')">Installed</md-button>
        <!-- 
            Installed id: 
                For signage = 9
                For sponsorship = 8
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '18')
                || (order.mediaType === 'sponsorship' && order.status === '33')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '18' : '33')">Problem</md-button>
        <!-- 
            Problem status id: 
                For signage = 18
                For sponsorship = 33
        -->
        
        <md-button
            class="md-raised"
            :class="{
                'md-primary': 
                (order.mediaType === 'signage' && order.status === '19')
                || (order.mediaType === 'sponsorship' && order.status === '34')
            }"
            @click="updateStatus(order.mediaType === 'signage' ? '19' : '34')">Solved</md-button>
        <!-- 
            Solved status id: 
                For signage = 19
                For sponsorships = 34
        -->

    </div>
    <!-- end of statuses card -->
</template>

<script>
import {InitializeOrderMixin} from '@/mixins/InitializeOrderMixin'

export default {
    name: 'ShipmentItemStatusesSection',
    mixins: [InitializeOrderMixin],
    props: {
         orderData: {
            type: Object,
            default: null
        }
    },
    created() {
        this.initializeOrder(this.orderData);
    },
    methods: {
        updateStatus(statusId) {
            let payload = {};
            const fieldName = this.order.mediaType === 'signage' ? 'status_id' : 'sponsorship_status';

            payload[fieldName] = statusId;
            this.order.updateOrder(payload)
                .then(() => {
                    this.$emit('updateOrder');
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    
    &__status-flow {
        @include white-card-with-subheading;
        @include manage-status-card;
    }

}
</style>