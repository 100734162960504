import { render, staticRenderFns } from "./MaterialDashboardManageMaterials.vue?vue&type=template&id=734dd1fc&scoped=true&"
import script from "./MaterialDashboardManageMaterials.vue?vue&type=script&lang=js&"
export * from "./MaterialDashboardManageMaterials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734dd1fc",
  null
  
)

export default component.exports