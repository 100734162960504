import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import orders from './modules/orders'
import materials from './modules/materials'
import installations from './modules/installations'
import shipments from './modules/shipments'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        vendorFilters: {
            search: '',
            mediaType: '',
            location: '',
            status: '',
            space: ''
        },
        vendorOrders: [],
        signageFilter: '',
        materialFilter: ''
    },

    getters: {
        getVendorFilters(state) {
            return state.vendorFilters;
        },
        getVendorOrders(state) {
            return state.vendorOrders;
        },
        getSignageFilter(state) {
            return state.signageFilter;
        },
        getMaterialFilter(state) {
            return state.materialFilter;
        }
    },

    mutations: {
        updateVendorFilters(state, newFilters) {
            state.vendorFilters = newFilters;
        },
        updateVendorOrders(state, newOrders) {
            state.vendorOrders = newOrders;
        },
        updateSignageFilter(state, newText) {
            state.signageFilter = newText;
        },
        updateMaterialFilter(state, newText) {
            state.materialFilter = newText;
        }
    },

    actions: {
        saveVendorFilters(vuexContext, data) {
            vuexContext.commit('updateVendorFilters', data);
        },
        saveVendorOrders(vuexContext, data) {
            vuexContext.commit('updateVendorOrders', data);
        },
        saveSignageFilter(vuexContext, data) {
            vuexContext.commit('updateSignageFilter', data);
        },
        saveMaterialFilter(vuexContext, data) {
            vuexContext.commit('updateMaterialFilter', data);
        }
    },
    
    modules: {
        installations,
        auth,
        orders,
        materials,
        shipments
    }
});