<template>
    <div class="component-wrap md-elevation-5">

        <h4 class="md-subheading">Users list</h4>

        <md-list class="users-list" v-if="!loading">

            <md-list-item
                class="user"
                v-for="(user, index) in users" :key="index + 'user'">
                <p class="md-body-2 user__name">{{user.users_first + ' ' + user.users_last}}</p>
                <p class="md-bpdy-1 user__email">{{user.email}}</p>
                <p class="md-body-1 user__role">{{user.users_role_name}}</p>
                <md-button
                    class="md-primary md-raised"
                    @click="impersonateUser(user)"
                    :disabled="sudoMode">Login</md-button>
            </md-list-item>

        </md-list>

        <AppSpinner v-if="loading" :loading="loading" />
        <AppEmptyState v-if="!loading && !users.length"  message="There are no users to display"/>

        <md-snackbar
            md-position="center"
            :md-active.sync="snackbar.show"
            :md-duration="snackbar.duration"
            md-persistent>
            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Got it</md-button>
        </md-snackbar>

    </div>
</template>

<script>
export default {
    name: 'UsersList',
    data: function() {
        return {
            users: [],
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            loading: true
        }
    },

    created() {
        this.getUsers();
    },

    computed: {
        sudoMode() {
            return this.$store.getters['auth/isSudoMode'];
        }
    },

    methods: {
        showSnackbar(message = "Done") {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },

        impersonateUser(user) {
            this.$store.dispatch('auth/impersonateUser', user.users_id)
                .then(() => {
                    this.showSnackbar(`You are browsing as ${user.users_first + ' ' + user.users_last}`);

                    setTimeout(() => {
                        this.$router.push('/');
                    }, 1000);
                })
                .catch(err => {
                    this.showSnackbar("Ooops! " + err.message);
                })
        },

        getUsers() {
            this.loading = true;

            this.$axios.get('users')
                .then(res => {
                    this.users = res.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.showSnackbar("Ooops! " + err.message);
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.component-wrap {
    padding: 2.4rem;

    .md-subheading {
        width: 100%;
        font-weight: 600;
        margin: 0;
        grid-column: 1 / -1;
    }
}

/deep/ .user {
    &__name {
        width: 20%;
    }

    &__email {
        width: 30%;
    }

    &__role {
        width: 20%;
    }

    .md-list-item-container .md-list-item-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    }
}
</style>