import { $axios } from '@/plugins/axios';
import { sponsorshipStatuses, signageStatuses } from '@/constants';

export default class OrderModel {
	constructor(
		mediaType,
		id,
		companyName,
		artworkId,
		artworkName,
		artworkThumbnail,
		artworkDownloadLink,
		artworkNotes,
		spaceId,
		spaceDescription,
		installationGroupId,
		installationGroupName,
		disposition,
		notes,
		materialId,
		materialName,
		artworkWidth,
		artworkHeight,
		units,
		status,
		statusStage,
		statusName,
		accountExecutiveName = '',
		accountExecutiveEmail = '',
		frontCopy = '',
		backCopy = '',
		reference = '',
		supportMethod = '',
		description = '',
		quantity = '',
		deposition = '',
		size = '',
		installBy = '',
		leaveUntil = '',
		removeBy = '',
		adspaceLocation,
		qr_code_image_url,
		upload_original_url,
		location_code,
		locationName,
		printNotes
	) {
		this.mediaType = mediaType;
		this.id = id;
		this.companyName = companyName;
		this.artworkId = artworkId;
		this.artworkName = artworkName;
		this.artworkThumbnail = artworkThumbnail;
		this.artworkDownloadLink = artworkDownloadLink;
		this.artworkNotes = artworkNotes;
		this.spaceId = spaceId;
		this.spaceDescription = spaceDescription;
		this.installationGroupId = installationGroupId;
		this.installationGroupName = installationGroupName;
		this.disposition = disposition;
		this.notes = notes;
		this.materialId = materialId;
		this.materialName = materialName;
		this.artworkWidth = artworkWidth;
		this.artworkHeight = artworkHeight;
		this.units = units;
		this.status = status;
		this.statusStage = statusStage;
		this.statusName = statusName;

		this.accountExecutiveName = accountExecutiveName;
		this.accountExecutiveEmail = accountExecutiveEmail;
		this.frontCopy = frontCopy;
		this.backCopy = backCopy;
		this.reference = reference;
		this.supportMethod = supportMethod;
		this.description = description;
		this.quantity = quantity;
		this.deposition = deposition;
		this.size = size;
		this.installBy = installBy;
		this.leaveUntil = leaveUntil;
		this.removeBy = removeBy;
		this.adspaceLocation = adspaceLocation;

		this.fallbackImage = document.location.origin +
			require('@/assets/img/empty_state_image.png');

		this.qr_code_image_url = qr_code_image_url;
		this.upload_original_url = upload_original_url;
		this.location_code = location_code;
		this.locationName = locationName;
		this.printNotes = printNotes
	}

	static getBaseRequestUrl(mediaType, id) {
		let requestUrl = '';

		if (mediaType === 'sponsorship' || mediaType === 'free-standing') {
			requestUrl = `sponsorships/${id}`;
		} else if (mediaType === 'signage') {
			requestUrl = `signage-orders/${id}`;
		}

		return requestUrl;
	}

	// downloadArtwork() {
	//
	//     let fileURL = this.artworkDownloadLink;
	//     let fileName = this.artworkName;
	//
	//     fetch(fileURL).then(function(t) {
	//
	//         return t.blob().then((b)=>{
	//
	//                 let a = document.createElement("a");
	//                 a.href = URL.createObjectURL(b);
	//                 a.setAttribute("download", fileName);
	//                 a.click();
	//             }
	//         );
	//     });
	// }

	uploadOriginalFile(file, note) {
		let requestUrl = '';
		const payload = new FormData();

		if (this.mediaType === 'sponsorship' || this.mediaType ===
			'free-standing') {
			requestUrl = `sponsorships/${this.id}/artwork`;
			payload.append('uploadedFile', file, file.name);
			payload.append('artwork_notes', note);

		} else if (this.mediaType === 'signage') {
			requestUrl = `signage-orders/${this.id}/signage-proofs`;
			payload.append('file', file, file.name);
			payload.append('notes', note);
		}

		return $axios.post(requestUrl, payload).then(res => {
			return res;
		});
	}

	/** TO DO: join with update order method, for DRY sake */
	setUpOrderMaterial() {
		let requestUrl = this.constructor.getBaseRequestUrl(this.mediaType,
			this.id);
		let payload = { material_id: this.materialId };

		return $axios.put(requestUrl, payload).then(res => {
			return res;
		});
	}

	unSet() {
		let requestUrl = this.constructor.getBaseRequestUrl(this.mediaType,
			this.id);
		let payload = { material_id: '1' };

		return $axios.put(requestUrl, payload).then(res => {
			return res;
		});
	}

	updateOrder(payload) {
		let requestUrl = this.constructor.getBaseRequestUrl(this.mediaType,
			this.id);

		return $axios.put(requestUrl, payload).then(res => {
			return res;
		});
	}

	updateStatus(status) {

		let payload;

		if (this.mediaType === 'signage') {

			payload = {
				status_id: signageStatuses[status],
			};

			this.status = signageStatuses[status];

		} else {

			payload = {
				sponsorship_status: sponsorshipStatuses[status],
			};

			this.status = sponsorshipStatuses[status];
		}

		this.updateOrder(payload);
	}

	markInstalled() {

		this.updateStatus('INSTALLED');
		this.statusName = 'Installed';
	}

	markRemoved() {

		this.updateStatus('REMOVED');
		this.statusName = 'Removed';
	}

	downloadPrintTicket() {

		let baseRequestUrl = this.constructor.getBaseRequestUrl(this.mediaType,
			this.id);

		return $axios.get(baseRequestUrl + '/print-ticket').then(res => {

			let fileURL = res.data;
			let fileLink = document.createElement('a');
			let fileName = this.artworkName + '.pdf';

			fileLink.href = fileURL;
			fileLink.setAttribute('download', fileName);

			document.body.appendChild(fileLink);

			fileLink.click();

			return res;
		});

	}
}