import axios from 'axios';
import Cookie from 'js-cookie';

/**
 * Possible URLs:
 * atl.imc.tcs.ink, hp.imc.tcs.ink, vegas.imc.tcs.ink
 * atl.test.imc.tcs.ink, hp.test.imc.tcs.ink, vegas.test.imc.tcs.ink
 * atl.dev.imc.tcs.ink, hp.dev.imc.tcs.ink, vegas.dev.imc.tcs.ink
 *
 * APIs:
 * v2.imchp.com/api/v1 v2.imclv.com/api/v1
 * test.imchp.com/api/v1 test.imclv.com/api/v1
 * dev.imchp.com/api/v1 dev.imclv.com/api/v1
 */

const getAPIUrl = () => {
    let campusPrefix = '';
    let branchPrefix = '';
    let [hostNamePrefix, serverPrefix] = window.location.hostname.split('.');

    // console.log('Host Name Prefix / Server Prefix', hostNamePrefix, serverPrefix);

    switch (hostNamePrefix) {
        case 'atl':
            campusPrefix = 'imclv';
            break;
        case 'vegas':
            campusPrefix = 'imclv';
            break;
        case 'hp':
            campusPrefix = 'imchp';
            break;

        default:
            campusPrefix = 'imclv';
    }

    switch (serverPrefix) {
        case 'imc':
            branchPrefix = 'v2';
            break;
        case 'test':
            branchPrefix = 'test';
            break;
        case 'dev':
            branchPrefix = 'dev';
            break;
        case 'beta':
            branchPrefix = 'beta';
            break;

        default:
            branchPrefix = 'dev';
    }

    // console.log('API url:',  `https://${branchPrefix}.${campusPrefix}.com/api/v1`);

    // return `https://${branchPrefix}.${campusPrefix}.com/api/v1`;
    return `https://${branchPrefix}.${campusPrefix}.com/api/v1`;
    // return `https://dev.imclv.com/api/v1/`;
};

export const $axios = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL,
    baseURL: getAPIUrl(),
    headers: {
        Authorization: `Bearer ${Cookie.get('current_user')}`
    },
    withCredentials: true
});

// $axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 400) {
//         console.log('error')
//     }
//     return error;
// });

$axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers.Authorization = `Bearer ${Cookie.get('current_user')}`;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

$axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log('Axios Plugin Interceptor:', error, error.response);
        if (401 === error.response.status) {
            window.location = '/login';
        } else {
            return Promise.reject(error);
        }
    }
);