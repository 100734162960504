<template>
    <div class="component-wrap md-layout">

        <div class="md-layout md-layout-item md-xlarge-size-100 md-large-size-100 md-small-size-100 md-xsmall-size-100">
            <div class="md-layout md-layout-item md-xlarge-size-70 md-large-size-70 md-small-size-50 md-xsmall-size-100">
                <div class="md-layout-item md-large-size-40 md-small-size-50 md-xsmall-size-100">
                    <AppContentHeader headline="Installation walkthrough" class="hdr-page"/>
                </div>
                <div class="md-layout-item md-large-size-30 md-small-size-50 md-xsmall-size-100">
                    <p class="group-name" v-if="!loadingSpinner">{{installationGroupName}}</p>
                </div>
                <div class="md-layout-item md-large-size-25 md-small-size-50 md-xsmall-size-100 order-switcher">
                    <md-switch v-model="showVerifiedOrders">Show verified orders</md-switch>
                </div>
            </div>
        </div>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>
        <div class="md-layout md-layout-item md-xlarge-size-100 md-small-size-100 md-xsmall-size-100 md-alignment-top-center order_cards_container">
            <OrderCard
                    v-for="order in orders"
                    :key="order.index +'_order'"
                    cardMode="installationWalkthrough"
                    :orderData="order"
                    @installedCorrectly="correctInstallation(order.order_id, order.index)"
                    @reportSnackBar="showSnackbar"
                    class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
            </OrderCard>
        </div>

        <AppEmptyState message="There is no installation Walkthroughs to display." v-if="!orders.length && !loadingSpinner"/>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>

    </div>
</template>

<script>
    import OrderCard from "@/components/Common/TicketCard.vue"

    export default {
        name: 'InstallationWalkthrough',
        data: function() {
            return {
                showVerifiedOrders: false,
                installationGroupId: this.$route.params.id,
                installationGroupName: '',
                loadingSpinner: true,
                orders: [],
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                },
            }
        },
        components: {
            OrderCard
        },
        methods: {
            showSnackbar(errorMessage = null) {
                this.snackbar.message = errorMessage ? errorMessage : 'Good, It is Installed Correctly';
                this.snackbar.show = true;
            },
            getCurrentInstallationGroup(){
                let groupId = this.installationGroupId;

                this.$axios.get(`/installation-groups/${groupId}`)
                    .then((res) => {
                        this.installationGroupName = res.data.name;
                        this.loadingSpinner = false;
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            getLocations(){

                let groupId = this.installationGroupId;

                this.$axios.get(`/installation-groups/${groupId}/orders`)
                    .then((res) => {

                        let sponsorships = res.data.sponsorships.map((x, i) => {

                            x['index'] = i + '_sponsorships';
                            x['order_id'] = x.sponsorships_id;
                            x['order_name'] = x.artworkName;

                            return x;
                        });

                        let sinageOrders = res.data['signage-orders'].map((x, i) => {

                            x['index'] = i + '_signage-orders';
                            x['order_id'] = x.id;
                            x['order_name'] = x.signage_description;

                            return x;
                        });

                        this.orders = [...sponsorships, ...sinageOrders];

                        if (!this.showVerifiedOrders) {

                            this.orders = this.orders.filter(function( obj ) {
                                return obj.installer_verified == 0;
                            });
                        }

                        this.orders = this.orders.map((x, i) => {

                            let indexProp = x.index;
                            let indexType = indexProp.split('_')[1];

                            x['index'] = i + '_' + indexType;

                            return x;
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },

            correctInstallation(id, index){

                let indexAsNumber = index.split('_')[0];

                indexAsNumber = Number(indexAsNumber);

                let orderType = index.split('_')[1]

                let chosenOrder = this.orders[indexAsNumber];

                chosenOrder.installer_verified = 1;

                this.$axios.put(`/${orderType}/${id}`, chosenOrder)
                    .then(() => {
                        this.showSnackbar();
                        this.orders.splice(index,1);
                    })
                    .catch(err => {
                        this.showSnackBar(err.message)
                    })
            }
        },
        created(){
            this.getLocations();
            this.getCurrentInstallationGroup();
        },

        watch:{
            showVerifiedOrders(){
                this.getLocations();
            }
        }
    }
</script>

<style lang="scss" scoped>

    .component-wrap{

        .hdr-page{

            @include respond(small) {
                margin: 0px;
            }
        }

        .group-description{
            margin-bottom: 30px;
            color: #7B7C7E;

            @include respond(small) {
                margin-bottom: 0px;
                text-align:center;
            }
        }

        .group-name{
            margin-top: 2.4em;
            color: #5E5F61;
            background-color: #FFCCCC;
            text-align: center;
            padding-top: 4px;
            border-radius: 13px;
            padding-bottom: 4px;

            @include respond(small) {
                margin-top: 0px;
            }
        }

        .install-group-btns{
            width: 40%;
            font-size: 0.8em;
            margin-top: 20px;

            @include respond(medium) {
                width: 100%;
            }
        }

        .create-new-installation-group{
            position: absolute;
            bottom: 65px;
            right: 65px;
        }

        .md-switch {
            display: flex;
        }

        .order-switcher{
            margin-top: 22px;
            margin-left: 10px;


            @include respond(small) {
                margin-top: 0px;
            }
        }
    }

    /deep/ .card-header__horizontal{
        justify-content: flex-start;

        .md-title{
            margin: 0 auto;
        }
    }

    /deep/ .walkthrough{
        text-align:center;
    }

    /deep/ .order_cards_container{
        justify-content: flex-start;
    }


</style>