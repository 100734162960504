<template>
    <div class="component-wrap md-layout">

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <div class="md-layout-item md-large-size-33 md-small-size-50 md-xsmall-size-100" v-if="!loadingSpinner">
            <AppContentHeader headline="Installation Groups" class="hdr-page"/>

            <p class="group-description">Select an installation group to edit, or create a new one.</p>

            <div class="md-alignment-top-left" v-for="(installationGroup, index) in installationGroups" :key="index + '_install-group'" @mouseover="showDeleteBtnByIndex = index" @mouseout="showDeleteBtnByIndex = null">
                <md-button @click="goToEditIt(installationGroup)" class="md-primary md-raised md-large-size-100 install-group-btns">{{installationGroup.name}}</md-button><md-button v-show="showDeleteBtnByIndex === index" @click="deleteGroup(installationGroup.id)" class="md-raised dlt-btn">Delete</md-button>
                <md-button class="md-raised dlt-btn-mobile" @click="deleteGroup(installationGroup.id)">Delete</md-button>
            </div>

            <p v-if="!installationGroups.length" class="empty-msg">Looks like you haven't made any groups yet.</p>
            <p v-if="!installationGroups.length" class="empty-msg">Click the '+' button below to create one.</p>

        </div>

        <div class="md-layout-item md-large-size-66 md-small-size-50 md-xsmall-size-100">
            <div class="md-alignment-right">
                <md-button class="md-fab md-primary create-new-installation-group" @click="goToCreateAGroup()">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
        </div>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->

    </div>
</template>

<script>
    export default {
        name: 'InstallationGroupList',
        data: function() {
            return {
                installationGroups: [],
                loadingSpinner: true,
                showDeleteBtnByIndex: null,
                snackbar: {
                    show: false,
                    message: '',
                    duration: 4000
                }
            }
        },
        methods: {
            showSnackbar(message) {
                this.snackbar.message = message;
                this.snackbar.show = true;
            },

            goToCreateAGroup(){
                this.$router.push('/installer-group-create');
            },

            async getGroups() {
                try {
                    const installGroups = (await this.$axios.get('/installation-groups')).data;
                    this.installationGroups = installGroups;
                    this.loadingSpinner = false;
                } catch (err) {
                    this.loadingSpinner = false;
                }
            },
            goToEditIt(locationGroup){
                this.$router.push('/installer-group-locations/' + locationGroup.id);
            },

            deleteGroup(groupId){

                this.$axios.delete(`/installation-groups/${groupId}`)
                    .then(()=> {

                        this.installationGroups = this.installationGroups.filter(function( obj ) {
                            return obj.id !== groupId;
                        });

                        this.showSnackbar('Installation Group successfuly deleted!');
                    })
                    .catch(err => {
                        this.showSnackbar(`${err.message}. Please, try again.`);
                    });
            }
        },

        created(){
          this.getGroups();
        }
    }
</script>

<style lang="scss" scoped>

    .component-wrap{

        .hdr-page{

            @include respond(small) {
                margin: 0px;
            }
        }

        .group-description{
            margin-bottom: 30px;
            color: #7B7C7E;

            @include respond(small) {
                margin-bottom: 0px;
            }
        }

        .install-group-btns{
            width: 70%;
            margin-top: 20px;

            @include respond(medium) {
                width: 100%;
            }
        }

        .dlt-btn{
            margin-top: 20px;
            margin-left: 10px;

            @include respond(medium) {
                display: none;
            }
        }

        .dlt-btn-mobile{
            display: none;

            @include respond(medium) {
                display: block;
                margin-top: 8px;
                text-align: center;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .create-new-installation-group{
            position: absolute;
            bottom: 65px;
            right: 65px;
        }

        .empty-msg{
            font-size: 1.5em;
            color: darkgray;
            text-align: center;
            margin-bottom: 40px;
        }
    }
</style>