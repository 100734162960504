<template>
    <div class="component-wrap">

        <AppContentHeader headline="Shipment Dashboard">
            <md-button class="md-primary md-raised" @click="$router.push('/shipments/packing')">Pack new box</md-button>
        </AppContentHeader>

        <div class="md-layout md-alignment-top-space-between boxes">
            <ShipmentBox
                v-for="(box, index) in boxes"
                :key="index + '_box'"
                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"/>
            <AppEmptyState v-if="!boxes.length" class="md-layout-item md-large-size-100" message="No boxes to display"/>
        </div>


    </div>
</template>

<script>

import ShipmentBox from "@/components/ShipmentDashboard/ShipmentBox"
export default {
    name: 'ShipmentDashboard',
    data: function() {
        return {
            boxes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
    },
    components: {
        ShipmentBox
    }
}
</script>

<style lang="scss" scoped>
.boxes {
    width: 100%;
}
</style>