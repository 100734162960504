<template>

    <label class="radio-control"
            @click.prevent="toggleCheck($event)">{{radioOption}}
        <input
            type="radio"
            v-bind="$attrs"
            class="radio-control__input"
            :checked="isSelected"
            @click="toggleOnKeyDown($event)">

        <span class="radio-control__circle"></span>
    </label>

</template>

<script>
export default {
    name: 'AppRadioInput',
    props: {
        radioOption: {
            type: String,
            default: 'Option'
        },
        value: {
            type: [String, Number, Boolean, Object],
            default: 'on'
        },
        model: [String, Number, Boolean, Object],
    },
    model: {
      prop: 'model',
      event: 'change'
    },
    computed: {
      isSelected () {
        return this.model === this.value
      }
    },
    methods: {
        toggleCheck() {
            this.$emit('change', this.value);
        },
        toggleOnKeyDown(e) {
            e.stopPropagation();
        },
    }
}
</script>

<style scoped>
.radio-control{
    align-items: center;
    cursor: pointer;
    color: #4d5762;
    display: flex;
    height: 32px;
    font-size: 1.8rem;
    font-weight: 300;
    padding-left: 48.5px;
    position: relative;
    user-select: none;
}
/* Hide default input */
.radio-control__input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
/* Create custom input */
.radio-control__circle{
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    background-color: #fff;
    border: solid 1px rgba(77, 87, 98, .2);
    border-radius: 50%;
}
.radio-control__input:focus ~ .radio-control__circle{
    background-color: #4ea5d9;
}
.radio-control:hover .radio-control__circle {
    border: solid 1px #171717;
}
.radio-control__input:checked ~ .radio-control__circle{
    border: solid 1px #171717;
}
/* Style inner circle */
.radio-control__circle:after {
    content: "";
    background-color: rgba(77, 87, 98, .2);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 15px;
    width: 15px;
}
/* Add strong background to inner circle when input is checked */
.radio-control__input:checked ~ .radio-control__circle:after{
    background-color: #4d5762;
}

</style>