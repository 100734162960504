<template>
    <TheLayoutWrap v-if="loaded">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
    </TheLayoutWrap>
</template>

<script>

import TheLayoutWrap from "@/components/Layout/TheLayoutWrap.vue"

export default {
  name: 'AppContent',
  data: () => ({
    loaded: false
  }),
  components: {
    TheLayoutWrap
  },
  created() {
    this.$store.dispatch('auth/checkUserToken')
      .then(() => {
        this.loaded = true;
      })
  }
}
</script>
