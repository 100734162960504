export const roles = {
    ADMIN: 1,
    MANAGER: 2,
    ADVERTISER: 3,
    ACC_EXECUTIVE: 4,
    WF_PRINTER: 5,
    PUB_PRINTER: 6,
    FACILITATOR: 7,
    DESIGNER: 8,
    INSTALLER: 9,
    PRODUCT_MANAGER: 10
};

export const sponsorshipStatuses = {
    AWAITING_ARTWORK: 1,
    PRINTED_CORRECT_READY_TO_LOAD: 38,
    ARTWORK_UPLOADED: 2,
    ARTWORK_APPROVED: 19,
    BILLING_CONFIRM_REQUIRED: 17,
    BILLED: 18,
    DESIGN_DOWNLOADED: 3,
    PROOF_UPLOADED: 4,
    ADVERTISER_APPROVED: 5,
    READY_TO_PROCES: 20,
    SENT_TO_PRINTER: 12,
    DOWNLOADED_BY_PRINTER: 16,
    QUEUED_FOR_PUBLICATION: 24,
    PRINT_VERIFICATION: 21,
    READY_TO_SHIP: 29,
    READY_TO_PRINT: 6,
    READY_TO_INSTALL: 7,
    READY_FOR_PUBLICATION: 22,
    PACKING: 28,
    LOADED_ON_TRUCK: 29,
    SHIPPED: 30,
    IN_STAGING: 31,
    UNPACKED: 32,
    PROBLEM: 33,
    INSTALLATION_PROBLEM: 37,
    SOLVED: 34,
    INSTALLED: 8,
    WALKTHROUGH_COMPLETED: 25,
    REMOVED: 9,
    CHANGE_ORDER: 14,
    ADMIN_REJECTION_RESOLVED: 26,
    DESIGN_REJECTION_RESOLVED: 10,
    REJECTED_FOR_REUPLOAD: 23,
    REJECTED_FOR_REVIEW: 27,
    REJECTED_PROOF: 11,
    PUBLICATION_REJECTED: 13,
    CANCELLED: 15
};

export const signageStatuses = {
    CREATED: 1,
    READY_TO_DESIGN: 2,
    PROOF_UPLOADED: 3,
    SUBMITED_TO_RPARTY: 4,
    PARTY_APPROVED: 5,
    APPROVED_WITH_CHANGES: 6,
    READY_TO_PRINT: 7,
    PRINTED: 8,
    INSTALLED: 9,
    REMOVED: 10,
    REJECTED: 11,
    ACCEPTED_WITH_CHANGES: 12,
	PRINTED_CORRECT_READY_TO_LOAD: 13,
    PACKING: 14,
    READY_TO_SHIP: 15,
    SHIPPED: 16,
    IN_STAGING: 17,
    UNPACKED: 18,
    READY_TO_INSTALL: 19,
    SOLVED: 23,
    CANCELLED: 20,
    PROBLEM: 21,
    INSTALLATION_PROBLEM: 22
};